import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'

export const CHANNEL_INITIAL_STATE: ChannelInterface = {
  bannerImage: '',
  bannerImageAlt: '',
  channelFeeDisplayType: 'exclusive',
  channelId: '',
  channelPrivacyType: 'public',
  channelStatus: 'draft',
  channelUrl: '',
  country: '',
  createdAt: '',
  defaultLocalTimezone: '',
  description: '',
  displayName: '',
  facebookUrl: '',
  instagramUrl: '',
  logo: '',
  logoAlt: '',
  marketings: [],
  notForProfit: false,
  ogMetaDescription: '',
  ogMetaImage: '',
  ogMetaImageAlt: '',
  ogMetaTitle: '',
  roles: [],
  stripeAccounts: [],
  channelSubscription: [],
  tcsAccepted: false,
  tcsAcceptedDate: '',
  twitterUrl: '',
  updatedAt: '',
  websiteUrl: '',
  youtubeUrl: '',
  superChannel: false,
  channelPass: [],
}
