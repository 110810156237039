import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAccountStatus, UserEntity } from '@apis/users-microservice/_types'
import checkEmailExist from '@apis/users-microservice/check-email-exist'

export const getUserVizingDataThunk = createAsyncThunk(
  'user/getUserVidzingData',
  async (email: string, { dispatch }): Promise<UserEntity> => {
    try {
      const responseData: UserAccountStatus = await checkEmailExist(email)
      return responseData.user
    } catch (error) {
      return Promise.reject(error)
    }
  },
)
