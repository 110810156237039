import { useRouter } from 'next/router'
import { useAppSelector } from '@root/store'
import {
  selectContentDataForEventTracking,
  selectHasAccessToContent,
} from '@store/contents-slice/_selectors'
import { useEffect, useMemo } from 'react'
import { parseBoolean } from '@utils/connascence'
import { removeQueryParamFromUrl } from '@utils/remove-query-params'
import { trackEvent } from '@utils/event-tracking'
import { TrackingEventsEnum } from '@shared/enums/tracking-events.enum'
import { selectSuccessfulChannelEventForTracking } from '@store/channel-slice/_selectors'

export default function UseSuccessQueryParameter(): JSX.Element {
  const router = useRouter()
  const { success, channel_pathname, content_pathname } = router.query
  const path = router.asPath

  // Check if the user has access to the content
  const userHasAccessToContent = useAppSelector(
    selectHasAccessToContent(content_pathname as string),
  )

  // Get the price data for the tracking event data
  const eventPriceData = useAppSelector(
    selectContentDataForEventTracking(content_pathname as string),
  )

  // Get the successful pass or subscription event data this selector will return undefined if the user did not purchase a pass or subscription
  const successfulPassOrSubscriptionEventData = useAppSelector(
    selectSuccessfulChannelEventForTracking(channel_pathname as string),
  )

  // Remove the success query param from the url
  const removeSuccessQueryParamFromUrl = () => {
    router.replace(removeQueryParamFromUrl(path, 'success'), undefined, { shallow: true })
  }

  // Handle sending the tracking event depending on the page and the event data
  const handleSendTrackEvent = () => {
    // Video info page handler (Must come first as the channel_pathname is also defined for the video info page)
    if (content_pathname && userHasAccessToContent) {
      // Use the successfulPassOrSubscriptionEventData if not undefined, as this means the user bought a pass or a subscription from a content page.
      // If undefined then the user must have purchased the content individually. So use the eventPriceData for the tracking data
      const trackData = successfulPassOrSubscriptionEventData ?? eventPriceData
      trackEvent(TrackingEventsEnum.PURCHASE, trackData)
      removeSuccessQueryParamFromUrl()
      return
    }

    // Channel page handler
    if (channel_pathname && successfulPassOrSubscriptionEventData) {
      trackEvent(TrackingEventsEnum.PURCHASE, successfulPassOrSubscriptionEventData)
      removeSuccessQueryParamFromUrl()
      return
    }
  }

  // If the user has access to the content and the success query param is true
  // then track the event and remove the query param from the url
  useEffect(() => {
    if (parseBoolean(success as string)) {
      handleSendTrackEvent()
    }
  }, [success, userHasAccessToContent, successfulPassOrSubscriptionEventData])

  return <></>
}
