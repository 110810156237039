import { GetByChannelPathNameParamsInterface } from '@apis/channels-microservice/_entities/_types'

export const CHANNEL_DEFAULT_QUERY_PARAMS_V2: GetByChannelPathNameParamsInterface = {
  draft: false,
  subscriptions: true,
  private: true,
  theme: true,
  organisations: true,
  superChannelRail: true,
  pass: true,
  contentTypeLabels: true,
  unlistedSubscriptions: true,
  showCategorySlug: true,
}
