import { SearchState } from '@store/search-slice/_types'
import { createSlice } from '@reduxjs/toolkit'
import {
  changeInputVisibility,
  changeSearchText,
  clearData,
  changeLoadingStatus,
  setSearchData,
  toggleIsLoading,
} from '@store/search-slice/_reducers'
import { searchText } from '@store/search-slice/_thunks'

const initialState: SearchState = {
  searchText: '',
  inputVisibility: false,
  resultsByChannelPathnameAndSearchText: {},
  isLoading: false,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchText: changeSearchText,
    toggleInputVisibility: changeInputVisibility,
    clearSearchData: clearData,
    toggleLoading: toggleIsLoading,
    setLoadingStatus: changeLoadingStatus,
  },
  extraReducers: {
    [searchText.fulfilled.toString()]: setSearchData,
  },
})

export const searchReducer = searchSlice.reducer
export const {
  setSearchText,
  setLoadingStatus,
  toggleInputVisibility,
  clearSearchData,
  toggleLoading,
} = searchSlice.actions
