import axiosRequest from '@apis/axios'
import { CouponResponse, ValidateCouponPayload } from '@store/coupons-slice/interfaces'

export default async function validateCoupon(
  payload: ValidateCouponPayload,
): Promise<CouponResponse> {
  const { promotionCode, organisationId, productId, channelId, productType, price, currency } =
    payload

  return await axiosRequest('billing', {
    method: 'GET',
    url: `coupons/promotion-code/${promotionCode}/validate`,
    params: { promotionCode, organisationId, productId, channelId, productType, price, currency },
  })
}
