import { ThemeState } from '@store/theme-slice/_types'
import { ThemesEnum } from '@shared/enums/themes/theme.enum'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setTheme } from '@store/theme-slice/reducers'

const initialState: ThemeState = {
  theme: ThemesEnum.LIGHT,
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: setTheme,
  },
})

export const themeReducer = themeSlice.reducer
