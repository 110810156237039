import { createSlice } from '@reduxjs/toolkit'
import { CreatorInterface } from '../../shared/interfaces/creator.interface'
import { CHANNEL_INITIAL_STATE } from '@store/creator-slice/_constants'
import { getCreatorChannelThunk } from '@store/creator-slice/_thunks'
import {
  getCreatorChannelFulfilled,
  getCreatorChannelPending,
  getCreatorChannelRejected,
} from '@store/creator-slice/_reducers'

export const initialState: CreatorInterface = {
  channel: CHANNEL_INITIAL_STATE,
  fetchStatus: 'idle',
}

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {},
  extraReducers: {
    [getCreatorChannelThunk.pending.toString()]: getCreatorChannelPending,
    [getCreatorChannelThunk.fulfilled.toString()]: getCreatorChannelFulfilled,
    [getCreatorChannelThunk.rejected.toString()]: getCreatorChannelRejected,
  },
})

export const creatorReducer = creatorSlice.reducer
