import { FACEBOOK_PIXEL_ID } from '@shared/constants/root.constants'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import isFacebookPixelLoaded from '@utils/is-facebook-pxiel-loaded'

export default function useFacebookPixel() {
  const router = useRouter()
  const isProd = process.env.ENVIRONMENT === 'prod'

  useEffect(() => {
    if (isProd) {
      // Dynamically import the Facebook Pixel module
      import('react-facebook-pixel')
        .then((ReactPixel) => {
          // Check if the Pixel has already been initialized
          if (!isFacebookPixelLoaded(FACEBOOK_PIXEL_ID)) {
            ReactPixel.default.init(FACEBOOK_PIXEL_ID)
            ReactPixel.default.pageView()
          }

          // Function to track page views on route change
          const handleRouteChange = () => {
            ReactPixel.default.pageView()
          }

          // Attach the event listener
          router.events.on('routeChangeComplete', handleRouteChange)

          // Clean up the event listener when the component unmounts
          return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
          }
        })
        .catch((err) => console.error('Failed to load Facebook Pixel', err))
    }
  }, [router.events])
}
