import { HorizontalNavOptionsInterface } from '@shared/interfaces/horizontal-nav-options.interface'
import { BundleTypeEnum } from '@shared/enums/bundles/bundle-types.enum'
import capitalizeString from '@utils/strings'

export const getBundleContentNavOption = (
  bundleType: BundleTypeEnum | undefined,
): HorizontalNavOptionsInterface => ({
  label:
    bundleType === BundleTypeEnum.COURSE
      ? capitalizeString(BundleTypeEnum.COURSE)
      : capitalizeString(BundleTypeEnum.SERIES),
  key: 'bundleContent',
})

export const CONTENT_RESOURCES_NAV_OPTION: HorizontalNavOptionsInterface = {
  label: 'Downloads',
  key: 'downloads',
}

export const CONTENT_CHAPTER_NAV_OPTION: HorizontalNavOptionsInterface = {
  label: 'Chapters',
  key: 'chapters',
}

export const RECOMMENDED_CONTENT_NAV_OPTION: HorizontalNavOptionsInterface = {
  label: 'Recommended',
  key: 'recommended',
}
