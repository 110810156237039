import { createAsyncThunk } from '@reduxjs/toolkit'
import { TagRequestInterface, TagStateInterface } from '@shared/interfaces/tag.interface'
import fetchTagRequest from '@apis/tag'
import { PAGINATION_PLACEHOLDER } from '@shared/constants/pagination.constants'

export const fetchTagThunk = createAsyncThunk(
  'tags/fetchTag',
  async (payload: TagRequestInterface): Promise<TagStateInterface> => {
    try {
      const response: TagStateInterface = await fetchTagRequest(payload)
      const page = response?.meta?.page ?? 1
      return {
        fetchStatus: 'succeeded',
        tagId: payload.tagId,
        name: payload.name,
        data: response.data,
        meta: {
          ...response.meta,
          // Backend is returning string
          page: typeof page === 'string' ? parseInt(page) : page,
        },
      }
    } catch (error) {
      return {
        fetchStatus: 'failed',
        tagId: payload.tagId,
        name: payload.name,
        data: [],
        meta: PAGINATION_PLACEHOLDER,
      }
    }
  },
)
