import { createSlice } from '@reduxjs/toolkit'
import { getUserGeoLocationThunk } from './_thunk'
import type { GeoState } from './_types'
import {
  getUserGeoLocationFulfilledReducer,
  getUserGeoLocationPendingReducer,
  getUserGeoLocationRejectedReducer,
} from './_reducer'

const initialState: GeoState = {
  countryName: '',
  countryCode: '',
  city: '',
  fetchStatus: 'idle',
}

const geoSlice = createSlice({
  name: 'geo',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserGeoLocationThunk.pending.toString()]: getUserGeoLocationPendingReducer,
    [getUserGeoLocationThunk.fulfilled.toString()]: getUserGeoLocationFulfilledReducer,
    [getUserGeoLocationThunk.rejected.toString()]: getUserGeoLocationRejectedReducer,
  },
})

export const geoReducer = geoSlice.reducer
