import { TagState } from '@shared/interfaces/tag.interface'
import { createSlice } from '@reduxjs/toolkit'
import { fetchTagThunk } from '@store/tags-slice/_thunk'
import { fetchTagPendingReducer, fetchTagResultReducer } from '@store/tags-slice/_reducers'

const initialState: TagState = {
  tags: [],
}

const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTagThunk.pending, fetchTagPendingReducer)
      // Using as success and failure
      .addCase(fetchTagThunk.fulfilled, fetchTagResultReducer)
  },
})

export const tagsReducer = tagSlice.reducer
