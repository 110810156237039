import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserSubscriptionStatusInterface } from '@shared/interfaces/user-subscription-status.interface'
import axiosRequest from '@apis/axios'
import {
  GetSubscriptionStatusInterface,
  GetSubscriptionStatusPayloadInterface,
} from '@store/subscriber-slice/_interfaces'
import { datadogLogs } from '@datadog/browser-logs'

export const getSubscriptionStatusThunk = createAsyncThunk(
  'subscriber/status',
  async ({
    channelPathname,
    subscriptionId,
  }: GetSubscriptionStatusPayloadInterface): Promise<GetSubscriptionStatusInterface> => {
    try {
      const userSubscription = (await axiosRequest('billing', {
        method: 'GET',
        url: `subscription/${subscriptionId}/user-status`,
      })) as UserSubscriptionStatusInterface

      return {
        channelPathname,
        subscriptionId,
        isSubscribed: userSubscription.isSubscribed,
        userSubscriptionDetails: userSubscription?.userSubscriptionDetails,
      }
    } catch (error) {
      datadogLogs.logger.warn('Error fetching user subscription status', error)
      throw error
    }
  },
)
