import { createAsyncThunk } from '@reduxjs/toolkit'
import { ListChannelsByCategoryThunkParameters } from '@store/channel-slice/_types'
import getChannelsByCategoryAndId from '@apis/channels-microservice/get-channels-by-category-and-id'
import { datadogLogs } from '@datadog/browser-logs'

export const listChannelsByCategoryThunk = createAsyncThunk(
  'channels/getChannel',
  async ({ categorySlug, organisationId }: ListChannelsByCategoryThunkParameters, thunkAPI) => {
    try {
      return getChannelsByCategoryAndId({ categorySlug, organisationId })
    } catch (error) {
      datadogLogs.logger.error(error?.message || `Error retrieving channels by category`, error)
      return thunkAPI.rejectWithValue(error?.data?.message ?? 'Error')
    }
  },
)
