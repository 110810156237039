import { GetChannelPassStatusThunkReturn, PassState } from '@store/pass-slice/_types'

export const getPassStatusPendingReducer = (state: PassState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getPassStatusRejectedReducer = (
  state: PassState,
  { error }: { error: { message: string } },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = error.message
}

export const getPassStatusFulfilledReducer = (
  state: PassState,
  { payload: payloadPassStatus }: { payload: GetChannelPassStatusThunkReturn[] },
): void => {
  if (payloadPassStatus) {
    // By passId
    const passStatusObj = payloadPassStatus.reduce((acc, passStatus) => {
      acc[passStatus.passId] = {
        isActive: passStatus.isActive,
        ...(passStatus.userPassDetails ? { userPassDetails: passStatus.userPassDetails } : {}),
      }

      return acc
    }, {})
    state.byPassStatusById = {
      ...state.byPassStatusById,
      ...passStatusObj,
    }

    // By channelPathname
    const passByChannelPathname = payloadPassStatus.reduce((acc, passStatus) => {
      acc[passStatus.channelPathname] = {
        isActive: passStatus.isActive,
        ...(passStatus.userPassDetails ? { userPassDetails: passStatus.userPassDetails } : {}),
      }
      return acc
    }, {})
    state.byChannelPathname = {
      ...state.byChannelPathname,
      ...passByChannelPathname,
    }

    state.fetchStatus = 'succeeded'
    state.errorMessages = ''
  }
}
