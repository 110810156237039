import { AssetsState } from '@store/asset-slice/_types'
import { createSlice } from '@reduxjs/toolkit'
import { getMuxAssetById } from '@store/asset-slice/_thunks'
import {
  getMuxAssetFulfilled,
  getMuxAssetPendingReducer,
  getMuxAssetRejectedReducer,
} from '@store/asset-slice/_reducers'

const initialState: AssetsState = {
  muxAssets: [],
  errorMessages: '',
  fetchStatus: 'idle',
}

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: {
    [getMuxAssetById.pending.toString()]: getMuxAssetPendingReducer,
    [getMuxAssetById.rejected.toString()]: getMuxAssetRejectedReducer,
    [getMuxAssetById.fulfilled.toString()]: getMuxAssetFulfilled,
  },
})

export const assetsReducer = assetsSlice.reducer
