import { OrganisationState } from '@store/organisation-slice/_types'
import { createSlice } from '@reduxjs/toolkit'
import { fetchOrganisationByOrgId } from '@store/organisation-slice/_thunks'
import {
  getOrganisationFulfilled,
  getOrganisationPendingReducer,
  getOrganisationRejectedReducer,
  resetFetchStatusAndErrorMessages,
} from '@store/organisation-slice/_reducers'

const initialState: OrganisationState = {
  organisations: [],
  errorMessages: '',
  fetchStatus: 'idle',
}

const organisationsSlice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    resetFetchAndError: resetFetchStatusAndErrorMessages,
  },
  extraReducers: {
    [fetchOrganisationByOrgId.pending.toString()]: getOrganisationPendingReducer,
    [fetchOrganisationByOrgId.rejected.toString()]: getOrganisationRejectedReducer,
    [fetchOrganisationByOrgId.fulfilled.toString()]: getOrganisationFulfilled,
  },
})

export const organisationsReducer = organisationsSlice.reducer
export const { resetFetchAndError } = organisationsSlice.actions
