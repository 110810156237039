/**
 * Checks if array is defined and not empty
 * @param array
 */
export function isEmptyArray<T>(array: T[]): boolean {
  return !(Array.isArray(array) && array.length > 0)
}

/**
 * Checks if array is defined and has more than one element
 * @param array
 */
export function isArrayLengthMoreThanOne<T>(array: T[]): boolean {
  return Array.isArray(array) && array.length > 1
}

export function compareArrayOfObjects<T extends Object>(
  property: keyof T,
  sort_order: 'asc' | 'desc',
) {
  return (a: T, b: T) => {
    const val1 = a[property]
    const val2 = b[property]
    const order = sort_order !== 'desc' ? 1 : -1

    switch (typeof val1) {
      case 'number': {
        const val2Typed = val2 as number
        const result = val1 - val2Typed
        return result * order
      }
      case 'string': {
        const val2Typed = val2 as string
        const result = val1.localeCompare(val2Typed)
        return result * order
      }
      // add other cases like boolean, etc.
      default:
        return 0
    }
  }
}
