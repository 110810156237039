import { ImageSpecsBuilder, ImageSpecSrcset } from '@shared/interfaces/image.interface'
import { generateTransformationUrl } from '@apis/cloudinary'
import { IMAGE_SIZES } from '@shared/constants/images-sizes.constants'
import { applyScaleToCropImage, cropImage, hasCropVersion } from '@root/utils/cloudinary'
import { isImageFromMux } from '@root/utils/mux'
import { AspectRatios } from '@shared/types/images.types'

const buildSrcListSizes = () => {
  return IMAGE_SIZES.srcSet
    .map((src) => {
      if (src.breakpoint) {
        return `(max-width: ${src.breakpoint}) ${src.breakpoint}`
      }
    })
    .filter((src) => src !== undefined)
    .join(', ')
}

const buildSrcSet = (image: string) => {
  return IMAGE_SIZES.srcSet
    .map((srcSetSpecs) => `${buildImageUrl(image, srcSetSpecs)} ${srcSetSpecs.resolution}`)
    .join(',')
}

const cropImgByImageDefaultSpecs = (image: string) => {
  return IMAGE_SIZES.srcSet
    .map((src) => `${cropImage(image, { height: src.height, width: src.width })} ${src.resolution}`)
    .join(',')
}

const buildImageUrl = (image: string, specs: ImageSpecSrcset) => {
  const { width, height } = specs
  return generateTransformationUrl(image, {
    width,
    height,
    quality: 'auto:best',
  })
}

export function buildCoverImageToUse(image: string): ImageSpecsBuilder {
  const hasCrop = hasCropVersion(image)
  const src = hasCrop
    ? applyScaleToCropImage({
        url: image,
        width: IMAGE_SIZES.default.width,
        height: IMAGE_SIZES.default.height,
      })
    : buildImageUrl(image, IMAGE_SIZES.default)
  const srcSet = hasCrop ? cropImgByImageDefaultSpecs(image) : buildSrcSet(image)
  const sizes = hasCrop ? buildSrcListSizes() : IMAGE_SIZES.default.breakpoint
  return { src, srcSet, sizes }
}

export const getHeightFromWidthAndAspect = (width: number, aspectRatio: AspectRatios): number => {
  const aspects = aspectRatio.split(':')
  const aspectDivisor = Number(aspects[1]) / Number(aspects[0])
  return Math.round(width * aspectDivisor)
}

export function buildThumbnailImageToUse(
  image: string,
  width: number,
  aspectRatio: AspectRatios,
): string {
  const isMuxImage = isImageFromMux(image)
  const hasCrop = hasCropVersion(image)

  if (isMuxImage) {
    return `${image}&width=${width}&height=${getHeightFromWidthAndAspect(
      width,
      aspectRatio,
    )}&fit_mode=preserve`
  }

  if (hasCrop) {
    return applyScaleToCropImage({
      url: image,
      width,
      height: getHeightFromWidthAndAspect(width, aspectRatio),
    })
  }

  return generateTransformationUrl(image, {
    width: width,
    quality: 'auto:best',
    height: getHeightFromWidthAndAspect(width, aspectRatio),
  })
}

/**
 * Function to determine is an image is stored locally on our project in the public folder
 * @param image
 */
export function isImageLocal(image: string): boolean {
  try {
    new URL(image)
    return false // It's a valid URL, so not a local image
  } catch {
    return true // It's not a valid URL, likely a local image sp return true
  }
}
