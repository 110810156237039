import axiosRequest from '@apis/axios'
import { ResumePlayback } from '@apis/analytics-microservice/_entities/resume-playback'

export default async function getResumePlayback(contentId: string): Promise<ResumePlayback> {
  return (await axiosRequest(
    'analytics',
    {
      method: 'GET',
      url: `user/resume-playback/content/${contentId}`,
    },
    true,
  )) as ResumePlayback
}
