import { MUX_THUMBNAIL_ORIGIN } from '@shared/constants/mux.constants'

export function isImageFromMux(image: string): boolean {
  // Try catch in case string can not be converted to url
  try {
    return new URL(image).origin === MUX_THUMBNAIL_ORIGIN
  } catch (e) {
    return false
  }
}
