export const CHANNEL_DEFAULT_QUERY_PARAMS = {
  draft: false,
  subscriptions: true,
  private: true,
  theme: true,
  organisations: true,
  superChannelRail: true,
  pass: true,
  contentTypeLabels: true,
  unlistedSubscriptions: true,
}
