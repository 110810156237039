import { PurchasesState } from '@store/user-billing-slice/purchases/_interfaces'
import {
  FailedGetActivePurchasesResponse,
  GetActivePurchasesResponse,
  PurchaseDetails,
} from '@shared/interfaces/users-billing/user-billing-purchases.interfaces'
import { PayloadAction } from '@reduxjs/toolkit'
import { getUserBillingPurchasesThunk } from '@store/user-billing-slice/purchases/_thunks'

export const pendingReducer = (state: PurchasesState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getPurchasesFulfilledReducer = (
  state: PurchasesState,
  action: PayloadAction<PurchaseDetails[]>,
): void => {
  // Update the purchases in the state
  state.data.purchases = action.payload

  // Update the fetchStatus to indicate the successful data retrieval
  state.fetchStatus = 'succeeded'

  // Clear any previous error messages
  state.errorMessages = ''
}

export const rejectedReducer = (
  state: PurchasesState,
  action: ReturnType<typeof getUserBillingPurchasesThunk.rejected>,
): void => {
  state.fetchStatus = 'failed'
  // Here we check if the error is available and set it accordingly
  if (action?.error?.message) {
    state.errorMessages = action.error.message
  } else {
    state.errorMessages = 'Failed to fetch purchases'
  }
}
