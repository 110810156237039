import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE } from './_types'
import { resetCouponThunk, validateCouponThunk } from '@store/coupons-slice/_thunks'
import {
  resetCouponReducer,
  validateCouponFulfilledReducer,
  validateCouponPendingReducer,
  validateCouponRejectedReducer,
} from '@store/coupons-slice/_reducers'

const couponsSlice = createSlice({
  name: 'coupons',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateCouponThunk.pending, validateCouponPendingReducer)
      .addCase(validateCouponThunk.rejected, validateCouponRejectedReducer)
      .addCase(validateCouponThunk.fulfilled, validateCouponFulfilledReducer)
      .addCase(resetCouponThunk.fulfilled, resetCouponReducer)
  },
})

export const couponsReducer = couponsSlice.reducer
