import axiosRequest from '@apis/axios'
import { SearchPayload } from '@apis/analytics-microservice/_entities/search'
import { SearchInterface } from '@shared/interfaces/search.interface'

export default async function getSearchResults({
  searchText,
  searchParams,
  signal,
  version = '1',
}: SearchPayload): Promise<SearchInterface[]> {
  return (await axiosRequest(
    'analytics',
    {
      method: 'GET',
      url: `search/${searchText}`,
      params: searchParams,
      signal,
    },
    true,
    version,
  )) as SearchInterface[]
}
