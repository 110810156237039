import '../styles/globals.css'
import Head from 'next/head'
import TagManager from 'react-gtm-module'
import UseAuthChangeHandler from '@apis/firebase/_hooks/use-auth-change'
import UseWebSocketInitialize from '@apis/websocket/_hooks/use-websocket-initialize'
import store from '../store'
import type { AppProps } from 'next/app'
import { GTM_CONFIG } from '@apis/google-analytics/gtm-ga.config'
import { Provider } from 'react-redux'
import { FACEBOOK_DOMAIN_VERIFICATION } from '../constants'
import { useEffect } from 'react'
import { useGoBackToCurrentUrl } from '@components/welcome-page/_hooks/use-go-back-to-current-url'
import UseUserGeoLocation from '@library/_hooks/use-user-location'
import dynamic from 'next/dynamic'
import UseDatadog from '@hooks/use-datadog'
import UseSuccessQueryParameter from '@hooks/use-success-query-parameter'
import useFacebookPixel from '@hooks/use-facebook-pixel-vidzing'
import { useRouter } from 'next/router'
import useMaintenanceMode from '@hooks/use-maintenance-mode'

const Modal = dynamic(() => import('@features/_ui/modal'), {
  ssr: false,
})

const FeatureFlagsHook = dynamic(() => import('@apis/feature-flag-handler'), {
  ssr: false,
})

const RouterLoader = dynamic(() => import('@ui/router-loader'), {
  ssr: false,
})

const UseHelpScout = dynamic(() => import('@hooks/use-help-scout'), {
  ssr: false,
})

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  useGoBackToCurrentUrl()

  useEffect(() => {
    TagManager.initialize(GTM_CONFIG)
  }, [])

  // Maintenance mode
  useMaintenanceMode()

  //Datadog
  UseDatadog()

  // Adding facebook pixel
  useFacebookPixel()

  // Show facebook meta tag validation. It should be on the whole site
  const showFacebookVerification = process.env.ENVIRONMENT === 'prod'

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title key="title">Vidzing</title>
        {showFacebookVerification && (
          <meta name="facebook-domain-verification" content={FACEBOOK_DOMAIN_VERIFICATION} />
        )}
      </Head>
      <Provider store={store}>
        <UseAuthChangeHandler />
        <UseUserGeoLocation />
        <UseWebSocketInitialize />
        <UseSuccessQueryParameter />
        <UseHelpScout />
        <FeatureFlagsHook />
        <Modal />
        <RouterLoader />
        <Component {...pageProps} />
      </Provider>
    </>
  )
}

export default MyApp
