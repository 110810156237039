import { getSubscriptionStatusThunk } from './_thunks'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pendingReducer, rejectedReducer, subscriberFulfilledReducer } from './_reducers'
import { SubscriberState } from './_types'
import { SubscribedByChannelPathnameInterface } from '@shared/interfaces/creators/subscription/subscribed-by-channel-pathname.interface'

const initialState: SubscriberState = {
  byChannelPathname: {},
  bySubscriptionId: {},
  errorMessages: '',
  fetchStatus: 'idle',
}

const subscriberSlice = createSlice({
  name: 'subscriber',
  initialState,
  reducers: {
    resetSubscriberBills: (state) => initialState,
    setSubscribedByChannelPathname: (
      state,
      { payload }: PayloadAction<SubscribedByChannelPathnameInterface>,
    ) => {
      // Populate byChannelPathname state
      // If previously subscribed state is true, then keep it true
      // A channel can have multiple subscriptions and the order in which the status of each subscription is returned is not guaranteed
      const previousSubscribedState = state.byChannelPathname[payload.channelPathname]?.isSubscribed
      state.byChannelPathname = {
        ...state.byChannelPathname,
        [payload.channelPathname]: {
          isSubscribed: previousSubscribedState || payload.isSubscribed,
        },
      }
    },
  },
  extraReducers: {
    [getSubscriptionStatusThunk.pending.toString()]: pendingReducer,
    [getSubscriptionStatusThunk.fulfilled.toString()]: subscriberFulfilledReducer,
    [getSubscriptionStatusThunk.rejected.toString()]: rejectedReducer,
  },
})

export const { resetSubscriberBills, setSubscribedByChannelPathname } = subscriberSlice.actions
export const subscribersReducer = subscriberSlice.reducer
