import { selectUserCountryCode } from '@store/geo-slice/_selectors'
import { selectUserId } from '@store/user-slice/_selectors'
import getUserGeoLocationApiCall from '@apis/_utilities/user-locaiton'
import store from '@root/store'

export default async function getCountryCode() {
  // Get country code and add to request as it's now used in state machine
  // to check state of geo-blocking
  const state = store.getState()
  let countryCode = selectUserCountryCode(state)
  const userId = selectUserId(state)
  // If userId is defined and country code is not call the geo endpoint and get it.
  // The geo request may take longer to be fetched by the app so call here if needed and await it
  // If local don't try fetch geo because we have no netlify edge and this blocks code execution
  if (userId !== '' && countryCode === '' && process.env.FRONT_END_ENV !== 'local') {
    const geo = await getUserGeoLocationApiCall()
    countryCode = geo.geo.country.code
  }
  return countryCode
}
