import { ImageSpecSrcset, ImageSpecsSrcset } from '@shared/interfaces/image.interface'

export const IMAGE_SIZES: ImageSpecsSrcset = {
  default: {
    width: 1920,
    height: 540,
    resolution: '',
    breakpoint: '1500px',
  },
  srcSet: [
    {
      width: 1000,
      height: 281,
      resolution: '599w',
      breakpoint: '599px',
    },
    {
      width: 1366,
      height: 384,
      resolution: '1499w',
      breakpoint: '1499px',
    },
    {
      width: 1537,
      height: 432,
      resolution: '1023w',
      breakpoint: '1023px',
    },
    {
      width: 1920,
      height: 540,
      resolution: '1500w',
      breakpoint: '',
    },
  ],
}
