import { UrlParts } from '@shared/interfaces/custom-channel.interface'

/**
 * Split URL
 * When the channel URL was watch video ( 3 part in array ), we display watch to customize \
 * the video page layout
 * @param url
 */
export default function splitChannelUrl(url: string): UrlParts {
  // remove query params if any first
  const removeQueries = url.split('?')[0]
  // remove anchors if any first
  const removeAnchors = removeQueries.split('#')[0]

  // split the url
  const data = removeAnchors.slice(1).split('/')

  // Small validations... just in case
  const channelSlug = data && data[0] !== undefined ? data[0] : ''
  const slug = data && data[1] !== undefined ? data[1] : ''
  const videoPlayer = data && data[2] !== undefined ? data[2] : ''
  //
  return { channelSlug: channelSlug, slug: videoPlayer ? videoPlayer : slug }
}
