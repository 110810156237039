import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { CartState, ContentItem } from './_interfaces'

const initialState: CartState = {
  contentItems: [],
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (cartState, { payload }: PayloadAction<ContentItem>) => {
      // Filter the items in the cart and remove the item with the matching contentId
      const filteredCart = cartState.contentItems.filter(
        (item) => item.contentId !== payload.contentId,
      )
      // Add the new item to the cart
      cartState.contentItems = [...filteredCart, payload]
    },
    removeFromCart: (cartState, { payload }: PayloadAction<string>) => {
      // Filter the items in the cart and remove the item with the matching contentId
      cartState.contentItems = cartState.contentItems.filter((item) => item.contentId !== payload)
    },
    resetCart: (cartState) => {
      cartState.contentItems = initialState.contentItems
    },
  },
})

export const cartReducer = cartSlice.reducer
export const { addToCart, resetCart, removeFromCart } = cartSlice.actions
