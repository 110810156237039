import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getContentByIdThunk,
  getContentResourcesByIdThunk,
  getFeaturedContentByChannelIdThunk,
  getRecommendedContentById,
  getResumePlaybackByIdsThunk,
  getResumePlaybackByIdThunk,
} from './_thunks'
import {
  getContentFulfilledReducer,
  getContentPendingReducer,
  getContentRejectedReducer,
  getContentResourcesFulfilledReducer,
  getFeaturedContentByChannelIdFulfilledReducer,
  getRecommendedContentFulfilledReducer,
  getResumePlaybackByIdsFulfilledReducer,
  getResumePlaybackFulfilledReducer,
  getResumePlaybackPendingReducer,
  saveRailsContentsReducer,
  setButtonStateReducer,
} from './_reducers'
import type { ContentsState } from './_types'
import { getAllRailsThunk } from '../rails-slice/_thunks'

const initialState: ContentsState = {
  byContentId: {},
  featuredContentByChanelId: {},
  contentResumePlayback: [],
  contentResources: [],
  recommendedContentByContentId: {},
  errorMessages: '',
  fetchStatus: 'idle',
  fetchPlaybackStatus: 'idle',
}

const contentsSlice = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    setButtonState: setButtonStateReducer,
  },
  extraReducers: {
    [getContentByIdThunk.pending.toString()]: getContentPendingReducer,
    [getContentByIdThunk.fulfilled.toString()]: getContentFulfilledReducer,
    [getContentByIdThunk.rejected.toString()]: getContentRejectedReducer,
    [getAllRailsThunk.fulfilled.toString()]: saveRailsContentsReducer,
    //
    [getResumePlaybackByIdThunk.pending.toString()]: getResumePlaybackPendingReducer,
    [getResumePlaybackByIdThunk.fulfilled.toString()]: getResumePlaybackFulfilledReducer,
    [getResumePlaybackByIdsThunk.fulfilled.toString()]: getResumePlaybackByIdsFulfilledReducer,
    //
    [getContentResourcesByIdThunk.fulfilled.toString()]: getContentResourcesFulfilledReducer,
    [getFeaturedContentByChannelIdThunk.fulfilled.toString()]:
      getFeaturedContentByChannelIdFulfilledReducer,
    [getRecommendedContentById.fulfilled.toString()]: getRecommendedContentFulfilledReducer,
  },
})

export const { setButtonState } = contentsSlice.actions
export const contentsReducer = contentsSlice.reducer
