import axiosRequest from '@apis/axios'
import { GenerateClientSecretResponse } from '@shared/interfaces/users-billing/user-billing-payment-methods.interfaces'
import { datadogLogs } from '@datadog/browser-logs'

export default async function generateClientSecret(): Promise<GenerateClientSecretResponse> {
  const response = (await axiosRequest(
    'billing',
    {
      method: 'PUT',
      url: `user-subscriptions/payment-methods/generate-client-secret`,
    },
    true,
  )) as GenerateClientSecretResponse

  datadogLogs.logger.info('Successful generate client secret API request')

  return response
}
