import { TagStateInterface } from '@shared/interfaces/tag.interface'
import { PAGINATION_PLACEHOLDER } from '@shared/constants/pagination.constants'

export const TAGS_PLACEHOLDER: TagStateInterface = {
  fetchStatus: 'loading',
  tagId: '',
  name: '',
  data: [],
  meta: PAGINATION_PLACEHOLDER,
}
