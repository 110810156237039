/**
 * Removes a query parameter from a URL.
 * And returns the updated URL.
 * @param url
 * @param paramToRemove
 */
export function removeQueryParamFromUrl(url: string, paramToRemove: string): string {
  const urlParts = url.split('?')
  if (urlParts.length < 2) {
    // No query string, so no need to remove anything
    return url
  }

  const baseUrl = urlParts[0]
  const queryString = urlParts[1]
  const queryParams = new URLSearchParams(queryString)

  queryParams.delete(paramToRemove)

  const updatedQueryString = queryParams.toString()
  if (updatedQueryString) {
    return `${baseUrl}?${updatedQueryString}`
  } else {
    return baseUrl
  }
}
