import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import { GetPaymentMethodResponse } from '@shared/interfaces/users-billing/user-billing-payment-methods.interfaces'

export default async function getPaymentMethods(): Promise<GetPaymentMethodResponse> {
  datadogLogs.logger.info('Making get payment method API request')

  const response: GetPaymentMethodResponse = (await axiosRequest(
    'billing',
    {
      method: 'GET',
      url: 'user-subscriptions/payment-methods',
    },
    true,
  )) as GetPaymentMethodResponse

  datadogLogs.logger.info('Successfully fetched payment methods API request', { response })

  return response
}
