import store from '..'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllRailsByChannelId } from '@apis/rails-microservice/get-all-rails-by-channel-id'
import { selectChannelPropByChannelPathname as select } from '../channel-slice/_selectors'
import type { RailsDictionaryEntry } from './_types'
import { selectUserCountryCode } from '@store/geo-slice/_selectors'
import { selectUserId } from '@store/user-slice/_selectors'
import getUserGeoLocationApiCall from '@apis/_utilities/user-locaiton'

interface GetAllRailsThunkCallbackInterface {
  channelPathname: string
  channelIdHardCoded?: string
}

const getAllRailsThunkCallback = async ({
  channelPathname,
  channelIdHardCoded,
}: GetAllRailsThunkCallbackInterface): Promise<RailsDictionaryEntry> => {
  const state = store.getState()
  const channelId = channelIdHardCoded || select(channelPathname)('channelId')(state)

  // Get country code and add to request as it's now used in state machine
  // to check state of geo-blocking
  let countryCode = selectUserCountryCode(state)
  const userId = selectUserId(state)
  // If userId is defined and country code is not call the geo endpoint and get it.
  // The geo request may take longer to be fetched by the app so call here if needed and await it
  // If local don't try fetch geo because we have no netlify edge and this blocks code execution
  if (userId !== '' && countryCode === '' && process.env.FRONT_END_ENV !== 'local') {
    const geo = await getUserGeoLocationApiCall()
    countryCode = geo.geo.country.code
  }

  if (channelId) {
    try {
      const rails = await getAllRailsByChannelId(channelId, countryCode)
      return { channelPathname, rails }
    } catch (error) {
      return Promise.reject(error)
    }
  } else {
    return Promise.reject('Can not find channel id for the channel pathname: ' + channelPathname)
  }
}

export const getAllRailsThunk = createAsyncThunk('rails/getAllRails', getAllRailsThunkCallback)
