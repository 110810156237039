import axiosRequest from '@apis/axios'
import { TagRequestInterface, TagStateInterface } from '@shared/interfaces/tag.interface'

export default async function fetchTagRequest(
  payload: TagRequestInterface,
): Promise<TagStateInterface> {
  const { name, order: order, page: page, take: take } = payload
  return await axiosRequest('contents', {
    method: 'GET',
    url: `tags/all`,
    params: { tagName: name, order, page, take },
  })
}
