import axiosRequest from '@apis/axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { addChannelCreator } from '@store/channel-slice'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'

const getCreatorChannel = async () => {
  const response = await axiosRequest('creators', {
    method: 'GET',
    url: 'channel',
  })

  const channel: ChannelInterface = {
    bannerImage: '',
    bannerImageAlt: '',
    channelFeeDisplayType: undefined,
    channelId: '',
    channelPrivacyType: undefined,
    channelStatus: undefined,
    channelUrl: '',
    country: '',
    createdAt: '',
    defaultLocalTimezone: '',
    description: '',
    displayName: '',
    logo: '',
    logoAlt: '',
    marketings: [],
    notForProfit: false,
    roles: [],
    stripeAccounts: [],
    channelSubscription: [],
    tcsAccepted: false,
    tcsAcceptedDate: '',
    updatedAt: '',
    superChannel: false,
    channelPass: [],
    ...response,
  }
  return channel
}

export const getCreatorChannelThunk = createAsyncThunk(
  'creator/getCreatorChannel',
  async (id: string, { dispatch }): Promise<ChannelInterface> => {
    try {
      const res: ChannelInterface = await getCreatorChannel()
      dispatch(addChannelCreator(res))
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
)
