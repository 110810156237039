import { ModalInterface } from '@shared/interfaces/modal.interface'
import { createSlice } from '@reduxjs/toolkit'
import { closeModalReducer, openModalReducer } from '@store/modal-slice/reducer'

const initialState: ModalInterface = {
  status: 'close',
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: openModalReducer,
    closeModal: closeModalReducer,
  },
})

export const modalReducer = modalSlice.reducer
export const { openModal, closeModal } = modalSlice.actions
