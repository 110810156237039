import { useEffect } from 'react'
import { useRouter } from 'next/router'
import splitChannelUrl from '@root/utils/split-channel-url'
import getCustomChannelData from '@root/utils/get-custom-channel-data'
import { VIDZING_HOME_PAGE } from '@root/constants'

export const AFTER_AUTH_PATHNAME_PARAMS_KEY = 'after-auth-pathname-params-key'
const authPagesPathnamePattern = /^\/welcome\//

/**
 * Go back to the current pathname and search params after authentication.
 * Add the following line into the login and sign up buttons' onClick handlers.
 * `router.push(sessionStorage?.getItem(afterAuthUrlKey) || '/default-landing-pathname')`
 */
export function useGoBackToCurrentUrl(): void {
  const router = useRouter()

  const path = router.asPath
  const { channelSlug, slug } = splitChannelUrl(path)
  const customPageData = getCustomChannelData(channelSlug)

  useEffect(() => {
    const storeCurrentPathnameParams = (destPathnameParams: string) => {
      const destPathname = destPathnameParams.split('?')[0]
      const currentPathname = window.location.pathname
      const currentParams = window.location.search + window.location.hash
      const currentPathnameParams = currentPathname + currentParams

      // test against pathname to avoid params have 'welcome' and return ture wrongly
      const fromAuth = authPagesPathnamePattern.test(currentPathname)
      const toAuth = authPagesPathnamePattern.test(destPathname)

      // grab the current after auth path from session storage
      const currentAfterAuthPath = sessionStorage.getItem(AFTER_AUTH_PATHNAME_PARAMS_KEY)

      // If the current auth path is not vidzing's home page ('/') and the current path is vidzing's home page ('/')
      // Do nothing. This is to avoid the user being redirected to the home page after auth on custom domain channels
      if (
        currentAfterAuthPath !== VIDZING_HOME_PAGE &&
        currentPathnameParams === VIDZING_HOME_PAGE
      ) {
        return
      } else if (!fromAuth && toAuth) {
        // Go to auth from another page
        sessionStorage.setItem(AFTER_AUTH_PATHNAME_PARAMS_KEY, currentPathnameParams)
      } else if (customPageData && slug !== 'login' && slug !== 'signup') {
        sessionStorage.setItem(AFTER_AUTH_PATHNAME_PARAMS_KEY, currentPathnameParams)
      }
    }

    router.events.on('routeChangeStart', storeCurrentPathnameParams)

    return () => {
      router.events.off('routeChangeStart', storeCurrentPathnameParams)
    }
  }, [router])
}
