import { UserEntity } from '@apis/users-microservice/_types'
import { initialState } from './index'

export const setUserReducer = (state: UserEntity, { payload }: { payload: UserEntity }): void => {
  state.administrativeArea = payload.administrativeArea
  state.country = payload.country
  state.dateOfBirth = payload.dateOfBirth
  state.email = payload.email
  state.emailVerified = payload.emailVerified
  state.firebaseUid = payload.firebaseUid
  state.firstName = payload.firstName
  state.fetchStatus = 'succeeded'
  state.gender = payload.gender
  state.lastName = payload.lastName
  state.locality = payload.locality
  state.marketingSubscribed = payload.marketingSubscribed
  state.stripeToken = payload.stripeToken
  state.thoroughfare = payload.thoroughfare
  state.tooManyUsersWatching = payload.tooManyUsersWatching
  state.userId = payload.userId
  state.userType = payload.userType
  state.watching = payload.watching
}

export const setWatchingReducer = (state: UserEntity, { payload }: { payload: number }): void => {
  state.watching = payload
}

export const setTooManyUsersWatchingReducer = (
  state: UserEntity,
  { payload }: { payload: boolean },
): void => {
  state.tooManyUsersWatching = payload
}

export const setUserPendingReducer = (state: UserEntity): void => {
  state.fetchStatus = 'loading'
}

export const setUserRejectedReducer = (state: UserEntity): void => {
  state.fetchStatus = 'failed'
}

export const userLogoutReducer = (state: UserEntity): void => {
  state.administrativeArea = initialState.administrativeArea
  state.country = initialState.country
  state.dateOfBirth = initialState.dateOfBirth
  state.email = initialState.email
  state.emailVerified = initialState.emailVerified
  state.firebaseUid = initialState.firebaseUid
  state.firstName = initialState.firstName
  state.fetchStatus = initialState.fetchStatus
  state.gender = initialState.gender
  state.lastName = initialState.lastName
  state.locality = initialState.locality
  state.marketingSubscribed = initialState.marketingSubscribed
  state.stripeToken = initialState.stripeToken
  state.thoroughfare = initialState.thoroughfare
  state.tooManyUsersWatching = initialState.tooManyUsersWatching
  state.userId = initialState.userId
  state.userType = initialState.userType
  state.watching = initialState.watching
}
