import { createPingTimer } from '@apis/websocket/_hooks/websocket-ping'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'
import type { WebSocketState } from './_types'

const initialState: WebSocketState = {
  webSocket: undefined,
  pingTimer: undefined,
}

const setWebSocketReducer = (
  state: WebSocketState,
  { payload }: { payload: WebSocketState },
): void => {
  if (state.pingTimer === undefined) {
    state.pingTimer = createPingTimer(payload.webSocket)
    state.webSocket = payload.webSocket
  } else {
    clearInterval(state.pingTimer)
    state.pingTimer = createPingTimer(payload.webSocket)
    state.webSocket = payload.webSocket
  }
}

const webSocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setWebSocket: setWebSocketReducer,
  },
})

export const webSocketReducer = webSocketSlice.reducer
export const { setWebSocket } = webSocketSlice.actions

export const selectWebSocket = ({ webSockets }: RootState): WebSocket | undefined =>
  webSockets.webSocket
