import { RootState } from '@root/store'
import { FeatureFlagResponseMap } from '@shared/interfaces/feature-flags.interface'
import { isEmptyArray } from '@utils/arrays'

/**
 * Check if fetching feature flags is completed
 * @param featureFlags
 */
export const isFeatureFlagLoaded = ({ featureFlags }: RootState): boolean => featureFlags.isLoaded

/**
 * Select feature flag/s
 * @param featureFlags
 * @param payload - string[] | string
 */
export const selectAppFeatureFlagStatusByName =
  (payload: string | string[]) =>
  ({ featureFlags }: RootState): boolean => {
    const flagName = payload
    const flags = featureFlags?.featureFlags

    if (isEmptyArray(flags) || !flagName) return false
    const flagFilter = (flagsInput: FeatureFlagResponseMap['featureFlags'], name: string) => {
      const flag = flagsInput?.find((flag) => flag.flagName === name)
      return !!flag?.isActive
    }

    if (Array.isArray(flagName) && !isEmptyArray(flagName)) {
      return flagName.every((name) => {
        return flagFilter(flags, name)
      })
    }

    return flagFilter(flags, flagName as string)
  }
