import {
  CouponResponse,
  CouponsState,
  ValidateCouponPayload,
} from '@store/coupons-slice/interfaces'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

export const validateCouponPendingReducer = (state: CouponsState): void => {
  state.status = 'loading'
  state.errorMessages = ''
}

export const validateCouponRejectedReducer = (
  state: CouponsState,
  action: PayloadAction<
    unknown,
    string,
    {
      arg: ValidateCouponPayload
      requestId: string
      requestStatus: 'rejected'
      aborted: boolean
      condition: boolean
    },
    SerializedError
  >,
): void => {
  state.status = 'failed'
  state.errorMessages = action.error.message || 'Something went wrong'
}

export const validateCouponFulfilledReducer = (
  state: CouponsState,
  action: PayloadAction<
    CouponResponse,
    string,
    { arg: ValidateCouponPayload; requestId: string; requestStatus: 'fulfilled' },
    never
  >,
): void => {
  state.status = 'succeeded'
  if (action?.payload) {
    // Only update the response and save the promocode used in state if the coupon is valid
    state.response = { ...action.payload }
    // Save the code to use in state only on success and if the code is valid (We use it in the checkout to the BE)
    state.code = action.payload?.isValid ? action.meta.arg.promotionCode : ''
  }
}

export const resetCouponReducer = (state: CouponsState, action: PayloadAction<void>): void => {
  state.errorMessages = ''
  state.response = {
    data: {
      discountAmountInCents: 0,
      discountedAmountToPayInCents: 0,
      originalAmountInCents: 0,
      serviceFeeInCents: 0,
      totalAmountToPayInCents: 0,
    },
    isValid: null,
  }
  state.status = 'idle'
  state.code = ''
}
