import { Cloudinary } from '@cloudinary/url-gen'
import { crop, scale } from '@cloudinary/url-gen/actions/resize'
import type { ImagePosition } from '@shared/interfaces/image.interface'
import { ImageScaleSpecs } from '@shared/interfaces/image.interface'
import * as cloudinaryConstants from '@shared/constants/cloudinary.constants'
import { CldQualityEnum } from '@shared/enums/cloudinary.enums'
import { DEFAULT_WIDTH_THUMB } from '@root/constants'
import { ApplyScaleToCropImageInterface } from '@shared/interfaces/apply-scale-to-crop-image.interface'

/**
 * Init Cloudinary SDK
 */
function initCloudinary() {
  return new Cloudinary({
    cloud: {
      cloudName: 'vidzing',
    },
  })
}

/**
 * Crop image using Cloudinary SDK
 * @param image
 * @param scaleSpecs
 */
export function cropImage(image: string, scaleSpecs: ImageScaleSpecs): string {
  const cld = initCloudinary()
  const name = getImageName(image)
  const { width, height, x, y } = getSpecs(image)
  return cld
    .image(name)
    .resize(crop().width(width).height(height).y(y).x(x))
    .resize(scale().width(scaleSpecs.width).height(scaleSpecs.height))
    .quality('auto:best')
    .toURL()
}

/**
 * Reverse engineer to get specs from URL. Cloudinary doesn't have this option native on its SDK
 * @param url
 */
export function getSpecs(url: string) {
  const imageData = url.split('/').splice(6, 1).join('').split(',')
  let imgSpecs: ImagePosition = { width: 0, height: 0, x: 0, y: 0 }
  // Image has cropped version
  if (imageData[0] === 'c_crop') {
    imageData.splice(1).map((specs) => {
      const _specs = specs.split('_')
      let spec = ''
      switch (_specs[0]) {
        case 'w':
          spec = 'width'
          break
        case 'h':
          spec = 'height'
          break
        default:
          spec = _specs[0]
      }
      const value: number = parseInt(_specs[1])
      imgSpecs = { ...imgSpecs, [spec]: value }
    })
  }

  return imgSpecs
}

/**
 * Validate if the URL has a crop version
 * @param url
 */
export function hasCropVersion(url: string): boolean {
  const imageData = url ? url.split('/').splice(6, 1).join('').split(',') : ''
  return imageData[0] === 'c_crop'
}

/**
 * Getting image name from URL. Cloudinary doesn't have this native option on its SDK
 * @param url
 */
export function getImageName(url: string) {
  const dataArray = url.split('/')
  // If we don't have stored the cropped version, the string will be different
  const takeFrom = dataArray.length === 10 ? 7 : 8
  return dataArray.slice(takeFrom).join('/').split('?').slice(0, 1).join('')
}

export function applyScaleToCropImage(data: ApplyScaleToCropImageInterface): string {
  // Build the scale query string
  const cldScaleString = [
    cloudinaryConstants.CLD_SCALE_STRING,
    `${cloudinaryConstants.CLD_HEIGHT_QUERY}${data.height}`,
    `${cloudinaryConstants.CLD_WIDTH_QUERY}${data.width}`,
    CldQualityEnum.BEST,
  ].join(',')

  // Split the url by '/'
  const urlArray = data.url.split('/')

  // Add into the 7th position in the array the scale query string
  urlArray.splice(7, 0, cldScaleString)

  // Reuturn the urlArray joining again by '/'
  return urlArray.join('/')
}

/**
 * Function to determine if a pass or subscription image has the new 1:1 crop version
 * @param image
 */
export function isNewCropPassSubImage(image: string): boolean {
  return image.includes('&v=v1')
}
