import { CouponsState } from '@store/coupons-slice/interfaces'

export const INITIAL_STATE: CouponsState = {
  errorMessages: '',
  response: {
    data: {
      discountAmountInCents: 0,
      discountedAmountToPayInCents: 0,
      originalAmountInCents: 0,
      serviceFeeInCents: 0,
      totalAmountToPayInCents: 0,
    },
    isValid: null,
  },
  status: 'idle',
  code: '',
}
