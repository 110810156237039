import type { ContentEntity } from '../_entities/_types'
import axiosRequest from '@apis/axios'
import { GetContentByIdInterface } from '@shared/interfaces/get-content-by-id.interface'

export default async function getContentById({
  contentId,
  channelId,
  countryCode,
  useNewBundles = true,
  withBundleContents = false,
}: GetContentByIdInterface): Promise<ContentEntity> {
  return (await axiosRequest('contents', {
    method: 'GET',
    url: `${contentId}`,
    params: { country: countryCode, channelId, useNewBundles, withBundleContents },
  })) as ContentEntity
}
