import { PlayerState } from '@store/player-slice/_types'

export const setAudioPlayReducer = (
  state: PlayerState,
  { payload }: { payload: boolean },
): void => {
  state.audioPlay = payload
}

export const setVideoPlayReducer = (
  state: PlayerState,
  { payload }: { payload: boolean },
): void => {
  state.videoPlay = payload
}
