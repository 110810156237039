export const UNSUPPORTED_CODE_ERROR = 'Unsupported HTTP status code.'
export const NETWORK_ERROR = 'Network unavailable, please try again later.'
export const UNAUTHENTICATED_ERROR = 'Please log in.'

export const VIDZING_LOGO =
  'https://res.cloudinary.com/vidzing/image/upload/v1624392571/vidzing/global-assets/vidzing-logo_ksw1kq.png'
export const EMAIL_ICON =
  'https://res.cloudinary.com/vidzing/image/upload/v1623896220/vidzing/global-assets/Icon_dtdtcd.svg'
export const SIGN_IN_SPLASH =
  'https://res.cloudinary.com/vidzing/image/upload/v1623895358/vidzing/global-assets/Desktop_gctqvc.png'
export const PHONE_SIGN_IN_SPLASH =
  'https://res.cloudinary.com/vidzing/image/upload/v1623904064/vidzing/global-assets/Splatter_zulx3p.png'

export const MAX_USERS_WATCHING = 1
export const TOO_MANY_USERS_WATCHING = MAX_USERS_WATCHING + 1
