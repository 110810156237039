const convertArrayToObject = <T>(array: T[], keyName: keyof T): { [key: string]: T } => {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      // TODO fix TS error
      [item[keyName] as unknown as string]: item,
    }
  }, initialValue)
}

export default convertArrayToObject
