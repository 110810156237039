import { PayloadAction } from '@reduxjs/toolkit'
import { ActivePlansState } from '@store/user-billing-slice/active-plans/_interfaces'
import { MyVidzActivePlansResponse } from '@shared/interfaces/users-billing/user-billing-my-vidz-plans.interfaces'
import { getUserBillingActivePlansThunk } from '@store/user-billing-slice/active-plans/_thunks'

export const pendingReducer = (state: ActivePlansState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getActivePlansFulfilledReducer = (
  state: ActivePlansState,
  action: PayloadAction<MyVidzActivePlansResponse>,
): void => {
  // Update the subscriptionDetails in the state
  state.data.subscriptionDetails = action.payload.subscriptionDetails

  // Update the passDetails in the state
  state.data.passDetails = action.payload.passDetails

  // Update the fetchStatus to indicate the successful data retrieval
  state.fetchStatus = 'succeeded'

  // Clear any previous error messages
  state.errorMessages = ''
}

export const rejectedReducer = (
  state: ActivePlansState,
  action: ReturnType<typeof getUserBillingActivePlansThunk.rejected>,
): void => {
  state.fetchStatus = 'failed'
  // Here we check if the error is available and set it accordingly
  if (action?.error?.message) {
    state.errorMessages = action.error.message
  } else {
    state.errorMessages = 'Failed to fetch active plans'
  }
}
