import type {
  Audio,
  Bundle,
  ContentEntity,
  ContentPrice,
  ExternalLink,
  Live,
  UserContentState,
  Vod,
} from '@apis/contents-microservice/_entities/_types'
import type { FetchStatus } from '../_types'
import type { RootState } from '..'
import { PurchaseOptionsInterface } from '@shared/interfaces/purchase.options.interface'
import {
  addSecondsToCurrentDate,
  isEqualToOne,
  isGreaterThanOne,
  isGreaterThanZero,
  parseBoolean,
} from '@root/utils/connascence'
import {
  buildPriceStringForContent,
  buildPriceStringForPass,
  buildPriceStringForSubscriptions,
} from '@root/utils/build-price-strings'
import { buildThumbnailImageToUse } from '@root/utils/image'
import { generateTransformationUrl } from '@apis/cloudinary'
import { ResumePlayback } from '@apis/analytics-microservice/_entities/resume-playback'
import { HorizontalNavOptionsInterface } from '@shared/interfaces/horizontal-nav-options.interface'
import {
  CONTENT_CHAPTER_NAV_OPTION,
  CONTENT_RESOURCES_NAV_OPTION,
  getBundleContentNavOption,
  RECOMMENDED_CONTENT_NAV_OPTION,
} from '@shared/constants/content-horizontal-nav-options.constants'
import { isEmptyArray } from '@root/utils/arrays'
import { ContentResourceInterface } from '@shared/interfaces/assets/content-resource.interface'
import { getMuxAssetById } from '@store/asset-slice/_selectors'
import { MuxAssetInterface } from '@shared/interfaces/assets/mux-asset.interface'
import { ExternalLinkTypeEnum } from '@shared/enums/layout/external-link-type.enum'
import { RecommendedContentInterface } from '@shared/interfaces/content/recommended-content/recommended-content'
import { selectChannelPassesByPathname } from '@store/channel-slice/_selectors'
import { AssetResourcesInterface } from '@shared/interfaces/assets/asset-resources.interface'
import {
  buildPriceDataFromContentPrices,
  buildPriceDataFromPass,
  buildPriceDataFromSubscription,
} from '@utils/build-price-data'
import {
  passDurationToSubtitle,
  subscriptionRecurringIntervalToSubtitle,
} from '@utils/subscriptions'
import { EventTrackingDataInterface } from '@shared/interfaces/event-tracking-data.interface'
import { VidzingProductTypesEnum } from '@shared/enums/vidzing-product-types.enum'
import { toDollar } from '@library/_utilities/to-dollar'
import { filterPriceForExpirationAndRental } from '@utils/price-calculations'
import { createSelector } from 'reselect'
import { ContentsState } from '@store/contents-slice/_types'
import { CONTENT_RESUME_PLAYBACK_PLACEHOLDER } from '@shared/constants/content/content-resume.constants'
import { isEmptyString } from '@utils/strings'
import { TagCatalogInterface } from '@shared/interfaces/tag.interface'

export const selectContentPropByContentId =
  (contentId: string) =>
  <PropKey extends keyof ContentEntity>(propKey: PropKey) =>
  ({ contents }: RootState): ContentEntity[PropKey] | undefined => {
    const content = contents.byContentId?.[contentId]
    // If property value is null, return undefined
    return content?.[propKey] ?? undefined
  }

export const selectLivePropByContentId =
  (contentId: string) =>
  <PropKey extends keyof Live>(propKey: PropKey) =>
  ({ contents }: RootState): Live[PropKey] | undefined => {
    const content = contents.byContentId?.[contentId]

    // If property value is null, return undefined
    return content?.live?.[propKey] ?? undefined
  }

export const selectVodPropByContentId =
  (contentId: string) =>
  <PropKey extends keyof Vod>(propKey: PropKey) =>
  ({ contents }: RootState): Vod[PropKey] | undefined => {
    const content = contents.byContentId?.[contentId]

    // If property value is null, return undefined
    return content?.vod?.[propKey] ?? undefined
  }

export const selectAudioPropByContentId =
  (contentId: string) =>
  <PropKey extends keyof Audio>(propKey: PropKey) =>
  ({ contents }: RootState): Audio[PropKey] | undefined => {
    const content = contents.byContentId?.[contentId]

    // If property value is null, return undefined
    return content?.audio?.[propKey] ?? undefined
  }

export const selectExternalLinkPropByContentId =
  (contentId: string) =>
  <PropKey extends keyof ExternalLink>(propKey: PropKey) =>
  ({ contents }: RootState): ExternalLink[PropKey] | undefined => {
    const content = contents.byContentId?.[contentId]

    // If property value is null, return undefined
    return content?.externalLink?.[propKey] ?? undefined
  }

export const selectBundlePropByContentId =
  (contentId: string) =>
  <PropKey extends keyof Bundle>(propKey: PropKey) =>
  ({ contents }: RootState): Bundle[PropKey] | undefined => {
    const content = contents.byContentId?.[contentId]

    // If property value is null, return undefined
    return content?.bundle?.[propKey] ?? undefined
  }

export const selectContentPricesByContentId =
  (contentId: string) =>
  ({ contents }: RootState): ContentPrice[] | [] => {
    const content = contents.byContentId?.[contentId]

    if (!isEmptyArray(content?.contentPrices)) {
      if (!parseBoolean(process.env.VIDZING_RENTAL_PRICE)) {
        // TODO: Remove once the rental feature flag is implemented
        // If there is more than one price sort the prices by price ascending and return
        if (isGreaterThanOne(content.contentPrices.length)) {
          const pricesArray = [...content.contentPrices]
          return pricesArray.sort((a, b) => a.price - b.price)
        }
        // If there is only one price return the price
        return content.contentPrices
      } else {
        const pricesArray = [...content.contentPrices]
        const pricesArrayFiltered = filterPriceForExpirationAndRental(pricesArray, content)
        return pricesArrayFiltered.sort((a, b) => a.price - b.price)
      }
    }

    // If no prices return empty array
    return []
  }

/**
 * Select the content data for event tracking
 * @param contentId
 */
export const selectContentDataForEventTracking =
  (contentId: string) =>
  ({ contents, cart }: RootState): EventTrackingDataInterface => {
    const content = contents.byContentId?.[contentId]
    if (content && !isEmptyArray(content.contentPrices)) {
      // Instantiate contentData object with product name and type
      const contentData: Partial<EventTrackingDataInterface> = {
        product_name: content.title,
        product_type: VidzingProductTypesEnum.CONTENT,
      }
      // If the content has one price use that price
      if (isEqualToOne(content.contentPrices.length)) {
        contentData.currency = content.contentPrices[0].currency
        contentData.value = toDollar(content.contentPrices[0].price)
        // If the content has more than one price and the cart has a price for the contentId use the selected price from the cart
      } else if (
        isGreaterThanOne(content.contentPrices.length) &&
        !isEmptyArray(cart.contentItems)
      ) {
        const cartItem = cart.contentItems.find((item) => item.contentId === contentId)
        if (cartItem) {
          contentData.value = toDollar(cartItem.price)
          contentData.currency = content.contentPrices[0].currency
        }
        // Otherwise the content has no prices so return 0
      }
      return contentData as EventTrackingDataInterface
    }
    return undefined
  }

export const selectContentStateForContentById =
  (contentId: string) =>
  ({ contents }: RootState): UserContentState => {
    const content = contents.byContentId?.[contentId]
    // If property value is null, return undefined
    return content?.state.button ?? undefined
  }

export const selectStatus = ({ contents }: RootState): FetchStatus => {
  return contents.fetchStatus
}

export const selectErrorMessages = ({ contents }: RootState): string => {
  return contents.errorMessages
}

// Geo-blocking selectors
export const selectContentGeoBlockCountries =
  (contentId: string) =>
  ({ contents }: RootState): string[] => {
    const content = contents.byContentId?.[contentId]
    return content?.geoBlocking?.countries ?? []
  }

export const selectContentGeoBlockAllowed =
  (contentId: string) =>
  ({ contents }: RootState): boolean => {
    const content = contents.byContentId?.[contentId]
    return content?.geoBlocking?.access !== 'blocked'
  }

export const selectContentById =
  (contentId: string) =>
  ({ contents }: RootState): ContentEntity => {
    return contents.byContentId?.[contentId]
  }

/**
 * Select all purchase options that a content Id has
 * @param contentId
 * @param channelPathname
 */
export const selectPurchaseOptions =
  (contentId: string, channelPathname: string) =>
  (rootState: RootState): PurchaseOptionsInterface[] => {
    // Get content and channel by contentId
    const content = rootState.contents.byContentId?.[contentId]
    const channel = rootState.channels.byChannelPathname?.[channelPathname]

    // Check if user is subscribed to the channel, they can be subscribed to any subscription on the channel, so we can remove any pass purchase options
    const isSubscribedToChannel =
      rootState.subscribers.byChannelPathname?.[channelPathname]?.isSubscribed ?? false

    // Instantiate purchaseOptions array
    const purchaseOptions: PurchaseOptionsInterface[] = []

    // If the channel has subscriptions & NOT premium - add them to the purchase options
    if (
      !content?.premium &&
      channel?.channelSubscription &&
      isGreaterThanZero(channel.channelSubscription.length)
    ) {
      const channelSubscriptions = [...channel.channelSubscription]
      channelSubscriptions
        .sort((a, b) => a.subscription?.price - b.subscription?.price)
        .map((channelSubscription) => {
          const { subscription } = channelSubscription

          // Check if user is subscribed to the subscription
          const isSubscribedToSubscription =
            rootState.subscribers.bySubscriptionId?.[subscription.subscriptionId]?.isSubscribed ??
            false

          // If the user is not subscribed to any subscription at all then add all subscriptions to the purchase options
          if (!isSubscribedToChannel) {
            purchaseOptions.push({
              type: 'subscription',
              imageUrl: generateTransformationUrl(subscription.thumbnail, {
                width: 100,
                aspectRatio: '16:9',
                quality: 'auto',
              }),
              title: subscription.subscriptionName,
              price: buildPriceStringForSubscriptions(subscription),
              subscriptionId: subscription.subscriptionId,
              priceData: buildPriceDataFromSubscription(subscription),
              subscriptionFrequency: subscriptionRecurringIntervalToSubtitle(
                subscription.subscriptionRecurringInterval,
              ),
              unlisted: subscription.unlisted,
            })
          } // If the user is subscribed to the subscription then only add the subscription to the purchase options
          // to show the user what subscription they have active
          else if (isSubscribedToSubscription) {
            purchaseOptions.push({
              type: 'subscription',
              imageUrl: generateTransformationUrl(subscription.thumbnail, {
                width: 100,
                aspectRatio: '16:9',
                quality: 'auto',
              }),
              title: subscription.subscriptionName,
              price: buildPriceStringForSubscriptions(subscription),
              subscriptionId: subscription.subscriptionId,
              priceData: buildPriceDataFromSubscription(subscription),
              subscriptionFrequency: subscriptionRecurringIntervalToSubtitle(
                subscription.subscriptionRecurringInterval,
              ),
              isSubscribed: true,
            })
          }
        })
    }

    // If a channel has a channel pass & NOT premium add it to the purchase options
    const passes = selectChannelPassesByPathname(rootState, channelPathname)
    // If user is subscribed then don't show passes
    if (!isSubscribedToChannel && !content?.premium && !isEmptyArray(passes)) {
      passes.map((pass) => {
        // Check if user is subscribed to the subscription
        const hasActivePass = rootState.passes.byPassStatusById?.[pass.passId]?.isActive ?? false
        purchaseOptions.push({
          type: 'pass',
          imageUrl: generateTransformationUrl(pass.thumbnail, {
            width: 100,
            aspectRatio: '16:9',
            quality: 'auto',
          }),
          title: pass.name,
          price: buildPriceStringForPass(pass),
          passId: pass.passId,
          priceData: buildPriceDataFromPass(pass),
          passDuration: passDurationToSubtitle(pass.durationInDays),
          hasActivePass,
        })
      })
    }

    // If the content is part of any bundles add them to the purchase options
    if (content?.bundles && isGreaterThanZero(content.bundles.length)) {
      content.bundles.map((bundle) => {
        purchaseOptions.push({
          type: 'bundle',
          imageUrl: buildThumbnailImageToUse(bundle.content.thumbnail, 100, '16:9'),
          title: bundle.content.title,
          price: buildPriceStringForContent(bundle.content.contentPrices),
          bundleContentId: bundle.content.contentId,
          priceData: buildPriceDataFromContentPrices(bundle.content.contentPrices),
          hasBundleAccess: selectHasAccessToContent(bundle.content.contentId)(rootState),
          isPromo: bundle.content.promotional,
        })
      })
    }

    return purchaseOptions
  }

/**
 * Select content length by ID.
 * Only Audio and Vod content have a length
 * If content type is audio or vod return the length
 * otherwise return undefined
 * @param contentId
 */
export const selectContentLengthById =
  (contentId: string) =>
  ({ contents }: RootState): number => {
    const content = contents.byContentId?.[contentId]

    if (content) {
      switch (content.contentType) {
        case 'audio':
          return content.audio?.length ?? 0
        case 'vod':
          return content.vod?.length ?? 0
        default:
          return undefined
      }
    }

    return undefined
  }

/**
 * Select Content Resume Playback by id
 * @param contentId
 */
export const selectContentResumePlaybackById =
  (contentId: string) =>
  ({ contents }: RootState): ResumePlayback => {
    return contents.contentResumePlayback.find(
      (resumePlayback) => resumePlayback?.contentId === contentId,
    )
  }

/**
 * Select Content Resume Playback by id is memorising the data
 * @param contentId
 */
export const selectContentResumePlaybackByIdV2 = (contentId: string) =>
  createSelector(
    (state: RootState) => state.contents,
    (contentState: ContentsState): ResumePlayback => {
      const contentResume = contentState.contentResumePlayback.find(
        (resumePlayback) => resumePlayback?.contentId === contentId,
      )

      return contentResume ? contentResume : { ...CONTENT_RESUME_PLAYBACK_PLACEHOLDER, contentId }
    },
  )

export const selectContentResumePlaybackFetchStatus = createSelector(
  (state: RootState) => state.contents,
  (contentState: ContentsState): FetchStatus => contentState.fetchPlaybackStatus,
)

export const selectContentNavOptions =
  (contentId: string) =>
  (state: RootState): HorizontalNavOptionsInterface[] => {
    const { contents } = state
    // Get content from state
    const content = contents.byContentId?.[contentId]

    // Get resources for content from state
    const contentResources = contents.contentResources.filter(
      (contentResource) => contentResource.contentId === contentId,
    )

    // Initialize horizontal nav options empty array
    const horizontalNavOptions: HorizontalNavOptionsInterface[] = []

    if (content) {
      // If the content is a bundle then push the bundle content nav option
      if (content.contentType === 'bundle') {
        // Get bundle from state
        const { bundles } = state
        const bundle = bundles.byContentId?.[contentId]
        // On the first endpoint, the content does not have the new bundles thing. So the first run of this selector, the bundle type is undefined.
        // And when it connects to the second endpoint and I get the new bundles, it is now defined.
        const bundleContentNavOption = getBundleContentNavOption(
          bundle?.bundle?.bundleType?.typeName,
        )
        horizontalNavOptions.push(bundleContentNavOption)
      }

      // If the content type is vod or audio and there are mux chapters, push the content chapter nav option
      if (content.contentType === 'vod' || content.contentType === 'audio') {
        const id =
          content.contentType === 'audio'
            ? content.audio.muxAssetId
            : content.contentType === 'vod'
              ? content.vod.muxAssetId
              : null
        if (id) {
          const muxAsset = getMuxAssetById(id)(state)
          if (!!muxAsset?.muxAssetChapters?.length) {
            horizontalNavOptions.push(CONTENT_CHAPTER_NAV_OPTION)
          }
        }
      }

      // If there are content resources push the content resources nav option
      if (!isEmptyArray(contentResources)) {
        horizontalNavOptions.push(CONTENT_RESOURCES_NAV_OPTION)
      }

      // If there is a recommended content list push the recommended content to the nav option
      if (!isEmptyArray(selectAllRecommendedContentByContentId(contentId)(state))) {
        horizontalNavOptions.push(RECOMMENDED_CONTENT_NAV_OPTION)
      }
    }
    return horizontalNavOptions
  }

export const selectMuxAssetByContentId =
  (contentId: string) =>
  (state: RootState): MuxAssetInterface | undefined => {
    const { contents } = state
    const content = contents.byContentId?.[contentId]
    if (content.contentType === 'vod' || content.contentType === 'audio') {
      const id =
        content.contentType === 'audio'
          ? content.audio.muxAssetId
          : content.contentType === 'vod'
            ? content.vod.muxAssetId
            : null
      if (id) {
        return getMuxAssetById(id)(state)
      }
    }
    return undefined
  }

export const selectContentResourcesById =
  (contentId: string) =>
  ({ contents }: RootState): ContentResourceInterface[] => {
    return contents.contentResources.filter(
      (contentResource) => contentResource.contentId === contentId,
    )
  }

export const selectContentAssetResourcesById =
  (contentId: string) =>
  ({ contents }: RootState): AssetResourcesInterface[] => {
    return contents.contentResources
      .filter((contentResource) => contentResource.contentId === contentId)
      .map((contentResource) => contentResource.resource)
  }

export const selectHasAccessToContent =
  (contentId: string) =>
  ({ contents }: RootState): boolean => {
    const content = contents.byContentId?.[contentId]

    /**
     * If content is an external link and type is schedule virtual event meeting
     * and the state is book now. This means the user can access the content and book
     * a one on one meeting
     */
    if (
      content &&
      content?.contentType === 'external-link' &&
      content?.externalLink?.externalLinkType ===
        ExternalLinkTypeEnum.SCHEDULE_VIRTUAL_EVENT_MEETING &&
      content?.state?.button === 'bookNow'
    ) {
      return true
    }

    /**
     * If content and state exists and if user has access to the content
     * they can access the resources otherwise user must purchase
     */
    if (content && content.state) {
      switch (content.state.button) {
        case 'booked':
        case 'purchased':
        case 'subscribed':
        case 'watchNow':
        case 'joinNow':
        case 'listenNow':
          return true
        default:
          return false
      }
    }

    return false
  }

export const selectCanWatchContent =
  (contentId: string) =>
  ({ contents }: RootState): boolean => {
    const content = contents.byContentId?.[contentId]

    /**
     * If content and state exists and if user has access to the content
     * they can access the chapter otherwise user must purchase
     */
    if (content && content.state) {
      switch (content.state.button) {
        case 'watchNow':
        case 'listenNow':
          return true
        default:
          return false
      }
    }

    return false
  }

/**
 * Select featured content by channel pathname.
 * Currently, we are only supporting one featured content. But this can be expanded to support more. as the BE
 * is returning an array of featured content.
 * @param channelPathname
 */
export const selectFeaturedContentIdByChannelPathname =
  (channelPathname: string) =>
  (rootState: RootState): ContentEntity => {
    const channelId = rootState.channels.byChannelPathname?.[channelPathname]?.channelId
    const featuredContents = rootState.contents.featuredContentByChanelId?.[channelId]

    if (!isEmptyArray(featuredContents)) {
      return featuredContents[0]
    }

    return null
  }

export const selectOneOnOneMeetingLink =
  (contentId: string) =>
  ({ contents }: RootState): string => {
    const content = contents.byContentId?.[contentId]

    // If the content is an external link & the external link type is schedule virtual event/meeting
    // And the content.state.button = bookNow then this is a free featured content, so we need to ignore the
    // current purchase flow and bounce the user straight out to the external link
    if (
      content &&
      content?.contentType === 'external-link' &&
      content?.externalLink?.externalLinkType ===
        ExternalLinkTypeEnum.SCHEDULE_VIRTUAL_EVENT_MEETING &&
      content?.state?.button === 'bookNow'
    ) {
      return content?.externalLink?.externalLinkUrl
    }

    return undefined
  }

/**
 * Select all the recommended content by content id.
 * @param contentId
 */
export const selectAllRecommendedContentByContentId =
  (contentId: string) =>
  ({ contents }: RootState): RecommendedContentInterface[] => {
    return contents?.recommendedContentByContentId?.[contentId]
  }

// Select from the content the date it is available to watch
export const selectContentScheduledAvailableDateById =
  (contentId: string) =>
  ({ contents }: RootState): Date => {
    const content = contents.byContentId?.[contentId]
    if (content) {
      switch (content.contentType) {
        case 'live':
          return new Date(content.live.scheduledStartTime)
        case 'vod':
        case 'audio':
        case 'bundle':
          return new Date(content.scheduledReleaseDate)
        case 'external-link':
          return new Date(content.externalLink.scheduledStartTime)
      }
    }
    return undefined
  }

/**
 * Selector to determine if the content can be played or joined if external link
 * @param contentId
 */
export const selectCanPlayOrJoinContent =
  (contentId: string) =>
  ({ contents }: RootState): boolean => {
    // Get content from state
    const content = contents.byContentId?.[contentId]

    // If content exists check if the required mux data exists or external link url exists
    if (content) {
      switch (content.contentType) {
        case 'live':
          return !!content.live?.muxLiveStreamId && content.state?.button === 'watchNow'
        case 'vod':
          return !!content.vod?.muxAssetId && content.state?.button === 'watchNow'
        case 'audio':
          return !!content.audio?.muxAssetId && content.state?.button === 'listenNow'
        case 'external-link':
          return !!content.externalLink?.externalLinkUrl && content.state?.button === 'joinNow'
      }
    }
    return false
  }

/**
 * Select the content state expiry date by content id
 * @param contentId
 */
export const selectContentStateExpiryByContentId =
  (contentId: string) =>
  ({ contents }: RootState): string | null => {
    const expiresIn = contents.byContentId?.[contentId]?.state?.access?.expiresIn
    if (expiresIn) {
      return addSecondsToCurrentDate(expiresIn).toISOString()
    }
    return null
  }

/**
 * Get content tags (String value )
 */
export const selectContentTagsById = (contentId: string) => {
  return createSelector(
    (state: RootState) => state.contents,
    (contents: ContentsState): string[] => {
      // No Content id
      if (isEmptyString(contentId)) return []

      const content = contents.byContentId?.[contentId]
      if (content && !isEmptyArray(content?.readableContentTags)) {
        return content?.readableContentTags.map((tag) => tag.name)
      }

      return []
    },
  )
}

/**
 * Get Content tags ( Full data )
 */
export const selectContentTagsFullDataById = (contentId: string) => {
  return createSelector(
    (state: RootState) => state.contents,
    (contents: ContentsState): TagCatalogInterface[] => {
      // No Content id
      if (isEmptyString(contentId)) return []

      const content = contents.byContentId?.[contentId]
      if (content && !isEmptyArray(content?.readableContentTags)) {
        return content?.readableContentTags ?? []
      }

      return []
    },
  )
}
