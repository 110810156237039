import { SubscriptionRecurringType } from '@shared/types/subscription.recurring.type'

export function subscriptionRecurringIntervalToSubtitle(
  recurringInterval: SubscriptionRecurringType,
): string {
  switch (recurringInterval) {
    case 'month':
      return 'Monthly Subscription'
    case 'year':
      return 'Annual Subscription'
    case 'day':
      return 'Daily Subscription'
    case 'week':
      return 'Weekly Subscription'
  }
}

export function passDurationToSubtitle(durationInDays: number): string {
  return `${durationInDays} Days Access`
}
