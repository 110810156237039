import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  setUserPendingReducer,
  setTooManyUsersWatchingReducer,
  setUserReducer,
  setUserRejectedReducer,
  setWatchingReducer,
  userLogoutReducer,
} from './_reducers'
import { getUserVizingDataThunk } from './_thunk'
import { UserEntity, UserTypeEnum } from '@apis/users-microservice/_types'
import { FetchStatus } from '@store/_types'

export const initialState: UserEntity = {
  administrativeArea: '',
  country: '',
  dateOfBirth: undefined,
  email: '',
  emailVerified: false,
  firebaseUid: '',
  firstName: '',
  fetchStatus: 'idle',
  gender: '',
  lastName: '',
  locality: '',
  marketingSubscribed: false,
  stripeToken: '',
  thoroughfare: '',
  tooManyUsersWatching: false,
  userId: '',
  userType: UserTypeEnum.VIEWER,
  watching: 0,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogout: userLogoutReducer,
    setWatching: setWatchingReducer,
    setTooManyUsersWatching: setTooManyUsersWatchingReducer,
    setUserMock: (userState, { payload }: PayloadAction<string>) => {
      userState.userId = payload
    },
  },
  extraReducers: {
    [getUserVizingDataThunk.pending.toString()]: setUserPendingReducer,
    [getUserVizingDataThunk.fulfilled.toString()]: setUserReducer,
    [getUserVizingDataThunk.rejected.toString()]: setUserRejectedReducer,
  },
})

export const userReducer = userSlice.reducer
export const { setWatching, setTooManyUsersWatching, userLogout, setUserMock } = userSlice.actions
