import { ModalInterface } from '@shared/interfaces/modal.interface'
import { ReactNode } from 'react'

export const openModalReducer = (
  state: ModalInterface,
  { payload }: { payload: ReactNode },
): void => {
  state.status = 'open'
  state.content = payload
}

export const closeModalReducer = (state: ModalInterface): void => {
  state.status = 'close'
  state.content = undefined
}
