import { FeatureFlagState } from '@store/feature-flag-slice/_types'
import { createSlice } from '@reduxjs/toolkit'
import { setFeatureFlags, setFeatureFlagsFailed } from '@store/feature-flag-slice/_reducers'
import { fetchFeatureFlagsThunk } from '@store/feature-flag-slice/_thunks'

const initialState: FeatureFlagState = {
  isLoaded: false,
  featureFlags: [],
}

const featureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureFlagsThunk.fulfilled, setFeatureFlags)
    builder.addCase(fetchFeatureFlagsThunk.rejected, setFeatureFlagsFailed)
  },
})

export const featureFlagReducer = featureFlagSlice.reducer
