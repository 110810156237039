import { PaymentMethodState } from '@store/user-billing-slice/payment-methods/_interfaces'
import {
  SubscriptionDefaultPaymentMethodResponse,
  GenerateClientSecretResponse,
  GetPaymentMethodResponse,
  UserDefaultPaymentMethodResponse,
} from '@shared/interfaces/users-billing/user-billing-payment-methods.interfaces'

export const pendingReducer = (state: PaymentMethodState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getPaymentMethodsFulfilledReducer = (
  state: PaymentMethodState,
  {
    payload,
  }: {
    payload: GetPaymentMethodResponse
  },
): void => {
  const { paymentMethods, stripeCustomer } = payload

  // Update the paymentMethods in the state
  state.data.paymentMethods = paymentMethods

  // Update the stripeCustomer in the state
  state.data.stripeCustomer = stripeCustomer

  // Update the fetchStatus to indicate the successful data retrieval
  state.fetchStatus = 'succeeded'

  // Clear any previous error messages
  state.errorMessages = ''
}

export const generateClientSecretFulfilledReducer = (
  state: PaymentMethodState,
  {
    payload,
  }: {
    payload: GenerateClientSecretResponse
  },
): void => {
  const { clientSecret } = payload

  state.data.clientSecret = clientSecret

  // Update the fetchStatus to indicate the successful data retrieval
  state.fetchStatus = 'succeeded'

  // Clear any previous error messages
  state.errorMessages = ''
}

export const patchUserDefaultPaymentMethodFulfilledReducer = (
  state: PaymentMethodState,
  {
    payload,
  }: {
    payload: UserDefaultPaymentMethodResponse
  },
): void => {
  // Update the stripeCustomer in the state
  state.data.stripeCustomer = payload

  // Update the fetchStatus to indicate the successful data retrieval
  state.fetchStatus = 'succeeded'

  // Clear any previous error messages
  state.errorMessages = ''
}

export const rejectedReducer = (
  state: PaymentMethodState,
  { error }: { error: { message: string } },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = error.message
}
