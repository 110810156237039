import { getUserBillingInvoicesThunk } from './_thunks'
import { createSlice } from '@reduxjs/toolkit'
import { getInvoicesFulfilledReducer, pendingReducer, rejectedReducer } from './_reducers'
import { initialUserBillingInvoicesState } from './_types'

const userBillingInvoicesSlice = createSlice({
  name: 'user-billing-invoices',
  initialState: initialUserBillingInvoicesState,
  reducers: {
    resetUserBillingState: (state) => initialUserBillingInvoicesState,
  },
  extraReducers: {
    [getUserBillingInvoicesThunk.pending.toString()]: pendingReducer,
    [getUserBillingInvoicesThunk.fulfilled.toString()]: getInvoicesFulfilledReducer,
    [getUserBillingInvoicesThunk.rejected.toString()]: rejectedReducer,
  },
})

export const userBillingInvoicesReducer = userBillingInvoicesSlice.reducer
