import type { AppDispatch } from '@root/store'
import { setTooManyUsersWatching, setWatching } from '@store/user-slice'
import fbAuth from '../firebase'
import { openWebSocket, ReOpenFunctionType } from './_hooks/use-websocket-initialize'
import { datadogLogs } from '@datadog/browser-logs'
import { GUEST_USER } from '@root/constants'
import { SocketMessageInterface } from '@shared/interfaces/player/socket.interface'

export type WebSocketResponse = {
  connectionId?: string
  watching?: number
  email?: string
  message?: string
  usersWatching?: number
  maxAllowedAmount?: number
}

export async function WebSocketOnUserPlays(
  socket: WebSocket | undefined,
  dispatch: AppDispatch,
): Promise<void> {
  if (socket !== undefined) {
    if (socket.readyState === WebSocket.OPEN) {
      const token = await fbAuth.currentUser?.getIdToken()

      socket.send(
        JSON.stringify({
          action: 'onUserPlays',
          data: {
            token: `Bearer ${token}`,
          },
        }),
      )

      socket.onmessage = (message) => {
        try {
          const socketMessage: WebSocketResponse = JSON.parse(message.data)
          if (socketMessage.watching) {
            dispatch(setWatching(socketMessage.watching))
          } else if (socketMessage.maxAllowedAmount) {
            dispatch(setTooManyUsersWatching(true))
          }
        } catch (error) {
          datadogLogs.logger.warn('Error extracting web socket message', { error, message })
        }
      }
    } else {
      openWebSocket(dispatch, ReOpenFunctionType.ON_USER_PLAYS)
    }
  }
}

export async function WebSocketOnUserPlaying(
  message: SocketMessageInterface,
  socket: WebSocket | undefined,
): Promise<void> {
  if (socket !== undefined) {
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          action: 'OnUserPlaying',
          data: {
            userId: !message.userId ? GUEST_USER : message.userId,
            contentId: message.contentId,
            currentTime: message.currentTime,
            channelId: message.channelId,
            bundleId: message.bundleId,
            organisationId: message.organisationId,
            interval: message.interval,
          },
        }),
      )
    }
  }
}

export async function WebSocketOnUserStops(
  socket: WebSocket | undefined,
  dispatch: AppDispatch,
): Promise<void> {
  if (socket !== undefined) {
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          action: 'onUserStops',
        }),
      )

      socket.onmessage = (message) => {
        try {
          const socketMessage: WebSocketResponse = JSON.parse(message.data)
          if (socketMessage.watching === 0) {
            dispatch(setWatching(socketMessage.watching))
            dispatch(setTooManyUsersWatching(false))
          }
        } catch (error) {
          datadogLogs.logger.warn('Error extracting web socket message', { error, message })
        }
      }
    }
  } else {
    openWebSocket(dispatch, ReOpenFunctionType.ON_USER_STOPS)
  }
}

export async function WebSocketPing(socket: WebSocket | undefined): Promise<void> {
  if (socket !== undefined) {
    socket.send('ping')
  }
}
