import { cancelSubscriptionThunk, getUserBillingDetailsThunk } from './_thunks'
import { createSlice } from '@reduxjs/toolkit'
import {
  subscriptionDefaultPaymentMethodFulfilledReducer,
  pendingReducer,
  rejectedReducer,
  userBillingFulfilledReducer,
} from './_reducers'
import { initialUserBillingState } from './_types'
import { updateSubscriptionDefaultPaymentMethodThunk } from '@store/user-billing-slice/payment-methods/_thunks'

const userBillingSlice = createSlice({
  name: 'user-billing',
  initialState: initialUserBillingState,
  reducers: {
    resetUserBillingState: (state) => initialUserBillingState,
  },
  extraReducers: {
    [getUserBillingDetailsThunk.pending.toString()]: pendingReducer,
    [getUserBillingDetailsThunk.fulfilled.toString()]: userBillingFulfilledReducer,
    [getUserBillingDetailsThunk.rejected.toString()]: rejectedReducer,
    [cancelSubscriptionThunk.pending.toString()]: pendingReducer,
    [cancelSubscriptionThunk.fulfilled.toString()]: userBillingFulfilledReducer,
    [cancelSubscriptionThunk.rejected.toString()]: rejectedReducer,
    [updateSubscriptionDefaultPaymentMethodThunk.fulfilled.toString()]:
      subscriptionDefaultPaymentMethodFulfilledReducer,
    [updateSubscriptionDefaultPaymentMethodThunk.pending.toString()]: pendingReducer,
    [updateSubscriptionDefaultPaymentMethodThunk.rejected.toString()]: rejectedReducer,
  },
})

export const userBillingReducer = userBillingSlice.reducer
