import type { SubscriberState } from './_types'
import { GetSubscriptionStatusInterface } from '@store/subscriber-slice/_interfaces'

export const pendingReducer = (state: SubscriberState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const subscriberFulfilledReducer = (
  state: SubscriberState,
  {
    payload,
  }: {
    payload: GetSubscriptionStatusInterface
  },
): void => {
  const { channelPathname, subscriptionId, isSubscribed, userSubscriptionDetails } = payload

  // Populate byChannelPathname state
  // If previously subscribed state is true, then keep it true
  // A channel can have multiple subscriptions and the order in which the status of each subscription is returned is not guaranteed
  const previousSubscribedState = state.byChannelPathname[channelPathname]
  state.byChannelPathname = {
    ...state.byChannelPathname,
    [channelPathname]: previousSubscribedState?.isSubscribed
      ? previousSubscribedState
      : {
          isSubscribed: isSubscribed,
          userSubscriptionDetails,
        },
  }

  // Populate bySubscriptionId state
  state.bySubscriptionId = {
    ...state.bySubscriptionId,
    [subscriptionId]: { isSubscribed, userSubscriptionDetails },
  }

  state.fetchStatus = 'succeeded'
  state.errorMessages = ''
}

export const rejectedReducer = (
  state: SubscriberState,
  { error }: { error: { message: string } },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = error.message
}
