import { OrganisationState } from '@store/organisation-slice/_types'
import { OrganisationInterface } from '@shared/interfaces/creators/organisation.interface'

export const getOrganisationPendingReducer = (state: OrganisationState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getOrganisationRejectedReducer = (
  state: OrganisationState,
  { payload }: { payload: string },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = payload
}

export const getOrganisationFulfilled = (
  state: OrganisationState,
  { payload }: { payload: OrganisationInterface },
): void => {
  state.fetchStatus = 'succeeded'
  state.errorMessages = ''
  const index = state.organisations.findIndex(
    (organisation) => organisation.organisationId === payload.organisationId,
  )
  if (index !== -1) {
    state.organisations[index] = payload
  } else {
    state.organisations = [...state.organisations, ...[payload]]
  }
}

export const resetFetchStatusAndErrorMessages = (state: OrganisationState): void => {
  state.fetchStatus = 'idle'
  state.errorMessages = ''
}
