import { ActivePlansState } from '@store/user-billing-slice/active-plans/_interfaces'

export const initialUserBillingActivePlansState: ActivePlansState = {
  data: {
    subscriptionDetails: [],
    passDetails: [],
  },
  errorMessages: '',
  fetchStatus: 'idle',
}
