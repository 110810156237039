import { ContentPrice } from '@apis/contents-microservice/_entities/_types'
import { isGreaterThanOne, isGreaterThanZero } from '@utils/connascence'
import { PriceData } from '@shared/interfaces/price-data.interface'
import { SubscriptionInterface } from '@shared/interfaces/creators/subscription/subscription.interface'
import { PassInterface } from '@shared/interfaces/creators/channel-pass/pass.interface'
import { getAvailabilityLabel } from '@utils/price-calculations'

/**
 * Function to build price data for an array of content prices
 * @param contentPrices
 */
export function buildPriceDataFromContentPrices(contentPrices: ContentPrice[]): PriceData {
  if (isGreaterThanZero(contentPrices.length)) {
    // convert the first price to price data
    const priceData = convertContentPriceToPriceData(contentPrices[0])
    // if there are more than one price, set hasMorePrices to true
    priceData.hasMorePrices = isGreaterThanOne(contentPrices.length)
    return priceData
  }
  return undefined
}

/**
 * Function to convert content price to price data
 * @param contentPrice
 */
export function convertContentPriceToPriceData(contentPrice: ContentPrice): PriceData {
  const duration: string | undefined = getAvailabilityLabel(contentPrice)
  return {
    price: contentPrice.price,
    currency: contentPrice.currency,
    label: contentPrice.label,
    duration,
  }
}

export function buildPriceDataFromSubscription(subscription: SubscriptionInterface): PriceData {
  return {
    price: subscription.price,
    currency: subscription.currency,
    label: subscription.subscriptionName,
  }
}

export function buildPriceDataFromPass(pass: PassInterface): PriceData {
  return {
    price: pass.price,
    currency: pass.currency,
    label: pass.name,
  }
}
