import { GA4_DEV_UAT_ID, GA4_PROD_ID } from './constants'
import { gtmConfig } from './gtm.config.data'
import type { AppEnvironments } from '../_utilities/environment/api.url.details'
import type { GTMConfig } from './types'

export class GTMEnvironmentConfig {
  public appEnv: AppEnvironments

  constructor(environment: AppEnvironments) {
    this.appEnv = environment
  }

  getGtmConfig(): GTMConfig {
    if (this.appEnv === 'local') {
      const config = gtmConfig.dev
      return config
    }
    return gtmConfig[this.appEnv]
  }

  getGaId(): string {
    if (this.appEnv === 'prod') {
      return GA4_PROD_ID
    }
    return GA4_DEV_UAT_ID
  }
}

export const GTM_CONFIG = new GTMEnvironmentConfig(
  <'local' | 'prod' | 'dev' | 'uat'>process.env.ENVIRONMENT,
).getGtmConfig()

export const GA4_ID = new GTMEnvironmentConfig(
  <'local' | 'prod' | 'dev' | 'uat'>process.env.ENVIRONMENT,
).getGaId()
