import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import { UserDefaultPaymentMethodResponse } from '@shared/interfaces/users-billing/user-billing-payment-methods.interfaces'

export default async function patchUserDefaultPaymentMethod(request: {
  paymentMethodId: string
}): Promise<UserDefaultPaymentMethodResponse> {
  datadogLogs.logger.info('Making an update user default payment method API request', { request })

  // Sets the payment method as the default payment method
  const response = (await axiosRequest(
    'billing',
    {
      method: 'PATCH',
      url: `user-subscriptions/default-payment-method/${request.paymentMethodId}`,
    },
    true,
  )) as UserDefaultPaymentMethodResponse

  datadogLogs.logger.info('Successful update for user default payment method API request', {
    response,
    request,
  })

  return response
}
