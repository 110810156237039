import convertArrayToObject from '../_utilities/convert-array-to-object'
import type { ChannelState } from './_types'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'

export const getChannelPendingReducer = (state: ChannelState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getChannelFulfilledReducer = (
  state: ChannelState,
  { payload: newChannelEntities }: { payload: ChannelInterface[] },
): void => {
  state.byChannelPathname = {
    ...convertArrayToObject<ChannelInterface>(newChannelEntities, 'channelUrl'),
  }
  state.fetchStatus = 'succeeded'
  state.errorMessages = ''
}

export const getChannelRejectedReducer = (
  state: ChannelState,
  { error }: { error: { message: string } },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = error.message
}
