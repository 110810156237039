import firebase from 'firebase/app'
import 'firebase/auth'
import { FIREBASE_CONFIG } from './firebase.config'

export type FirebaseConfig = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

function getFbAuth(config: FirebaseConfig): firebase.auth.Auth {
  if (!firebase.apps.length) {
    // Initialize firebase.
    return firebase.initializeApp(config).auth()
  } else {
    // If firebase already initialized, use that one.
    return firebase.app().auth()
  }
}

const fbAuth = getFbAuth(FIREBASE_CONFIG)

export default fbAuth
