import type { GeoState } from './_types'
import type { GeoEntry } from '@apis/_utilities/user-locaiton/_types'

export const getUserGeoLocationPendingReducer = (state: GeoState): void => {
  state.fetchStatus = 'loading'
}

export const getUserGeoLocationFulfilledReducer = (
  state: GeoState,
  {
    payload: geoEntry,
  }: {
    payload: GeoEntry
  },
): void => {
  const { geo } = geoEntry
  state.city = geo.city ?? ''
  state.countryName = geo.country?.name ?? ''
  state.countryCode = geo.country?.code ?? ''
  state.fetchStatus = 'succeeded'
}

export const getUserGeoLocationRejectedReducer = (state: GeoState): void => {
  state.fetchStatus = 'failed'
}
