import getChannelByPathname from '../../apis/channels-microservice/get-channel-by-pathname'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getChannelFulfilledReducer,
  getChannelPendingReducer,
  getChannelRejectedReducer,
} from './_reducers'
import type { ChannelState } from './_types'
import axiosRequest from '@apis/axios'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { listChannelsByCategoryThunk } from '@store/channel-slice/_thunks'
import { GET_ALL_CHANNELS_DEFAULT_PARAMS } from '@store/channel-slice/_constants'
import { RootState } from '@root/store'
import { useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import getChannelByPathnameV2 from '@apis/channels-microservice/get-channel-by-pathname-v2'

const initialState: ChannelState = {
  byChannelPathname: {},
  errorMessages: '',
  fetchStatus: 'idle',
}

export const getChannelThunk = createAsyncThunk(
  'channels/getChannel',
  async (channelPathname: string, thunkAPI): Promise<ChannelInterface[]> => {
    const state = thunkAPI.getState() as RootState
    const isCheerIqFlagActive = selectAppFeatureFlagStatusByName('flagCheerdanceIqPage')(state)

    if (isCheerIqFlagActive) {
      return [await getChannelByPathnameV2(channelPathname)]
    }

    return [await getChannelByPathname(channelPathname)]
  },
)
export const listChannelsThunk = createAsyncThunk(
  'channels/getChannel',
  async (): Promise<ChannelInterface[]> =>
    (await axiosRequest('creators', {
      method: 'GET',
      url: 'channels/all',
      params: {
        ...GET_ALL_CHANNELS_DEFAULT_PARAMS,
        private: false,
        homePage: true,
      },
    })) as ChannelInterface[],
)

const creatorsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    addChannelCreator: (state, { payload }: PayloadAction<ChannelInterface>) => {
      state.byChannelPathname[payload.channelUrl] = payload
      state.fetchStatus = 'succeeded'
    },
  },
  extraReducers: {
    [getChannelThunk.pending.toString()]: getChannelPendingReducer,
    [getChannelThunk.fulfilled.toString()]: getChannelFulfilledReducer,
    [getChannelThunk.rejected.toString()]: getChannelRejectedReducer,
    [listChannelsByCategoryThunk.pending.toString()]: getChannelPendingReducer,
    [listChannelsByCategoryThunk.fulfilled.toString()]: getChannelFulfilledReducer,
    [listChannelsByCategoryThunk.rejected.toString()]: getChannelRejectedReducer,
  },
})

export const channelsReducer = creatorsSlice.reducer
export const { addChannelCreator } = creatorsSlice.actions
