import { createAsyncThunk } from '@reduxjs/toolkit'
import { datadogLogs } from '@datadog/browser-logs'
import getInvoices from '@apis/billing-microservice/user-billing/get-invoices'
import { GetInvoiceResponse } from '@shared/interfaces/users-billing/user-billing-invoices.interfaces'

export const getUserBillingInvoicesThunk = createAsyncThunk(
  'user-billing/invoices/fetch',
  async (): Promise<GetInvoiceResponse> => {
    try {
      // Logs the API request to Datadog inside getInvoices function
      const response = await getInvoices()

      return response
    } catch (error) {
      datadogLogs.logger.warn('Error fetching user billing invoices', error)
      throw error
    }
  },
)
