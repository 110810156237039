import { firebaseConfig } from './firebase.config.data'
import type { AppEnvironments } from '../_utilities/environment/api.url.details'
import type { FirebaseConfig } from '.'

export class FirebaseEnvironmentConfig {
  public appEnv: AppEnvironments

  constructor(environment: AppEnvironments) {
    this.appEnv = environment
  }

  getConfig(): FirebaseConfig {
    let config: FirebaseConfig
    if (this.appEnv === 'local') {
      config = firebaseConfig.dev
      return config
    }
    config = firebaseConfig[this.appEnv]
    return config
  }
}

export const FIREBASE_CONFIG = new FirebaseEnvironmentConfig(
  <'local' | 'prod' | 'dev' | 'uat'>process.env.ENVIRONMENT,
).getConfig()
