// Declare global window object with dataLayer
declare global {
  interface Window {
    dataLayer: any[]
  }
}

/**
 * Check if GTM is already loaded on a previous page
 */
export function isGtmInitialised(): boolean {
  return (
    typeof window !== 'undefined' &&
    Array.isArray(window.dataLayer) &&
    window.dataLayer.some((item) => {
      return item['gtm.start'] && item['gtm.uniqueEventId'] === 1
    })
  )
}
