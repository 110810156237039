import {
  HOURS_IN_DAY,
  MINS_IN_HOUR,
  ONE,
  ONE_DAY_IN_SECONDS,
  ONE_MS_SECOND,
  ONE_SECOND_MS,
  SECONDS_IN_MINS,
  ZERO,
} from '../../constants'
import { ROUNDER } from '@shared/constants/root.constants'

export function convertDaysToSeconds(days: number): number {
  return days * ONE_DAY_IN_SECONDS
}

export const convertSecondsToDays = (seconds: number) =>
  Math.round(seconds / SECONDS_IN_MINS / MINS_IN_HOUR / HOURS_IN_DAY)

export function secondsIsGreaterThanDays(seconds: number, days: number): boolean {
  const daysInSeconds = days * ONE_DAY_IN_SECONDS
  return seconds > daysInSeconds
}

export function convertSecondsToMilliSeconds(seconds: number): number {
  return seconds * ONE_SECOND_MS
}

export function convertMilliSecondsToSeconds(milliseconds: number): number {
  return milliseconds * ONE_MS_SECOND
}

export function addSecondsToCurrentDate(seconds: number): Date {
  const today = new Date()
  return new Date(today.getTime() + convertSecondsToMilliSeconds(seconds))
}

export function isGreaterThanZero(num: number): boolean {
  return num > ZERO
}

export function isGreaterOrEqualToZero(num: number): boolean {
  return num >= ZERO
}

export function isGreaterThanOne(num: number): boolean {
  return num > ONE
}

export function isEqualToZero(num: number): boolean {
  return num === ZERO
}

export function isEqualToOne(num: number): boolean {
  return num === ONE
}

export function parseBoolean(params?: string): boolean {
  if (params !== undefined) {
    return params.toLocaleLowerCase() === 'true'
  }
  return false
}

export function getPercentage(num1: number, num2: number): number {
  return (num1 / num2) * ROUNDER
}

export function roundToAtMostTwoDecimalPlaces(
  number: number,
  roundFunc: (num: number) => number = Math.round,
): number {
  return roundFunc((number + Number.EPSILON) * ROUNDER) / ROUNDER
}

export function isEmptyString(value: string): boolean {
  return value !== undefined && value.length === 0
}

export function undefinedOrTransformed<Y, T>(transformed: Y, original?: T): undefined | Y {
  return original !== undefined ? transformed : undefined
}
