import { createSlice } from '@reduxjs/toolkit'
import {
  getAllRailsFulfilledReducer,
  getAllRailsPendingReducer,
  getAllRailsRejectedReducer,
} from './_reducers'
import { getAllRailsThunk } from './_thunks'
import type { RailsState } from './_types'

const initialState: RailsState = {
  byRailId: {},
  allRailIdsByChannelPathname: {},
  errorMessages: '',
  fetchStatus: 'idle',
}

const railsSlice = createSlice({
  name: 'rails',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllRailsThunk.pending.toString()]: getAllRailsPendingReducer,
    [getAllRailsThunk.fulfilled.toString()]: getAllRailsFulfilledReducer,
    [getAllRailsThunk.rejected.toString()]: getAllRailsRejectedReducer,
  },
})
export const railsReducer = railsSlice.reducer
