import { createAsyncThunk } from '@reduxjs/toolkit'
import { SearchPayload } from '@apis/analytics-microservice/_entities/search'
import getSearchResults from '@apis/analytics-microservice/get-search-results'
import { datadogLogs } from '@datadog/browser-logs'
import { toggleLoading, setLoadingStatus } from '@store/search-slice/index'
import { RootState } from '@root/store'
import { SearchPayload as SearchPayloadSliceType } from '@store/search-slice/_types'

export const searchText = createAsyncThunk(
  'search/searchText',
  async (payload: SearchPayload, thunkAPI) => {
    const {
      search: { isLoading },
    } = thunkAPI.getState() as RootState
    try {
      thunkAPI.dispatch(setLoadingStatus(true))
      const search = await getSearchResults(payload)

      const returnSearch: SearchPayloadSliceType = {
        search,
        searchText: payload.searchText,
        channelPathname: payload.channelPathname,
      }
      return returnSearch
    } catch (error) {
      if (error) {
        datadogLogs.logger.error(error.message || `Error retrieving search results`, error)
      }
      return thunkAPI.rejectWithValue(error?.data?.message ?? 'Error')
    } finally {
      thunkAPI.dispatch(setLoadingStatus(false))
    }
  },
)
