import { CardMarketPlaceInterface } from '@shared/interfaces/layout/slider.interface'

export const HEADER_VIDZING_DESCRIPTION = ' Vidzing.tv'

// Me Menu text
export const CREATOR_LINK_TEXT = 'Switch to Creating'

// Default channel images
export const PREVIEW_CHANNEL_IMAGES = [
  'https://res.cloudinary.com/vidzing/image/upload/v1663729270/vidzing/global-assets/channel-preview-images/example-1_xo3ser.png',
  'https://res.cloudinary.com/vidzing/image/upload/v1663729270/vidzing/global-assets/channel-preview-images/example-2_lxaihz.png',
  'https://res.cloudinary.com/vidzing/image/upload/v1663729270/vidzing/global-assets/channel-preview-images/example-3_uz9l5q.png',
]

export const PREVIEW_CHANNEL_AVATAR =
  'https://res.cloudinary.com/vidzing/image/upload/v1663729270/vidzing/global-assets/channel-preview-images/avatar_ly6ayt.png'

// Default error
export const DEFAULT_ERROR = 'Something went wrong, Please try again.'

// FADE IN
export const FADE_IN = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

export const DEFAULT_AUTOPLAY_QUERY = 'autoplay=true'
export const DEFAULT_AUTOPLAY_QUERY_OBJECT = {
  autoplay: true,
}

export const VIDZING_SKY_EARTH_GRADIENT_STRING =
  'linear-gradient(285deg, rgba(83, 233, 178, 1) 10.98%, rgba(99, 203, 248, 1) 100%)'
export const ROUNDER = 100

export const HOME_PAGE_CHANNEL_PATHNAME = 'home-page'
export const CHEER_DANCE_IQ_PATHNAME = 'cheer-dance'

export const CUSTOM_PAGES_PATHNAMES = [HOME_PAGE_CHANNEL_PATHNAME, CHEER_DANCE_IQ_PATHNAME]

//uuid to match format of channelID and enable rails on vidzing's home page:
export const HOME_PAGE_CHANNEL_ID = '145b90b4-6bfe-4fc4-9e6b-17f17e1b35f0' //uuid from Bastian/BE
// uuid to match format of channelID and enable rails on cheerleading-iq's home page:
export const CHEER_DANCE_IQ_CHANNEL_ID = '4ab6a1d2-3343-48c3-83bf-197f7541e60a'
export const CHEER_DANCE_IQ_CATEGORY_SLUG = 'cheer-dance'

// Parse the playback env variable that is a string to an int to be used inside the app.
export const RESUME_PLAYBACK_GRANULARITY_MS_INT = parseInt(
  process.env.RESUME_PLAYBACK_GRANULARITY_MS,
)

// Height of the header in pixels
export const NAV_HEIGHT = 78

// Disclaimer for creator sign up buttons
export const CREATOR_SIGN_UP_DISCLAIMER = 'No credit card required.'

// Main explore
export const EXPLORE_HERO_IMAGE =
  'https://res.cloudinary.com/vidzing/image/upload/v1706823590/hero_2_yor1ef.png'
export const EXPLORE_HERO_ALT_TEXT = 'Vidzing streaming your way'
export const EXPLORE_HERO_HEADLINE = 'Streaming your way'
export const EXPLORE_HERO_DESCRIPTION = 'Streaming the worlds content the way they want.'
export const EXPLORE_META_TITLE = 'Sell live events online | Vidzing'
export const EXPLORE_META_DESCRIPTION =
  'Sell Live Streams, Webinars, Virtual lessons online all in one place. Upload recordings to continue selling the events after.'
export const EXPLORE_META_IMAGE =
  'https://res.cloudinary.com/vidzing/image/upload/v1709680838/vidzing/global-assets/vidzing-share_z8vqi3.png'
export const EXPLORE_META_IMAGE_ALT = 'Vidzing Logo'
export const EXPLORE_META_URL = 'https://www.vidzing.tv/'
export const EXPLORE_META_KEYWORDS =
  'Live stream, webinars, virtual lessons, online coaching, live stream sales, live event sales, webinar sales, live courses, video platform, streaming platform, membership platform, video subscription, monetize content, sell content, sell vidoes, sell live streams, sell webinars, build courses, online course'

// Cheer Dance IQ Page
export const CHEER_DANCE_HERO_IMAGE =
  'https://res.cloudinary.com/vidzing/image/upload/v1706823590/hero_2_yor1ef.png'
export const CHEER_DANCE_HERO_ALT_TEXT = 'Cheer Dance'
export const CHEER_DANCE_HERO_HEADLINE = 'Cheer & Dance TV'
export const CHEER_DANCE_HERO_DESCRIPTION = 'Find all your cheerleading and dance content here!'
export const CHEER_DANCE_META_TITLE = 'Cheer and Dance | Vidzing '
export const CHEER_DANCE_META_DESCRIPTION = CHEER_DANCE_HERO_DESCRIPTION
export const CHEER_DANCE_URL = `https://www.vidzing.tv/${CHEER_DANCE_IQ_PATHNAME}`
export const CHEER_DANCE_IMAGE =
  'https://res.cloudinary.com/vidzing/image/upload/v1709680838/vidzing/global-assets/cheer-iq-share_gkyox2.png'
export const CHEER_DANCE_IMAGE_ALT = 'Cheer & Dance IQ'
export const CHEER_DANCE_META_KEYWORDS =
  'cheer education, cheer courses, cheer workshops, cheer stunts, cheer tumble, cheer routines, cheer coach training, cheer knowledge, cheer reviews, cheerleading education, cheerleading courses, cheerleading workshops, cheerleading stunts, cheerleading tumble, cheerleading routines, cheerleading coach training, cheerleading knowledge'

// Marketplace rail
export const MARKETPLACE_TITLE = 'Channels'
export const MARKETPLACE_RAILS: CardMarketPlaceInterface[] = [
  {
    image: 'https://res.cloudinary.com/vidzing/image/upload/v1710806039/cheer-market_sgw4ua.jpg',
    title: 'Cheer & Dance TV',
    path: '/cheer-dance',
  },
]

// SEO DEFAULT TITLE
export const VIDZING_MARKET_PLACE = 'Vidzing Video Marketplace'

// Favicon
export const VIDZING_FAVICON =
  'https://res.cloudinary.com/vidzing/image/upload/c_fit,w_44/v1712700291/favicon_zrdixo.png'

// Black noise
export const BLACK_NOISE =
  'https://res.cloudinary.com/vidzing/image/upload/v1712797102/vidzing/global-assets/black-noise_pedqbg.png'

// Facebook pixel
export const FACEBOOK_PIXEL_ID = '284046396596605'

// Help Vidzing Link
export const HELP_VIDZING_EMAIL_LINK = 'mailto:support@vidzing.tv'
export const HELP_VIDZING_LINK = `<a href="${HELP_VIDZING_EMAIL_LINK}" target="_blank" rel="noopener">Support team</a>`
