import convertArrayToObject from '../_utilities/convert-array-to-object'
import { prop } from 'ramda'
import type { RailEntity } from '@apis/rails-microservice/_types'
import type { RailsDictionaryEntry, RailsState } from './_types'
import { HOME_PAGE_CHANNEL_PATHNAME } from '@shared/constants/root.constants'

export const getAllRailsPendingReducer = (state: RailsState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getAllRailsFulfilledReducer = (
  state: RailsState,
  {
    payload: { channelPathname = HOME_PAGE_CHANNEL_PATHNAME, rails: newRails },
  }: {
    payload: RailsDictionaryEntry
  },
): void => {
  state.byRailId = {
    ...state.byRailId,
    ...convertArrayToObject<RailEntity>(newRails, 'railId'),
  }

  const newAllId = newRails.map(prop('railId'))
  state.allRailIdsByChannelPathname[channelPathname] = newAllId

  state.fetchStatus = 'succeeded'
  state.errorMessages = ''
}

export const getAllRailsRejectedReducer = (
  state: RailsState,
  { error }: { error: { message: string } },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = error.message
}
