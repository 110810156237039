import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CancelSubscriptionPayload,
  GetActiveSubscriptionsResponse,
} from '@shared/interfaces/users-billing/user-billing-subsciptions.interfaces'
import getActiveSubscriptions from '@apis/billing-microservice/user-billing/get-active-subscriptions'
import { datadogLogs } from '@datadog/browser-logs'
import cancelSubscription from '@apis/billing-microservice/user-billing/cancel-subscription'

export const getUserBillingDetailsThunk = createAsyncThunk(
  'user-billing/subscription/fetch',
  async (): Promise<GetActiveSubscriptionsResponse> => {
    try {
      // Logs the API request to Datadog inside getActiveSubscriptions function
      const response = await getActiveSubscriptions()

      return response
    } catch (error) {
      datadogLogs.logger.warn('Error fetching user subscription status', error)
      throw error
    }
  },
)

export const cancelSubscriptionThunk = createAsyncThunk(
  'user-billing/subscription/cancel',
  async ({
    stripeSubscriptionId,
    reason,
  }: CancelSubscriptionPayload): Promise<GetActiveSubscriptionsResponse> => {
    try {
      // Logs the API request to Datadog inside cancelSubscription function
      const response = await cancelSubscription({ reason, stripeSubscriptionId })

      return response
    } catch (error) {
      datadogLogs.logger.error('Error cancelling user subscription from user billing portal', {
        error,
        stripeSubscriptionId,
        reason,
      })
      throw error
    }
  },
)
