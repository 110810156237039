import { TrackingEventsEnum } from '@shared/enums/tracking-events.enum'
import TagManager from 'react-gtm-module'
import { isGtmInitialised } from '@utils/is-gtm-loaded'
import isFacebookPixelLoaded from '@utils/is-facebook-pxiel-loaded'

/**
 * Track events
 * Currently we only support GTM and Facebook Pixel
 * ADD to here when we want to support more.
 * @param event
 * @param data
 */
export function trackEvent(event: TrackingEventsEnum, data: any) {
  if (isGtmInitialised()) {
    gtmEvent(event, data)
  }

  if (isFacebookPixelLoaded()) {
    facebookPixelEvent(event, data)
  }
}

function facebookPixelEvent(event: string, data?: any) {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track(event, data)
    })
}

function gtmEvent(event: string, data?: any) {
  TagManager.dataLayer({
    dataLayer: {
      event,
      data,
    },
  })
}
