import { HttpRequestMethod } from '@apis/_types'
import { NETWORK_ERROR, UNSUPPORTED_CODE_ERROR } from '@apis/_constants'
import type { GeoEntry } from '@apis/_utilities/user-locaiton/_types'

export default async function getUserGeoLocationApiCall(): Promise<GeoEntry> {
  const url = '/edge_functions/geolocation'
  const headers = {
    'Content-Type': 'application/json',
  }

  try {
    const response = await fetch(url, {
      method: HttpRequestMethod.GET,
      headers,
    })
    switch (response.status) {
      case 200: {
        return await response.json()
      }
      case 404: {
        const error: string = await response.json()
        return Promise.reject(error)
      }
      default: {
        return Promise.reject(UNSUPPORTED_CODE_ERROR)
      }
    }
  } catch (networkError) {
    return Promise.reject(NETWORK_ERROR)
  }
}
