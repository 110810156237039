import axiosRequest from '@apis/axios'
import { OrganisationInterface } from '@shared/interfaces/creators/organisation.interface'

export async function getOrganisationByOrgId(
  organisationId: string,
): Promise<OrganisationInterface> {
  return (await axiosRequest('creators', {
    method: 'GET',
    url: `organisations/${organisationId}`,
  })) as OrganisationInterface
}
