import { PayloadAction } from '@reduxjs/toolkit'
import { ClearDataProps, SearchPayload, SearchState } from '@store/search-slice/_types'

export const changeSearchText = (state: SearchState, { payload }: PayloadAction<string>) => {
  state.searchText = payload
}

export const changeLoadingStatus = (state: SearchState, { payload }: PayloadAction<boolean>) => {
  state.isLoading = payload
}

export const changeInputVisibility = (state: SearchState) => {
  state.inputVisibility = !state.inputVisibility
}

export const setSearchData = (
  state: SearchState,
  { payload: { searchText, channelPathname, search } }: PayloadAction<SearchPayload>,
) => {
  if (channelPathname && searchText) {
    const searchWordEncoded = encodeURI(searchText)
    state.resultsByChannelPathnameAndSearchText[channelPathname] = {
      ...state.resultsByChannelPathnameAndSearchText[channelPathname],
      [searchWordEncoded]: search,
    }
  }
}
export const clearData = (
  state: SearchState,
  { payload: { searchText, channelPathname } }: PayloadAction<ClearDataProps>,
) => {
  const searchWordEncoded = encodeURI(searchText)
  if (state.resultsByChannelPathnameAndSearchText[channelPathname]?.[searchWordEncoded])
    state.resultsByChannelPathnameAndSearchText[channelPathname] = {
      [searchWordEncoded]: [],
    }
}
export const toggleIsLoading = (state: SearchState) => {
  state.isLoading = !state.isLoading
}
