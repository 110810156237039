import store from '..'
import { createAsyncThunk } from '@reduxjs/toolkit'
import getContentById from '../../apis/contents-microservice/get-content-by-id'
import { selectUserCountryCode } from '../geo-slice/_selectors'
import { selectUserId } from '@store/user-slice/_selectors'
import getUserGeoLocationApiCall from '@apis/_utilities/user-locaiton'
import { ResumePlayback } from '@apis/analytics-microservice/_entities/resume-playback'
import getResumePlayback from '@apis/analytics-microservice/get-last-known-time'
import { ContentEntity } from '@apis/contents-microservice/_entities/_types'
import { ErrorListNames } from '@apis/_utilities/api-errors/error-list-names'
import { datadogLogs } from '@datadog/browser-logs'
import { ContentResourceInterface } from '@shared/interfaces/assets/content-resource.interface'
import axiosRequest from '@apis/axios'
import { GetContentByIdInterface } from '@shared/interfaces/get-content-by-id.interface'
import { FeaturedContentReducerInterface } from '@store/contents-slice/_types'
import getRecommendedContentByIdApi from '@apis/contents-microservice/get-recommended-content-by-id'
import getCountryCode from '@store/_utilities/get-country-code'

export const getContentByIdThunk = createAsyncThunk(
  'contents/listContents',
  async ({
    contentId,
    channelId,
    withBundleContents,
  }: GetContentByIdInterface): Promise<ContentEntity> => {
    const countryCode = await getCountryCode()
    return await getContentById({ contentId, channelId, countryCode, withBundleContents })
  },
)

export const getResumePlaybackByIdThunk = createAsyncThunk(
  'contents/resumePlayback',
  async (contentId: string): Promise<ResumePlayback> => {
    try {
      return await getResumePlayback(contentId)
    } catch (error) {
      // If error is NotFoundException, it might simply be that this is the first time a user watches this content, so log it as an info only.
      if (error.data?.name === ErrorListNames.NotFoundException || error.status === 404) {
        datadogLogs.logger.info(
          error.message ||
            error.data?.message ||
            `No last known time found for the given user and content`,
          error,
        )

        const state = store.getState()
        const userId = selectUserId(state)

        // If the user has not watched it previously return a dummy object with lastKnownTime and updatedAt set to 0
        // This will prevent the FE from fetching the resume playback again for this content
        return {
          contentId,
          userId,
          lastKnownTime: 0,
          updatedAt: 0,
        }
      } else {
        // If error is different, then let's log it as an error on datadog as this is not an expected behaviour
        datadogLogs.logger.error(
          error.message || `Error retrieving user's last known time watching a content`,
          error,
        )
      }
    }
  },
)

/** Todo: Remove loop once an endpoint is created for getting the resume playback bundled data **/
export const getResumePlaybackByIdsThunk = createAsyncThunk(
  'contents/resumePlaybackByIds',
  async (contentIds: string[]): Promise<ResumePlayback[]> => {
    const playbacks = contentIds.map((contentId) => getResumePlayback(contentId))
    return await Promise.all(
      playbacks.map((p) => {
        const contentId = p.then((playback) => playback.contentId)
        return p.catch((error) => {
          // If error is NotFoundException, it might simply be that this is the first time a user watches this content, so log it as an info only.
          if (error.data?.name === ErrorListNames.NotFoundException || error.status === 404) {
            datadogLogs.logger.info(
              error.message ||
                error.data?.message ||
                `No last known time found for the given user and content`,
              error,
            )

            const state = store.getState()
            const userId = selectUserId(state)

            // If the user has not watched it previously return a dummy object with lastKnownTime and updatedAt set to 0
            // This will prevent the FE from fetching the resume playback again for this content
            return {
              contentId,
              userId,
              lastKnownTime: 0,
              updatedAt: 0,
            }
          } else {
            // If error is different, then let's log it as an error on datadog as this is not an expected behaviour
            datadogLogs.logger.error(
              error.message || `Error retrieving user's last known time watching a content`,
              error,
            )
          }
          return error
        })
      }),
    )
  },
)

export const getContentResourcesByIdThunk = createAsyncThunk(
  'contents/resources',
  async (contentId: string): Promise<ContentResourceInterface[]> => {
    try {
      return (await axiosRequest('assets', {
        method: 'GET',
        url: `resources/content/${contentId}`,
      })) as ContentResourceInterface[]
    } catch (error) {
      // If error is different, then let's log it as an error on datadog as this is not an expected behaviour
      datadogLogs.logger.error(error.message || `Error retrieving content resources`, error)
    }
  },
)

export const getFeaturedContentByChannelIdThunk = createAsyncThunk(
  'contents/featuredContent',
  async (channelId: string): Promise<FeaturedContentReducerInterface> => {
    // Get country code and add to request as it's now used in state machine
    // to check state of geo-blocking
    const state = store.getState()
    let countryCode = selectUserCountryCode(state)
    const userId = selectUserId(state)
    // If userId is defined and country code is not call the geo endpoint and get it.
    // The geo request may take longer to be fetched by the app so call here if needed and await it
    // If local don't try fetch geo because we have no netlify edge and this blocks code execution
    if (userId !== '' && countryCode === '' && process.env.FRONT_END_ENV !== 'local') {
      const geo = await getUserGeoLocationApiCall()
      countryCode = geo.geo.country.code
    }

    return {
      featuredContents: (await axiosRequest('contents', {
        method: 'GET',
        url: `external-link/scheduled/channel/${channelId}`,
        params: { country: countryCode },
      })) as ContentEntity[],
      channelId,
    }
  },
)

export const getRecommendedContentById = createAsyncThunk(
  'contents/recommended',
  async (contentId: string, thunkAPI) => {
    try {
      return {
        contentId,
        recommended: await getRecommendedContentByIdApi(contentId),
      }
    } catch (error) {
      // If error is different, then let's log it as an error on datadog as this is not an expected behaviour
      datadogLogs.logger.error(error?.message || `Error retrieving recommended content`, error)
      return thunkAPI.rejectWithValue(error?.message ?? 'Error')
    }
  },
)
