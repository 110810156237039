import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import { FeatureFlagResponseMap } from '@shared/interfaces/feature-flags.interface'

// TODO: this needs love. Please see: https://github.com/orgs/vidzing/projects/1?pane=issue&itemId=51742809 for information on how
// the feature flag endpoints are working.
// Note it's important to cache the results from the backend to avoid making too many requests.
// Also note I want the frontend to use feature-flag/all endpoint which returns ALL feature flags.
// This way, we can cache the results and store them on the local storage and use them wherever we need them.
// The backend is also using Redis DB for caching results. so if we need to clear/invalidate a cached feature flag, we need to do so from the backend.

export const DEFAULT_CACHE_TIME = 60000 // 60000 milliseconds = 1 Minute

// Get the feature flag cache time from the environment variables. If not set, use the default cache time
export const CACHE_TIME =
  process.env.FEATURE_FLAG_CACHE_TIME_MS !== undefined
    ? parseInt(process.env.FEATURE_FLAG_CACHE_TIME_MS)
    : DEFAULT_CACHE_TIME

export default async function UseFeatureFlags(
  cacheTime = CACHE_TIME,
): Promise<FeatureFlagResponseMap> {
  try {
    // Get cached feature flags from local storage
    const cachedFeatureFlags = getCachedFeatureFlags()

    // If the cache is still valid, return the cached feature flags
    if (isCacheValid(cachedFeatureFlags, cacheTime)) {
      return cachedFeatureFlags.data
    }

    // If the cache is invalid, fetch the feature flags from the backend
    const response = await fetchFeatureFlags()
    // Update the cache from the local storage with the new feature flags
    updateCache(response)

    return response
  } catch (error) {
    datadogLogs.logger.error('[UseFeatureFlags] - Error fetching feature flags', error)
    return error
  }
}

/**
 * Get cached feature flags from local storage
 */
function getCachedFeatureFlags(): { data: FeatureFlagResponseMap; timestamp: number } | null {
  const cachedFlags = localStorage.getItem('featureFlags')
  if (!cachedFlags) {
    return null
  }
  return JSON.parse(cachedFlags)
}

/**
 * Check if the cached feature flags are still valid. It's invalid if the cache time has expired. See CACHE_TIME
 * @param cachedFeatureFlags
 * @param cacheTime
 */
function isCacheValid(
  cachedFeatureFlags: { data: FeatureFlagResponseMap; timestamp: number } | null,
  cacheTime: number,
): boolean {
  return cachedFeatureFlags && Date.now() - cachedFeatureFlags.timestamp < cacheTime
}

/**
 * Fetch feature flags from the backend
 */
function fetchFeatureFlags(): Promise<FeatureFlagResponseMap> {
  return axiosRequest('analytics', {
    method: 'GET',
    url: `feature-flag/all`,
  }) as Promise<FeatureFlagResponseMap>
}

/**
 * Update the cache from the local storage with the new feature flags
 * @param response
 */
function updateCache(response: FeatureFlagResponseMap): void {
  const updatedCache = {
    data: response,
    timestamp: Date.now(),
  }
  localStorage.setItem('featureFlags', JSON.stringify(updatedCache))
}
