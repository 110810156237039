import { useRouter } from 'next/router'
import { useEffect } from 'react'

export default function useMaintenanceMode() {
  const router = useRouter()

  useEffect(() => {
    if (process.env.MAINTENANCE_MODE === 'true') {
      if (typeof window !== 'undefined') {
        window.location.href = '/maintenance.html'
      }
    }
  }, [router])
}
