import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import { SubscriptionDefaultPaymentMethodResponse } from '@shared/interfaces/users-billing/user-billing-payment-methods.interfaces'

export default async function patchSubscriptionDefaultPaymentMethod(request: {
  stripeSubscriptionId: string
  paymentMethodId: string
}): Promise<SubscriptionDefaultPaymentMethodResponse> {
  datadogLogs.logger.info(
    'Making an update to a subscriptions default payment method API request',
    { request },
  )

  // Sets the payment method as the default payment method
  const response = (await axiosRequest(
    'billing',
    {
      method: 'PATCH',
      url: `user-subscriptions/subscription/${request.stripeSubscriptionId}/default-payment-method/${request.paymentMethodId}`,
    },
    true,
  )) as SubscriptionDefaultPaymentMethodResponse

  datadogLogs.logger.info(
    'Successful update to a subscriptions default payment method API request',
    {
      response,
      request,
    },
  )

  return response
}
