import TagManager from 'react-gtm-module'

export default function gtmEvent(pagePath: string, userId: string): void {
  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      pagePath: pagePath,
      userId: userId,
    },
  })
}
