import { PayloadAction } from '@reduxjs/toolkit'
import { BundleState } from '@store/bundle-slice/_types'
import {
  BundleModuleInterface,
  ContentEntity,
  ModuleContentsInterface,
  ModuleContentsResponseInterface,
} from '@apis/contents-microservice/_entities/_types'
import { FetchStatusThunkPayloadInterface } from '@shared/interfaces/redux/fetch-status-thunk-payload.interface'
import { GET_BUNDLE_CONTENT_BY_CONTENT_ID_THUNK_TYPE } from '@store/bundle-slice/_constants'
import { FetchStatusThunkEnum } from '@shared/enums/redux/fetch-status-thunk.enum'

/**
 * Set content bundle by content id in bundle state
 * @param state
 * @param ContentEntity
 */

export const setContentBundleByContentId = (
  state: BundleState,
  { payload }: PayloadAction<ContentEntity>,
) => {
  if (payload) {
    state.byContentId = {
      ...state.byContentId,
      [payload.contentId]: payload,
    }
    state.byBundleId = {
      ...state.byBundleId,
      [payload?.bundle?.bundleId]: payload,
    }
    state.contentFetchStatus = 'idle'
  }
}

/**
 * Set bundle content fetch status for rejected and pending
 * @param state
 * @param payload
 */
export const setBundleContentFetchStatus = (
  state: BundleState,
  payload: FetchStatusThunkPayloadInterface,
) => {
  if (
    payload.type ===
    `${GET_BUNDLE_CONTENT_BY_CONTENT_ID_THUNK_TYPE}/${FetchStatusThunkEnum.REJECTED}`
  )
    state.contentFetchStatus = 'failed'
  else if (
    payload.type ===
    `${GET_BUNDLE_CONTENT_BY_CONTENT_ID_THUNK_TYPE}/${FetchStatusThunkEnum.PENDING}`
  )
    state.contentFetchStatus = 'loading'
}

/**
 * Module content by module - Pending
 * @param state
 * @param payload
 */
export const setModuleContentsByModuleIdPending = (
  state: BundleState,
  payload: BundleModuleInterface,
) => {
  state.contentFetchStatus = 'loading'
}

/**
 * Module content by module - Pending
 * @param state
 * @param payload
 */
export const setModuleContentsByModuleIdSuccess = (
  state: BundleState,
  { payload: payload }: { payload: ModuleContentsResponseInterface },
) => {
  if (payload) {
    const currentModules = state.bundleModules[payload.bundleId] ?? []
    const filteredStatus = currentModules.filter((module) => module.moduleId !== payload.moduleId)
    const updatedState: ModuleContentsInterface[] = [
      ...filteredStatus,
      { moduleId: payload.moduleId, contents: payload.contents },
    ]

    state.bundleModules = {
      ...state.bundleModules,
      [payload.bundleId]: updatedState,
    }

    state.contentFetchStatus = 'succeeded'
  }
}

/**
 * Module content by module - Pending
 * @param state
 * @param payload
 */
export const setModuleContentsByModuleIdRejected = (
  state: BundleState,
  { payload: payload }: { payload: ModuleContentsResponseInterface },
) => {
  if (payload) {
    state.bundleModules = {
      ...state.bundleModules,
      [payload.bundleId]: [],
    }
  }
  state.contentFetchStatus = 'failed'
}
