import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import { GetPaymentMethodResponse } from '@shared/interfaces/users-billing/user-billing-payment-methods.interfaces'

export default async function deletePaymentMethod(request: {
  paymentMethodId: string
}): Promise<GetPaymentMethodResponse> {
  datadogLogs.logger.info('Making delete payment method API request', { request })

  // Deletes a payment method if the card is not the last card on the profile
  const response = (await axiosRequest(
    'billing',
    {
      method: 'DELETE',
      url: `user-subscriptions/payment-methods/${request.paymentMethodId}`,
    },
    true,
  )) as GetPaymentMethodResponse

  datadogLogs.logger.info('Successful delete payment method API request', {
    response,
    request,
  })

  return response
}
