import { WebSocketPing } from '../..'
import { webSocketPingTimeMs } from '../../constants'

export function createPingTimer(webSocket: WebSocket | undefined): NodeJS.Timer | undefined {
  if (webSocket !== undefined) {
    return setInterval(() => {
      WebSocketPing(webSocket)
    }, webSocketPingTimeMs)
  }
  return undefined
}
