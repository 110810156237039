import { createAsyncThunk } from '@reduxjs/toolkit'
import getUserGeoLocationApiCall from '@apis/_utilities/user-locaiton'
import type { GeoEntry } from '@apis/_utilities/user-locaiton/_types'

const getUserGeoLocationThunkCallback = async (): Promise<GeoEntry> => {
  try {
    return await getUserGeoLocationApiCall()
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getUserGeoLocationThunk = createAsyncThunk(
  'geo/getUserGeoLocation',
  getUserGeoLocationThunkCallback,
)
