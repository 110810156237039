import { PaymentMethodState } from '@store/user-billing-slice/payment-methods/_interfaces'

export const initialUserBillingPaymentMethodState: PaymentMethodState = {
  data: {
    paymentMethods: [],
    stripeCustomer: {
      userId: '',
      defaultPaymentMethodId: '',
    },
    clientSecret: '',
  },
  errorMessages: '',
  fetchStatus: 'idle',
}
