import { createAsyncThunk } from '@reduxjs/toolkit'
import checkPassIdStatusByPassIdAndChannelId from '@apis/billing-microservice/check-pass-id-status'
import { GetChannelPassStatusThunkParams } from '@store/pass-slice/_types'

export const getChannelPassStatusThunk = createAsyncThunk(
  'pass/status',
  async ({ passIds, channelId, channelPathname }: GetChannelPassStatusThunkParams, thunkAPI) => {
    try {
      const passStatuses = passIds.map(async (passId) => {
        const status = await checkPassIdStatusByPassIdAndChannelId({ passId, channelId })
        return {
          channelPathname,
          passId,
          ...status,
        }
      })
      return Promise.all(passStatuses)
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.data?.error?.response?.message ?? 'Error')
    }
  },
)
