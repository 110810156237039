import { createAsyncThunk } from '@reduxjs/toolkit'
import { datadogLogs } from '@datadog/browser-logs'
import {
  GetActivePurchasesResponse,
  PurchaseDetails,
} from '@shared/interfaces/users-billing/user-billing-purchases.interfaces'
import getPurchases from '@apis/billing-microservice/user-billing/get-purchases'

export const getUserBillingPurchasesThunk = createAsyncThunk(
  'user-billing/purchases/fetch',
  async (): Promise<PurchaseDetails[]> => {
    try {
      // Logs the API request to Datadog inside getPurchases function
      const response = await getPurchases()

      return response
    } catch (error) {
      datadogLogs.logger.warn('Error fetching user billing purchases', error)
      throw error
    }
  },
)
