// Mux Thumbnail
export const MUX_THUMBNAIL_ORIGIN = 'https://image.mux.com'
export const MUX_THUMBNAIL_PREFIX = `${MUX_THUMBNAIL_ORIGIN}/`
export const MUX_THUMBNAIL_SUFFIX = '/thumbnail.png'
export const MUX_THUMBNAIL_DEFAULT_WIDTH_HEIGHT = '?width=80&height=45'

// Mux Player
export const MUX_PLAYER_OFFSET_IN_SECONDS = 15
export const MUX_PLAYBACK_RATES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

// Docs to list of all available options: https://github.com/muxinc/elements/blob/main/packages/mux-player/REFERENCE.md#:~:text=This%20is%20the%20list%20of%20CSS%20variables%20for%20showing/hiding%20specific%20controls.
export const MUX_PLAYER_CSS_VARIABLES: Record<string, unknown> = {
  // Hides the live button, as we have a custom one.
  '--live-button': 'none',
}

// The allowed time in seconds where we still consider a live stream as live.
export const MUX_PLAYER_LIVE_OFFSET_ALLOWANCE_IN_SECONDS = 5
