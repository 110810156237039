import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import {
  GetActivePurchasesResponse,
  PurchaseDetails,
} from '@shared/interfaces/users-billing/user-billing-purchases.interfaces'

export default async function getPurchases(): Promise<PurchaseDetails[]> {
  datadogLogs.logger.info('Making get purchases API request')

  const response: PurchaseDetails[] = (await axiosRequest(
    'contents',
    {
      method: 'GET',
      url: 'myvidz/all',
    },
    true,
  )) as PurchaseDetails[]

  datadogLogs.logger.info('Successfully fetched payment methods API request', { response })

  return response
}
