import { GTM_ID } from './constants'

export const gtmConfig = {
  dev: {
    gtmId: GTM_ID,
    auth: 'MMzcSHTE_HPwRbU0-rrlng',
    preview: 'env-26',
  },
  uat: {
    gtmId: GTM_ID,
    auth: 'o6RZBh2G9vtlvvr6m74a6A',
    preview: 'env-25',
  },
  prod: {
    gtmId: GTM_ID,
    auth: 'S6YnsjEqI0mCXZlWlMh56A',
    preview: 'env-1',
  },
}
