import { CreatorInterface } from '@shared/interfaces/creator.interface'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'

export const getCreatorChannelPending = (state: CreatorInterface): void => {
  state.fetchStatus = 'loading'
}

export const getCreatorChannelFulfilled = (
  state: CreatorInterface,
  { payload }: { payload: ChannelInterface },
): void => {
  state.fetchStatus = 'succeeded'
  state.channel = { ...payload }
}

export const getCreatorChannelRejected = (state: CreatorInterface): void => {
  state.fetchStatus = 'failed'
}
