import { AvailabilityDurationInterface } from '@shared/interfaces/price-data.interface'

export const HAS_MORE_PRICES_TAG = 'From'

export const AVAILABILITY_DURATION_OPTIONS: AvailabilityDurationInterface[] = [
  {
    label: '48 hours',
    value: '2',
  },
  {
    label: '7 days',
    value: '7',
  },
  {
    label: '14 days',
    value: '14',
  },
  {
    label: '30 days',
    value: '30',
  },
]
