import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import { GetActiveSubscriptionsResponse } from '@shared/interfaces/users-billing/user-billing-subsciptions.interfaces'

export default async function cancelSubscription(request: {
  reason: string
  stripeSubscriptionId: string
}): Promise<GetActiveSubscriptionsResponse> {
  datadogLogs.logger.info('Making cancel subscriptions API request', { request })

  // Cancels a subscription and returns the updated list of subscriptions
  const response = (await axiosRequest(
    'billing',
    {
      method: 'PATCH',
      url: `user-subscriptions/cancel-subscription/${request.stripeSubscriptionId}`,
    },
    true,
  )) as GetActiveSubscriptionsResponse

  datadogLogs.logger.info('Successful cancelled subscription API request', {
    response,
    request,
  })

  return response
}
