import { createAsyncThunk } from '@reduxjs/toolkit'
import { CouponResponse, ValidateCouponPayload } from '@store/coupons-slice/interfaces'
import validateCoupon from '@apis/billing-microservice/coupons'

export const validateCouponThunk = createAsyncThunk(
  'coupons/validate',
  async (payload: ValidateCouponPayload): Promise<CouponResponse> => {
    return await validateCoupon(payload)
  },
)

export const resetCouponThunk = createAsyncThunk('coupons/reset', async (): Promise<void> => {
  return
})
