import { PassState } from '@store/pass-slice/_types'
import { createSlice } from '@reduxjs/toolkit'
import { getChannelPassStatusThunk } from '@store/pass-slice/_thunks'
import {
  getPassStatusFulfilledReducer,
  getPassStatusPendingReducer,
  getPassStatusRejectedReducer,
} from '@store/pass-slice/_reducers'

const initialState: PassState = {
  byChannelPathname: {},
  byPassStatusById: {},
  errorMessages: '',
  fetchStatus: 'idle',
}

const passSlice = createSlice({
  name: 'passes',
  initialState,
  reducers: {
    resetPassBills: (state) => initialState,
  },
  extraReducers: {
    [getChannelPassStatusThunk.pending.toString()]: getPassStatusPendingReducer,
    [getChannelPassStatusThunk.fulfilled.toString()]: getPassStatusFulfilledReducer,
    [getChannelPassStatusThunk.rejected.toString()]: getPassStatusRejectedReducer,
  },
})

export const { resetPassBills } = passSlice.actions
export const passesReducer = passSlice.reducer
