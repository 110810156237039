import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomChannelInterface } from '@shared/interfaces/custom-channel.interface'

const initialState: CustomChannelInterface = {
  backgroundColour: '',
  fontFormColour: '',
  inputBackgroundColour: '',
  logo: '',
  logoWhite: '',
  mainColour: '',
  name: '',
  path: '',
  rightImage: '',
  secondaryColour: '',
  rightColumnTitle: '',
  rightColumnDesc: '',
  rightColumnColourText: '',
  playerBackgroundColour: '',
  customPlayerContentId: '',
  signUpDisclaimer: '',
  signUpButtonText: '',
}

const customChannelSlice = createSlice({
  name: 'customChannel',
  initialState,
  reducers: {
    setCustomChannel: (customChannelState, { payload }: PayloadAction<CustomChannelInterface>) => {
      customChannelState.name = payload.name
      customChannelState.path = payload.path
      customChannelState.backgroundColour = payload.backgroundColour
      customChannelState.inputBackgroundColour = payload.inputBackgroundColour
      customChannelState.mainColour = payload.mainColour
      customChannelState.secondaryColour = payload.secondaryColour
      customChannelState.fontFormColour = payload.fontFormColour
      customChannelState.logo = payload.logo
      customChannelState.logoWhite = payload.logoWhite
      customChannelState.rightImage = payload.rightImage
      customChannelState.rightColumnTitle = payload.rightColumnTitle
      customChannelState.rightColumnDesc = payload.rightColumnDesc
      customChannelState.rightColumnColourText = payload.rightColumnColourText
      customChannelState.playerBackgroundColour = payload.playerBackgroundColour
      customChannelState.customPlayerContentId = payload.customPlayerContentId
      customChannelState.signUpDisclaimer = payload.signUpDisclaimer
      customChannelState.signUpButtonText = payload.signUpButtonText
      payload.themeColour && (customChannelState.themeColour = payload.themeColour)
      payload.themeContrastColour &&
        (customChannelState.themeContrastColour = payload.themeContrastColour)
      payload.greyThemeColour && (customChannelState.greyThemeColour = payload.greyThemeColour)
      payload.backgroundImageUrl &&
        (customChannelState.backgroundImageUrl = payload.backgroundImageUrl)
      payload.promoVideoUrl && (customChannelState.promoVideoUrl = payload.promoVideoUrl)
    },
  },
})

export const customChannelReducer = customChannelSlice.reducer
export const { setCustomChannel } = customChannelSlice.actions
