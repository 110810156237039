import type { RootState } from '@store/index'
import type { FetchStatus } from '@store/_types'

export const selectStatus = ({ geo }: RootState): FetchStatus => {
  return geo.fetchStatus
}

export const selectUserCountryName = ({ geo }: RootState): string => {
  return geo.countryName
}

export const selectUserCountryCode = ({ geo }: RootState): string => {
  return geo.countryCode
}

export const selectUserCity = ({ geo }: RootState): string => {
  return geo.city
}
