import { RootState } from '@root/store'

/**
 * Select mux asset by assetId
 * @param assetId
 */
export const getMuxAssetById =
  (assetId: string) =>
  ({ assets }: RootState) => {
    return assets?.muxAssets?.find((muxAsset) => muxAsset.muxAssetId === assetId)
  }
