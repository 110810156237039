import type { RootState } from '..'
import type { FetchStatus } from '../_types'
import { SubscriptionInterface } from '@shared/interfaces/creators/subscription/subscription.interface'
import { isEmptyArray } from '@root/utils/arrays'
import { SubscriptionCardInterface } from '@shared/interfaces/subscription-card.interface'
import { buildPriceStringForSubscriptions } from '@root/utils/build-price-strings'
import { generateTransformationUrl } from '@apis/cloudinary'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { ChannelThemeInterface } from '@shared/interfaces/creators/channel-theme.interface'
import { createSelector } from 'reselect'
import convertArrayToObject from '@store/_utilities/convert-array-to-object'
import { PassInterface } from '@shared/interfaces/creators/channel-pass/pass.interface'
import { PassCardInterface } from '@shared/interfaces/pass-card.interface'
import { toDisplayCurrency } from '@library/_utilities/to-display-currency'
import { toDollar } from '@library/_utilities/to-dollar'
import { ContentTypeLabelInterface } from '@shared/interfaces/creators/content-type-label.interface'
import { EventTrackingDataInterface } from '@shared/interfaces/event-tracking-data.interface'
import { VidzingProductTypesEnum } from '@shared/enums/vidzing-product-types.enum'
import { ChannelState } from '@store/channel-slice/_types'
import { buildThumbnailImageToUse } from '@utils/image'
import { isNewCropPassSubImage } from '@utils/cloudinary'

export const selectAllChannels = ({ channels }: RootState): string[] =>
  Object.keys(channels.byChannelPathname)

//TODO: This selector is not memoize the data.
export const selectChannelPropByChannelPathname =
  (channelPathname: string) =>
  <PropKey extends keyof ChannelInterface>(propKey: PropKey) =>
  ({ channels }: RootState): ChannelInterface[PropKey] | undefined => {
    const channel = channels.byChannelPathname?.[channelPathname]

    // If property value is null, return undefined
    return channel?.[propKey] ?? undefined
  }

// New selector
export const selectChannelData = (channelPath: string) => {
  return createSelector(
    (state: RootState) => state.channels,
    (channelState: ChannelState) => {
      return channelState.byChannelPathname[channelPath] ?? undefined
    },
  )
}

export const selectSubscriptionPropByChannelPathname =
  (channelPathname: string) =>
  (index: number) =>
  <PropKey extends keyof SubscriptionInterface>(propKey: PropKey) =>
  ({ channels }: RootState): SubscriptionInterface[PropKey] | undefined => {
    const channel = channels.byChannelPathname?.[channelPathname]

    // If property value is null, return undefined
    return channel?.channelSubscription?.[index]?.subscription?.[propKey] ?? undefined
  }

export const selectIsSubscribableByChannelPathname =
  (channelPathname: string) =>
  ({ channels }: RootState): boolean => {
    const channel = channels.byChannelPathname?.[channelPathname]

    return channel?.channelSubscription?.length > 0
  }

export const selectStatus = ({ channels }: RootState): FetchStatus => {
  return channels.fetchStatus
}

export const selectErrorMessages = ({ channels }: RootState): string => {
  return channels.errorMessages
}

// TODO: Memorized
export const selectChannelPathnameByChannelId =
  (channelId: string) =>
  ({ channels }: RootState): string | undefined => {
    const pathnameDictionary = channels.byChannelPathname
    const pathnames: string[] = Object.keys(pathnameDictionary)
    const channelPathname = (name: string): boolean =>
      pathnameDictionary[name].channelId === channelId

    return pathnames.find(channelPathname)
  }

/**
 * Select subscription data by subscription Id and channel pathname
 * @param channelPathname
 * @param subscriptionId
 */
export const selectSubscriptionDataBySubscriptionId =
  (channelPathname: string, subscriptionId: string) =>
  ({ channels }: RootState): SubscriptionCardInterface => {
    const channel = channels.byChannelPathname?.[channelPathname]
    const channelSubscription = channel?.channelSubscription?.find(
      (channelSubscription) => channelSubscription.subscription?.subscriptionId === subscriptionId,
    )

    if (channelSubscription) {
      const { subscription } = channelSubscription
      let thumbnailToUse = ''
      const isNewCropVersion = isNewCropPassSubImage(subscription.thumbnail)
      if (isNewCropVersion)
        thumbnailToUse = buildThumbnailImageToUse(subscription.thumbnail, 310, '1:1')
      else
        thumbnailToUse = generateTransformationUrl(subscription.thumbnail, {
          width: 310,
          aspectRatio: '1:1',
          quality: 'auto',
        })
      return {
        imageUrl: thumbnailToUse,
        title: subscription.subscriptionName,
        description: subscription.subscriptionDescription,
        price: buildPriceStringForSubscriptions(subscription, 'min'),
        interval: subscription.subscriptionRecurringInterval,
      }
    }
  }

/**
 * Select subscription Ids by channel pathname
 * Return all subscriptionIds for a channel or and empty array if there are no subscriptions
 */
export const selectChannelSubscriptionIdsByChannelPathname = createSelector(
  [({ channels }: RootState) => channels, (state, channelPathname: string) => channelPathname],
  (channels, channelPathname): string[] => {
    const channel = channels.byChannelPathname?.[channelPathname]

    if (channel && !isEmptyArray(channel.channelSubscription)) {
      const channelSubscriptions = [...channel.channelSubscription]
      return channelSubscriptions
        .sort((a, b) => a.subscription?.price - b.subscription?.price)
        .map((channelSubscription) => channelSubscription.subscription?.subscriptionId)
    }
    return []
  },
)

/**
 * Select subscription Ids by channel pathname
 * Return all subscriptionIds for a channel or and empty array if there are no subscriptions
 * AND Filter out unlisted subscriptions
 */
export const selectFilteredOutUnlistedSubscriptionIdsByChannelPathname = createSelector(
  [({ channels }: RootState) => channels, (state, channelPathname: string) => channelPathname],
  (channels, channelPathname): string[] => {
    const channel = channels.byChannelPathname?.[channelPathname]

    if (channel?.channelSubscription && !isEmptyArray(channel?.channelSubscription)) {
      return channel.channelSubscription
        .filter((subscription) => subscription?.subscription?.unlisted == false)
        .sort((a, b) => (a.subscription?.price || 0) - (b.subscription?.price || 0))
        .map((subscription) => subscription.subscription?.subscriptionId)
    }
    return []
  },
)

/**
 * Select subscription data by subscription Id and channel pathname
 * @param channelPathname
 * @param subscriptionId
 */
export const selectSubscriptionBySubscriptionId =
  (channelPathname: string, subscriptionId: string) =>
  ({ channels }: RootState): SubscriptionInterface => {
    const channel = channels?.byChannelPathname?.[channelPathname]
    const channelSubscription = channel?.channelSubscription?.find(
      (channelSubscription) => channelSubscription?.subscription?.subscriptionId === subscriptionId,
    )
    return channelSubscription?.subscription
  }

/**
 * Select pass data by pass Id and channel pathname
 * @param channelPathname
 * @param passId
 */
export const selectPassByPassId =
  (channelPathname: string, passId: string) =>
  ({ channels }: RootState): PassInterface => {
    const channel = channels?.byChannelPathname?.[channelPathname]
    const channelPass = channel?.channelPass?.find(
      (channelPass) => channelPass?.pass?.passId === passId,
    )
    return channelPass?.pass
  }

/**
 * Select pass data by pass Id and channel pathname
 * @param channelPathname
 * @param passId
 */
export const selectPassDataByPassId =
  (channelPathname: string, passId: string) =>
  ({ channels }: RootState): PassCardInterface => {
    const channel = channels.byChannelPathname?.[channelPathname]
    const channelPass = channel?.channelPass?.find(
      (channelPass) => channelPass.pass.passId === passId,
    )

    if (channelPass) {
      const { pass } = channelPass
      let thumbnailToUse = ''
      const isNewCropVersion = isNewCropPassSubImage(pass.thumbnail)
      if (isNewCropVersion) thumbnailToUse = buildThumbnailImageToUse(pass.thumbnail, 310, '1:1')
      else
        thumbnailToUse = generateTransformationUrl(pass.thumbnail, {
          width: 310,
          aspectRatio: '1:1',
          quality: 'auto',
        })
      return {
        imageUrl: thumbnailToUse,
        title: pass.name,
        description: pass.description,
        price: toDisplayCurrency(pass.currency) + toDollar(pass.price),
        durationInDays: pass.durationInDays,
      }
    }
  }

/**
 * Select channel theme by channel pathname
 * @param channelPathname
 */
export const selectChannelThemeByChannelPathname =
  (channelPathname: string) =>
  ({ channels }: RootState): ChannelThemeInterface => {
    const channel = channels.byChannelPathname?.[channelPathname]

    if (channel) {
      return channel.channelTheme
    }
  }

/**
 * Select super rail channels by channel pathname
 * @param channelPathname
 */
export const selectSuperRailChannelsByChannelPathname = createSelector(
  [({ channels }: RootState) => channels, (state, channelPathname: string) => channelPathname],
  (channels, channelPathname) => {
    const channel = channels.byChannelPathname?.[channelPathname]
    if (channel?.superChannel && channel?.superChannelRail?.railChannels?.length) {
      const railChannels = channel.superChannelRail.railChannels.map((rail) => rail.channel)
      return convertArrayToObject<ChannelInterface>(railChannels, 'channelUrl')
    }
    return null
  },
)

/**
 * Select super rail channel names by channel pathname
 * @param channelPathname
 */
export const selectSuperRailChannelNamesByChannelPathname = createSelector(
  [({ channels }: RootState) => channels, (state, channelPathname: string) => channelPathname],
  (channels, channelPathname) => {
    const channel = channels.byChannelPathname?.[channelPathname]
    if (channel?.superChannel && !isEmptyArray(channel?.superChannelRail?.railChannels)) {
      const railChannels = [...channel.superChannelRail.railChannels]
      // Sort the rails by order
      const railChannelsSorted = railChannels.sort(
        (railChannelA, railChannelB) => railChannelA.order - railChannelB.order,
      )
      return railChannelsSorted.map((railChannel) => railChannel.channel.channelUrl)
    }
    return []
  },
)

/**
 * Select channel passes if exists by channel pathname
 * @param channelPathname
 */
export const selectChannelPassesByPathname = createSelector(
  [({ channels }: RootState) => channels, (state, channelPathname: string) => channelPathname],
  (channels, channelPathname) => {
    const channel = channels.byChannelPathname?.[channelPathname]

    if (channel && !isEmptyArray(channel.channelPass)) {
      const channelPasses = [...channel.channelPass]
      return channelPasses
        .sort((a, b) => a.pass?.price - b.pass?.price)
        .map((channelP) => channelP.pass)
    }
  },
)

/**
 * Select channel theme by channel pathname
 * @param channelPathname
 * @param passId
 */
export const selectChannelPassById =
  (channelPathname: string, passId: string) =>
  (state: RootState): PassInterface => {
    const passes = selectChannelPassesByPathname(state, channelPathname)
    return passes?.find((pass) => pass.passId === passId)
  }

/**
 * Select sub channel data if main channel is a super channel
 * @param mainChannelName
 * @param channelPathname
 */
export const selectSubChannelData =
  (channelPathname: string, mainChannelName?: string) => (state: RootState) => {
    if (mainChannelName) {
      const isSuperChannel = selectChannelPropByChannelPathname(mainChannelName ?? '')(
        'superChannel',
      )(state)
      if (isSuperChannel) {
        return selectSuperRailChannelsByChannelPathname(state, mainChannelName)?.[channelPathname]
      }
    }

    return undefined
  }

/**
 * Select channel content type labels by channel pathname
 * @param channelPathname
 */
export const selectChannelContentTypeLabelsByChannelPathname =
  (channelPathname: string) =>
  ({ channels }: RootState): ContentTypeLabelInterface[] => {
    const channel = channels.byChannelPathname?.[channelPathname]
    if (channel) {
      return channel.contentTypeLabels
    }
    return []
  }

/**
 * Select the subscription data for event tracking
 * @param data
 */
export const selectSubscriptionDataForEventTracking =
  (data: { subscriptionId: string; channelPathname: string }) =>
  ({ channels }: RootState): EventTrackingDataInterface => {
    const { subscriptionId, channelPathname } = data
    const channel = channels.byChannelPathname?.[channelPathname]
    const channelSubscription = channel?.channelSubscription?.find(
      (channelSubscription) => channelSubscription.subscription?.subscriptionId === subscriptionId,
    )

    if (channelSubscription && channelSubscription.subscription) {
      const {
        subscription: { price, currency, subscriptionName },
      } = channelSubscription

      return {
        value: toDollar(price),
        currency: currency,
        product_name: subscriptionName,
        product_type: VidzingProductTypesEnum.SUBSCRIPTION,
      }
    }
    return undefined
  }

/**
 * Select the pass data for event tracking
 * @param data
 */
export const selectPassDataForEventTracking =
  (data: { passId: string; channelPathname: string }) =>
  ({ channels }: RootState): EventTrackingDataInterface => {
    const { passId, channelPathname } = data
    const channel = channels.byChannelPathname?.[channelPathname]
    const channelPass = channel?.channelPass?.find(
      (channelPass) => channelPass.pass?.passId === passId,
    )

    if (channelPass && channelPass.pass) {
      const {
        pass: { price, currency, name },
      } = channelPass

      return {
        value: toDollar(price),
        currency: currency,
        product_name: name,
        product_type: VidzingProductTypesEnum.PASS,
      }
    }
    return undefined
  }

/**
 * Select the pass data for event tracking
 * @param channelPathname
 */
export const selectSuccessfulChannelEventForTracking =
  (channelPathname: string) =>
  (state: RootState): EventTrackingDataInterface => {
    const { passes, subscribers, channels } = state

    // Check if user has an active pass for this channel and the user pass details are available
    const hasPassAccess = passes.byChannelPathname?.[channelPathname]?.isActive
    const passDetails = passes.byChannelPathname?.[channelPathname]?.userPassDetails

    if (hasPassAccess && passDetails) {
      return selectPassDataForEventTracking({ passId: passDetails?.passId, channelPathname })(state)
    }

    // Check if user has an active subscription for this channel and the user subscription details are available
    const hasSubscriptionAccess = subscribers.byChannelPathname?.[channelPathname]?.isSubscribed
    const subscriptionDetails =
      subscribers.byChannelPathname?.[channelPathname]?.userSubscriptionDetails

    if (hasSubscriptionAccess && subscriptionDetails) {
      return selectSubscriptionDataForEventTracking({
        subscriptionId: subscriptionDetails?.subscriptionId,
        channelPathname,
      })(state)
    }

    return undefined
  }
