import { CustomChannelInterface } from '@shared/interfaces/custom-channel.interface'

export const INVALID_PASSWORD = 'The email address or password you entered is invalid'
export const DYNAMIC_PAGE_SLUG = '[channel_pathname]'
export const CUSTOM_CHANNELS: CustomChannelInterface[] = [
  {
    name: 'Southern cross health',
    path: '/southern-cross-health-insurance',
    backgroundColour: '#FFFFFF',
    mainColour: '#008BCE',
    secondaryColour: '#FFFFFF',
    fontFormColour: '#000000',
    inputBackgroundColour: '#FFFFFF',
    logo: '/_custom-channels/southern-cross-health-insurance/logo.png',
    logoWhite: '/_custom-channels/southern-cross-health-insurance/logo-white.png',
    rightImage: '/_custom-channels/southern-cross-health-insurance/right-column.jpg',
    rightColumnTitle: 'Southern Cross Health Insurance',
    rightColumnDesc:
      "As a not for profit Friendly Society, and New Zealand's largest health insurer, our focus is on helping our members to live well for longer. As your business partner, we want to help you lead your people into a happier, healthier and more productive future. We've gathered a selection of health and wellbeing experts to discuss some of the challenges (and solutions) that you and your people may face",
    rightColumnColourText: '#FFFFFF',
    playerBackgroundColour: '#0F486B',
    customPlayerContentId: '50171121-1ee8-47fd-bd9e-c3ab40fd7ec4',
    signUpDisclaimer:
      'The data you enter into this form will only be used for the purpose of accessing the Gather 2022 livestream; it will not be shared with any other parties and you will not be added to any marketing databases.',
    signUpButtonText: 'Confirm',
  },
]

export const SOUTHERN_CROSS_CONTENT_WITH_IMAGE = [
  // Prod Live stream
  '50171121-1ee8-47fd-bd9e-c3ab40fd7ec4',
  // Prod Agenda Content
  '2d2d21d3-5941-4f63-a067-c493a53be008',
  // UAT Speakers Bundle
  'b970ccf1-98ee-47a1-ac47-a3d08e1a77da',
]

export const SOUTHERN_CROSS_AGENDA_DATA = {
  href: 'https://vidzing-documents.s3.ap-southeast-2.amazonaws.com/southern-cross-health-insurance/Gather+2023_A4+Agenda.pdf',
  imageUrl:
    'https://res.cloudinary.com/vidzing/image/upload/v1700001847/Gather_2023_A4_Agenda_tneubh.jpg',
  alt: 'Southern Cross Gather 2023 Agenda',
}
