import { VidzingFeeInterface } from '@shared/interfaces/creators/vidzing-fee.interface'
import { convertSecondsToDays, roundToAtMostTwoDecimalPlaces } from '@utils/connascence'
import { ROUNDER } from '@shared/constants/root.constants'
import { toDisplayCurrency } from '@library/_utilities/to-display-currency'
import { toDollar } from '@library/_utilities/to-dollar'
import { ContentEntity, ContentPrice } from '@apis/contents-microservice/_entities/_types'
import { AVAILABILITY_DURATION_OPTIONS } from '@shared/constants/price.constants'
import { add } from 'date-fns'
import { getDifferenceInSecondsFromDateStrings } from '@utils/get-difference-in-seconds-from-date-strings'

export function getVidzingFeeFromPriceAndFee(
  price: number,
  vidzingFee: VidzingFeeInterface,
): number {
  return Math.round(
    price * feePercentageToDecimalPercentage(vidzingFee?.feePercentage) + vidzingFee?.feeCents,
  )
}

export function getTotalPriceFromPrceAndFee(
  price: number,
  vidzingFee: VidzingFeeInterface,
): number {
  const vidzingsFee = getVidzingFeeFromPriceAndFee(price, vidzingFee)

  return Math.round(price + vidzingsFee)
}

export function feePercentageToDecimalPercentage(percentage: number): number {
  return roundToAtMostTwoDecimalPlaces(percentage / ROUNDER)
}

// Take a currency and amount and return a string with the currency symbol and amount
export function currencyAndAmountString(currency: string, amount: number): string {
  return toDisplayCurrency(currency) + toDollar(amount)
}

// Get price availability duration options
export function getAvailabilityLabel(price: ContentPrice) {
  if (price.rental && price.availabilityDurationInSeconds) {
    const availabilityDurationInDays = convertSecondsToDays(price.availabilityDurationInSeconds)
    const days = availabilityDurationInDays.toString()
    const option = AVAILABILITY_DURATION_OPTIONS.find((option) => option.value === days)
    return option?.label ?? `${days} days`
  }
  return undefined
}

export function filterPriceForExpirationAndRental(
  pricesArray: ContentPrice[],
  content: ContentEntity,
) {
  return pricesArray.filter((price) => {
    if (content.scheduledExpiryDate && price.availabilityDurationInSeconds) {
      // Get date today and add the duration of the price (rental period)
      const futurePriceDate = add(new Date(), {
        seconds: price.availabilityDurationInSeconds,
      }).toISOString()

      // Get the difference in seconds between the future price date and the expiration date
      // If the future price date is greater than the expiration date then the difference is negative
      // If the future price date is less than the expiration date then the difference is positive
      const futurePriceDateAndExpirationDiff = getDifferenceInSecondsFromDateStrings(
        futurePriceDate,
        content.scheduledExpiryDate,
      )

      return futurePriceDateAndExpirationDiff >= 0
    }
    return true
  })
}
