import { buildUrl } from 'cloudinary-build-url'
import type { CldOptions } from '@cld-apis/types/lib/options/CldOptions'
import type { ResizeType } from '@cld-apis/types'

// Default quality by default
import { DEFAULT_QUALITY_IMG } from '@root/constants'
import { AspectRatios } from '@shared/types/images.types'

// We need able to pass a String because the quality can be a number ( 10 to 100) or the word auto
type QualityTypes = number | string

export type TransformationOptions = {
  width: number
  height?: number
  aspectRatio?: AspectRatios
  type?: ResizeType
  quality?: QualityTypes
}

/**
 * We need a utility to generate Cloudinary transformation URL because:
 * 1. Cloudinary SDK [only support ES6 module](https://github.com/cloudinary/js-url-gen#testing-with-jest)
 * This will cause 'Cannot use import statement outside a module' error.
 * Probably because server rendering need a node server to run this code.
 * 2. Cloudinary transformation URL don't use query parameters. <br>
 * [Cloudinary resizing doc](https://cloudinary.com/documentation/resizing_and_cropping)
 */
export function generateTransformationUrl(
  pathname: string,
  {
    width,
    height,
    aspectRatio,
    type = 'fill',
    quality = DEFAULT_QUALITY_IMG,
  }: TransformationOptions,
): string {
  const cloud = { cloudName: 'vidzing' }

  const cldOptions: CldOptions = {
    cloud,
    transformations: {
      resize: {
        width,
        height,
        aspectRatio: height === undefined ? aspectRatio : undefined,
        type,
      },
      quality,
      flags: 'force_strip',
    },
  }

  return pathname ? buildUrl(pathname, cldOptions) : ''
}
