import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import type { LogsInitConfiguration } from '@datadog/browser-logs/cjs/domain/configuration'
import type { RumInitConfiguration } from '@datadog/browser-rum-core/cjs/domain/configuration'

export default function UseDatadog() {
  function initDatadog() {
    const rumConfig: RumInitConfiguration = {
      applicationId: '148e4666-98d7-41cc-9ec1-e224e995db2d',
      clientToken: 'pub57957b437ce521f7d7252ab2f581717e',
      site: 'datadoghq.com',
      env: process.env.ENVIRONMENT,
      service: process.env.NEXT_PUBLIC_VIDZING_APP_NAME,
      version: process.env.NEXT_PUBLIC_VIDZING_APP_VERSION,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      silentMultipleInit: true,
    }

    const logConfig: LogsInitConfiguration = {
      clientToken: 'pub57957b437ce521f7d7252ab2f581717e',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: process.env.ENVIRONMENT,
      service: process.env.NEXT_PUBLIC_VIDZING_APP_NAME,
      version: process.env.NEXT_PUBLIC_VIDZING_APP_VERSION,
      silentMultipleInit: true,
    }

    datadogRum.init(rumConfig)
    datadogLogs.init(logConfig)
  }

  useEffect(() => {
    if (process.env.FRONT_END_ENV !== 'local') {
      initDatadog()
    }
  })
}
