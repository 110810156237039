import axiosRequest from '@apis/axios'
import { PassStatusInterface } from '@shared/interfaces/creators/channel-pass/pass-status.interface'

export default async function checkPassIdStatusByPassIdAndChannelId({
  passId,
  channelId,
}: {
  passId: string
  channelId: string
}): Promise<PassStatusInterface> {
  return (await axiosRequest(
    'billing',
    {
      method: 'GET',
      url: `pass/${passId}/status`,
      params: {
        channelId,
      },
    },
    true,
  )) as PassStatusInterface
}
