import ApiErrors from '../../_utilities/api-errors'
import { BASIC_AUTH_HEADERS } from '../../../constants'
import { APIDETAILS } from '../../_utilities/environment/api.url.details'
import type { ErrorMessages } from '../../_types'
import { UserAccountStatus } from '../_types'

export default async function checkEmailExist(email: string): Promise<UserAccountStatus> {
  const url = `${APIDETAILS.usersUrl}/check/${email}`

  try {
    const response = await fetch(url, { headers: BASIC_AUTH_HEADERS })

    switch (response.status) {
      case 200: {
        return await response.json()
      }
      default: {
        const errorMessages: ErrorMessages = [
          { code: 'web/unsuppotred-http-status-code', message: 'Unsupported HTTP status code.' },
        ]
        return Promise.reject(new ApiErrors(errorMessages))
      }
    }
  } catch (networkError) {
    const errorMessages: ErrorMessages = [
      { code: 'web/network-unavailable', message: 'Network unavailable, please try again later.' },
    ]
    return Promise.reject(new ApiErrors(errorMessages))
  }
}
