import { createAsyncThunk } from '@reduxjs/toolkit'
import { getOrganisationByOrgId as getOrganisationByOrgIdApi } from '@apis/creators-microservice/get-organisation-by-org-id'

export const fetchOrganisationByOrgId = createAsyncThunk(
  'organisation/getOrganisation',
  async (organisationId: string, thunkAPI) => {
    try {
      return await getOrganisationByOrgIdApi(organisationId)
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message ?? 'Error')
    }
  },
)
