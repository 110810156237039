import { GetInvoiceResponse } from '@shared/interfaces/users-billing/user-billing-invoices.interfaces'
import { InvoicesState } from '@store/user-billing-slice/invoices/_interfaces'

export const pendingReducer = (state: InvoicesState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getInvoicesFulfilledReducer = (
  state: InvoicesState,
  {
    payload,
  }: {
    payload: GetInvoiceResponse
  },
): void => {
  const { invoices } = payload

  state.data.invoices = invoices

  // Update the fetchStatus to indicate the successful data retrieval
  state.fetchStatus = 'succeeded'

  // Clear any previous error messages
  state.errorMessages = ''
}

export const rejectedReducer = (
  state: InvoicesState,
  { error }: { error: { message: string } },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = error.message
}
