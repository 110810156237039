import { createAsyncThunk } from '@reduxjs/toolkit'
import { datadogLogs } from '@datadog/browser-logs'
import { MyVidzActivePlansResponse } from '@shared/interfaces/users-billing/user-billing-my-vidz-plans.interfaces'
import getMyvidzActivePlans from '@apis/billing-microservice/user-billing/get-myvidz-active-plans'

export const getUserBillingActivePlansThunk = createAsyncThunk(
  'user-billing/active-plans/fetch',
  async (): Promise<MyVidzActivePlansResponse> => {
    try {
      const response = await getMyvidzActivePlans()
      return response
    } catch (error) {
      datadogLogs.logger.warn('Error fetching user billing active-plans', error)
      throw error
    }
  },
)
