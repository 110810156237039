export const firebaseConfig = {
  dev: {
    apiKey: 'AIzaSyCC3FyeK72dunHBP-rL8iRD9cOtZKzkSmY',
    authDomain: 'vidzing-app-dev.firebaseapp.com',
    projectId: 'vidzing-app-dev',
    storageBucket: 'vidzing-app-dev.appspot.com',
    messagingSenderId: '970639122733',
    appId: '1:970639122733:web:56848c0f725157c4a7b3a4',
    measurementId: 'G-JYV4PFH0SR',
  },
  uat: {
    apiKey: 'AIzaSyCoDpACwu-0KWcomalOidjpUnbcg8V19fA',
    authDomain: 'vidzingapp-uat.firebaseapp.com',
    projectId: 'vidzingapp-uat',
    storageBucket: 'vidzingapp-uat.appspot.com',
    messagingSenderId: '937104637708',
    appId: '1:937104637708:web:efd51a4d28a08a5b221329',
    measurementId: 'G-Z27MNDQ9CW',
  },
  prod: {
    apiKey: 'AIzaSyCjYhAmnLSKw8Ef9imy2igcCZPJ3dNSppk',
    authDomain: 'vidzing-dc8b3.firebaseapp.com',
    projectId: 'vidzing-dc8b3',
    storageBucket: 'vidzing-dc8b3.appspot.com',
    messagingSenderId: '228643541041',
    appId: '1:228643541041:web:f8e7ba81fb1e15162b8510',
    measurementId: 'G-P0TVWGEPEQ',
  },
}
