import axiosRequest from '@apis/axios'
import { MyVidzActivePlansResponse } from '@shared/interfaces/users-billing/user-billing-my-vidz-plans.interfaces'

export default async function getMyvidzActivePlans(): Promise<MyVidzActivePlansResponse> {
  return (await axiosRequest(
    'billing',
    {
      method: 'GET',
      url: 'user-subscriptions/myvidz/active-plans',
    },
    true,
  )) as MyVidzActivePlansResponse
}
