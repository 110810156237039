import { createSlice } from '@reduxjs/toolkit'
import {
  generateClientSecretFulfilledReducer,
  getPaymentMethodsFulfilledReducer,
  patchUserDefaultPaymentMethodFulfilledReducer,
  pendingReducer,
  rejectedReducer,
} from './_reducers'
import { initialUserBillingPaymentMethodState } from '@store/user-billing-slice/payment-methods/_types'
import {
  deleteUserBillingPaymentMethodThunk,
  generateUserClientSecretThunk,
  getUserBillingPaymentMethodsThunk,
  updateUserBillingDefaultPaymentMethodThunk,
} from '@store/user-billing-slice/payment-methods/_thunks'

const userBillingPaymentMethodSlice = createSlice({
  name: 'user-billing-payment-methods',
  initialState: initialUserBillingPaymentMethodState,
  reducers: {
    resetUserBillingPaymentMethodState: (state) => initialUserBillingPaymentMethodState,
  },
  extraReducers: {
    [getUserBillingPaymentMethodsThunk.pending.toString()]: pendingReducer,
    [getUserBillingPaymentMethodsThunk.fulfilled.toString()]: getPaymentMethodsFulfilledReducer,
    [getUserBillingPaymentMethodsThunk.rejected.toString()]: rejectedReducer,
    [deleteUserBillingPaymentMethodThunk.pending.toString()]: pendingReducer,
    [deleteUserBillingPaymentMethodThunk.fulfilled.toString()]: getPaymentMethodsFulfilledReducer,
    [deleteUserBillingPaymentMethodThunk.rejected.toString()]: rejectedReducer,
    [generateUserClientSecretThunk.fulfilled.toString()]: generateClientSecretFulfilledReducer,
    [generateUserClientSecretThunk.pending.toString()]: pendingReducer,
    [generateUserClientSecretThunk.rejected.toString()]: rejectedReducer,
    [updateUserBillingDefaultPaymentMethodThunk.fulfilled.toString()]:
      patchUserDefaultPaymentMethodFulfilledReducer,
    [updateUserBillingDefaultPaymentMethodThunk.pending.toString()]: pendingReducer,
    [updateUserBillingDefaultPaymentMethodThunk.rejected.toString()]: rejectedReducer,
  },
})

export const userBillingPaymentMethodReducer = userBillingPaymentMethodSlice.reducer
