import { ModuleContentInterface, ModuleRequestResponse } from '../_entities/_types'
import axiosRequest from '@apis/axios'
import { GetModuleIdInterface } from '@shared/interfaces/get-content-by-id.interface'

export default async function getModuleById({
  bundleId,
  moduleId,
}: GetModuleIdInterface): Promise<ModuleContentInterface[]> {
  return (await axiosRequest('bundles', {
    method: 'GET',
    url: `bundle/${bundleId}/bundle-module/${moduleId}`,
  })) as ModuleContentInterface[]
}
