import { createAsyncThunk } from '@reduxjs/toolkit'
import { datadogLogs } from '@datadog/browser-logs'
import {
  GetContentByIdInterface,
  GetModuleIdInterface,
} from '@shared/interfaces/get-content-by-id.interface'
import getContentById from '@apis/contents-microservice/get-content-by-id'
import {
  GET_BUNDLE_CONTENT_BY_CONTENT_ID_THUNK_TYPE,
  GET_MODULE_CONTENT_BY_MODULE_ID_THUNK_TYPE,
} from '@store/bundle-slice/_constants'
import getCountryCode from '@store/_utilities/get-country-code'
import getModuleById from '@apis/contents-microservice/get-module-by-id'
import { ContentEntity } from '@apis/contents-microservice/_entities/_types'
import { isEmptyArray } from '@utils/arrays'
import { getContentByIdThunk } from '@store/contents-slice/_thunks'

/**
 * Get content bundle by content id
 * @param contentId
 * @param channelId
 * @returns ContentEntity
 */
export const getBundleContentByContentIdThunk = createAsyncThunk(
  GET_BUNDLE_CONTENT_BY_CONTENT_ID_THUNK_TYPE,
  async ({ contentId, channelId }: GetContentByIdInterface, thunkAPI) => {
    try {
      const countryCode = (await getCountryCode()) as string
      return await getContentById({
        contentId,
        channelId,
        countryCode,
        useNewBundles: true,
        withBundleContents: true,
      })
    } catch (error) {
      datadogLogs.logger.error(error?.message || `Error retrieving content bundle`, error)
      return thunkAPI.rejectWithValue(error?.data?.message ?? 'Error')
    }
  },
)

export const getModuleContentByModuleIdThunk = createAsyncThunk(
  GET_MODULE_CONTENT_BY_MODULE_ID_THUNK_TYPE,
  async (payload: GetModuleIdInterface, thunkAPI) => {
    try {
      const response = await getModuleById(payload)

      // Reduce response
      const contents: ContentEntity[] = response
        ? response.reduce((acc, item) => {
            acc.push(item.content)
            return acc
          }, [])
        : []

      // Fetch content data. This is used because we need the real button state
      if (!isEmptyArray(contents)) {
        contents.forEach((content) => {
          thunkAPI.dispatch(
            getContentByIdThunk({
              contentId: content.contentId,
              channelId: payload.channelId,
              useNewBundles: false,
            }),
          )
        })
      }

      return { bundleId: payload.bundleId, moduleId: payload.moduleId, contents }
    } catch (error) {
      datadogLogs.logger.error(error?.message || `Error retrieving content bundle`, error)
      return thunkAPI.rejectWithValue(error?.data?.message ?? 'Error')
    }
  },
)
