import type { RailEntity } from '../_types'
import axiosRequest from '@apis/axios'

export async function getAllRailsByChannelId(
  channelId = 'home-page',
  countryCode?: string,
): Promise<RailEntity[]> {
  return (await axiosRequest('rails', {
    method: 'GET',
    url: `all/${channelId}`,
    params: { country: countryCode },
  })) as RailEntity[]
}
