import { ListChannelsByCategoryThunkParameters } from '@store/channel-slice/_types'
import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { GET_ALL_CHANNELS_DEFAULT_PARAMS } from '@store/channel-slice/_constants'

export default async function getChannelsByCategoryAndId(
  request: ListChannelsByCategoryThunkParameters,
) {
  datadogLogs.logger.info('Fetch all channels under a category', { request })
  const { categorySlug, organisationId } = request

  const response = (await axiosRequest(
    'creators',
    {
      method: 'GET',
      url: 'channels/all',
      params: {
        ...GET_ALL_CHANNELS_DEFAULT_PARAMS,
        private: true,
        homePage: false,
        roles: false,
        stripeAccounts: false,
        subscriptions: false,
        theme: false,
        superChannelRail: false,
        pass: false,
        organisations: true,
        categorySlug,
        organisationId,
      },
    },
    false,
    '2',
  )) as ChannelInterface[]

  datadogLogs.logger.info('Successful fetched channels by category API request', {
    response,
    request,
  })

  return response
}
