// 1099 => 10.99
export const toDollar = (cent: number): string => {
  const centString = cent?.toString()
  if (cent === 0) {
    return '0'
  } else if (cent < 10) {
    return '0.0' + centString
  } else if (cent < 100) {
    return '0.' + centString
  } else {
    return centString?.slice(0, -2) + '.' + centString?.slice(-2)
  }
}
