import { datadogLogs } from '@datadog/browser-logs'
import axiosRequest from '@apis/axios'
import { GetInvoiceResponse } from '@shared/interfaces/users-billing/user-billing-invoices.interfaces'

export default async function getInvoices(): Promise<GetInvoiceResponse> {
  datadogLogs.logger.info('Making get invoices API request')

  const response: GetInvoiceResponse = (await axiosRequest(
    'billing',
    {
      method: 'GET',
      url: 'user-subscriptions/invoices',
    },
    true,
  )) as GetInvoiceResponse

  datadogLogs.logger.info('Successfully fetched invoices API request', { response })

  return response
}
