// Declare global window object with fbq
import { ZERO } from '@root/constants'

declare global {
  interface Window {
    fbq: any
  }
}

export default function isFacebookPixelLoaded(fbPixelId?: string): boolean {
  // Check that window and fbq and fbq.getState() are defined to avoid errors
  if (typeof window !== 'undefined' && window.fbq) {
    // Wrapping in a try catch in case fbq.getState() throws an error, if that's the case return false
    try {
      const state = window.fbq.getState()
      if (state && state.pixels) {
        if (fbPixelId) {
          // Check if the array of pixels on fbq contains the pixel we want to check
          return state.pixels.some((pixel: any) => pixel.id === fbPixelId)
        }
        // Otherwise check if the array of pixels is greater than zero because then there is a pixel loaded
        return state.pixels.length > ZERO
      }
    } catch (e) {
      return false
    }
  }
  return false
}
