import { useAppDispatch } from '@root/store'
import { useEffect } from 'react'
import { getUserGeoLocationThunk } from '@store/geo-slice/_thunk'

export default function UseUserGeoLocation(): JSX.Element {
  const dispatch = useAppDispatch()
  useEffect(() => {
    ;(async () => {
      if (process.env.FRONT_END_ENV !== 'local') {
        await dispatch(getUserGeoLocationThunk())
      }
    })()
  }, [dispatch])
  return <></>
}
