import type { ErrorMessages } from '../../_types'

export default class ApiErrors extends Error {
  errorMessages: ErrorMessages

  constructor(errorMessages: ErrorMessages) {
    super('Api errors occurred.')
    this.name = 'ApiErrors'
    this.errorMessages = errorMessages
  }
}
