import type { RootState } from '..'
import type { FetchStatus } from '../_types'
import { UserEntity } from '@apis/users-microservice/_types'

export const selectStatus = ({ user }: RootState): FetchStatus | undefined => {
  return user.fetchStatus
}

export const selectUserId = ({ user }: RootState): string | undefined => user.userId

export const selectUserProps =
  <PropKey extends keyof UserEntity>(propKey: PropKey) =>
  ({ user }: RootState): UserEntity[PropKey] | undefined => {
    // If property value is null, return undefined
    return user?.[propKey] ?? undefined
  }

export const selectWatching = ({ user }: RootState): number | undefined => user.watching

export const selectTooManyUsersWatching = ({ user }: RootState): boolean | undefined =>
  user.tooManyUsersWatching

export const selectEmail = ({ user }: RootState): RootState['user']['email'] => user.email
