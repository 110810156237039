import { ContentPrice } from '@apis/contents-microservice/_entities/_types'
import { isEqualToOne, isEqualToZero, isGreaterThanOne } from '@root/utils/connascence'
import { FREE_TAG, PAY_WHAT_YOU_CAN_TAG } from '@root/constants'
import { toDisplayCurrency } from '@library/_utilities/to-display-currency'
import { toDollar } from '@library/_utilities/to-dollar'
import { SubscriptionInterface } from '@shared/interfaces/creators/subscription/subscription.interface'
import { SubscriptionRecurringType } from '@shared/types/subscription.recurring.type'
import { PassInterface } from '@shared/interfaces/creators/channel-pass/pass.interface'
import { HAS_MORE_PRICES_TAG } from '@shared/constants/price.constants'

/**
 * Function to build content price string for a content to be used in
 * other purchase options
 * @param contentPrices
 */
export function buildPriceStringForContent(contentPrices: ContentPrice[]): string {
  if (isEqualToOne(contentPrices.length)) {
    const price = contentPrices[0].price
    return isEqualToZero(price)
      ? FREE_TAG
      : toDisplayCurrency(contentPrices[0].currency) + toDollar(price)
  } else if (isGreaterThanOne(contentPrices.length)) {
    const contentPricesToBeSorted = [...contentPrices]
    const lowestContentPrice = contentPricesToBeSorted.sort((a, b) => a.price - b.price)[0]
    return isEqualToZero(lowestContentPrice.price)
      ? PAY_WHAT_YOU_CAN_TAG
      : `${HAS_MORE_PRICES_TAG} ` +
          toDisplayCurrency(lowestContentPrice.currency) +
          toDollar(lowestContentPrice.price)
  }
  return ''
}

/**
 * Build the price string for subscriptions
 * @param subscription
 * @param style
 */
export function buildPriceStringForSubscriptions(
  subscription: SubscriptionInterface,
  style: 'min' | 'full' = 'full',
): string {
  const dollarAndCurrency = toDisplayCurrency(subscription.currency) + toDollar(subscription.price)
  switch (style) {
    case 'min':
      return `${dollarAndCurrency} / ${getSubscriptionRecurringUnit(
        subscription.subscriptionRecurringInterval,
      )}`
    case 'full':
      return `${dollarAndCurrency} per ${subscription.subscriptionRecurringInterval}`
  }
  return `${toDisplayCurrency(subscription.currency)}${toDollar(subscription.price)} per ${
    subscription.subscriptionRecurringInterval
  }`
}

/**
 * Get unit from subscription recurring type
 * @param recurringType
 */
export function getSubscriptionRecurringUnit(recurringType: SubscriptionRecurringType): string {
  const map: Record<SubscriptionRecurringType, string> = {
    day: 'dy',
    week: 'wk',
    month: 'mo',
    year: 'yr',
  }

  return map[recurringType]
}

/**
 * Build the price string for pass
 * @param pass
 */
export function buildPriceStringForPass(pass: PassInterface): string {
  return `${toDisplayCurrency(pass.currency)}${toDollar(pass.price)} for ${
    pass.durationInDays
  } days`
}
