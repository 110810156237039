import fbAuth from '../..'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import gtmEvent from '../../../google-analytics'
import { datadogRum } from '@datadog/browser-rum'
import { getUserVizingDataThunk } from '@store/user-slice/_thunk'

function useAuthChangeHandler(): void {
  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    // TODO: remove listener
    fbAuth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        dispatch(getUserVizingDataThunk(user.email))
        // sets user email for datadogRUM, so we can track user's journey
        datadogRum.setUser({
          email: user.email,
          id: user.uid,
        })
        gtmEvent(router.asPath, user.uid)
      } else {
        gtmEvent(router.asPath, '')
      }
    })
  }, [dispatch])
}

// TODO: HOC
export default function UseAuthChangeHandler(): JSX.Element {
  useAuthChangeHandler()

  return <></>
}
