import { BundleState } from '@store/bundle-slice/_types'
import {
  getBundleContentByContentIdThunk,
  getModuleContentByModuleIdThunk,
} from '@store/bundle-slice/_thunks'
import { createSlice } from '@reduxjs/toolkit'
import {
  setBundleContentFetchStatus,
  setContentBundleByContentId,
  setModuleContentsByModuleIdPending,
  setModuleContentsByModuleIdRejected,
  setModuleContentsByModuleIdSuccess,
} from '@store/bundle-slice/_reducers'

const initialState: BundleState = {
  byContentId: {},
  byBundleId: {},
  bundleModules: {},
  contentFetchStatus: 'idle',
}

const bundleSlice = createSlice({
  name: 'bundles',
  initialState,
  reducers: {},
  extraReducers: {
    [getBundleContentByContentIdThunk.fulfilled.toString()]: setContentBundleByContentId,
    [getBundleContentByContentIdThunk.pending.toString()]: setBundleContentFetchStatus,
    [getBundleContentByContentIdThunk.rejected.toString()]: setBundleContentFetchStatus,
    // Fetch module data
    [getModuleContentByModuleIdThunk.pending.toString()]: setModuleContentsByModuleIdPending,
    [getModuleContentByModuleIdThunk.fulfilled.toString()]: setModuleContentsByModuleIdSuccess,
    [getModuleContentByModuleIdThunk.rejected.toString()]: setModuleContentsByModuleIdRejected,
  },
})

export const bundleReducer = bundleSlice.reducer
