import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMuxAssetByAssetId as getMuxAssetByAssetIdApi } from '@apis/mux/get-mux-asset-by-asset-id'
import { datadogLogs } from '@datadog/browser-logs'

export const getMuxAssetById = createAsyncThunk(
  'assets/getMuxAsset',
  async (assetId: string, thunkAPI) => {
    try {
      return await getMuxAssetByAssetIdApi(assetId)
    } catch (error) {
      datadogLogs.logger.error(
        error?.data?.error?.response?.message || 'Error retrieving mux asset',
        error,
      )
      return thunkAPI.rejectWithValue(error?.data?.error?.response?.message ?? 'Error')
    }
  },
)
