import { AssetsState } from '@store/asset-slice/_types'
import { MuxAssetInterface } from '@shared/interfaces/assets/mux-asset.interface'

export const getMuxAssetPendingReducer = (state: AssetsState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const getMuxAssetRejectedReducer = (
  state: AssetsState,
  { payload }: { payload: string },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = payload
}

export const getMuxAssetFulfilled = (
  state: AssetsState,
  { payload }: { payload: MuxAssetInterface },
): void => {
  state.fetchStatus = 'succeeded'
  state.errorMessages = ''
  const index = state.muxAssets.findIndex((muxAsset) => muxAsset.muxAssetId === payload.muxAssetId)
  if (index !== -1) {
    state.muxAssets[index] = payload
  } else {
    state.muxAssets = [...state.muxAssets, ...[payload]]
  }
}
