import getSymbolFromCurrency from 'currency-symbol-map'

// aud => AU$
// nzd => NZ$
// usd => US$
// gbp => GB£
export const toDisplayCurrency = (currency: string): string =>
  currency?.toUpperCase().slice(0, -1) + currencySymbol(currency)

export function useCurrencyAndSymbol(currency: string): string {
  const symbol = getSymbolFromCurrency(currency)
  return currency.slice(0, 2).toUpperCase() + symbol
}

export function currencySymbol(currency: string): string {
  if (!currency) return '$'
  return getSymbolFromCurrency(currency.toLowerCase())
}
