import { createAsyncThunk } from '@reduxjs/toolkit'
import { datadogLogs } from '@datadog/browser-logs'
import getPaymentMethods from '@apis/billing-microservice/user-billing/get-payment-methods'
import {
  SubscriptionDefaultPaymentMethodResponse,
  GenerateClientSecretResponse,
  GetPaymentMethodResponse,
  UserDefaultPaymentMethodResponse,
} from '@shared/interfaces/users-billing/user-billing-payment-methods.interfaces'
import generateClientSecret from '@apis/billing-microservice/user-billing/generate-client-secret'
import deletePaymentMethod from '@apis/billing-microservice/user-billing/delete-payment-method'
import patchSubscriptionDefaultPaymentMethod from 'apis/billing-microservice/user-billing/patch-subscription-default-payment-method'
import patchUserDefaultPaymentMethod from '@apis/billing-microservice/user-billing/patch-user-default-payment-method'

export const getUserBillingPaymentMethodsThunk = createAsyncThunk(
  'user-billing/payment-methods/fetch',
  async (): Promise<GetPaymentMethodResponse> => {
    try {
      // Logs the API request to Datadog inside getPaymentMethods function
      const response = await getPaymentMethods()

      return response
    } catch (error) {
      datadogLogs.logger.warn('Error fetching user billing payment methods', error)
      throw error
    }
  },
)

export const generateUserClientSecretThunk = createAsyncThunk(
  'user-billing/payment-methods/generate-client-secret',
  async (): Promise<GenerateClientSecretResponse> => {
    try {
      // Logs the API request to Datadog inside generateClientSecret function
      const response = await generateClientSecret()

      return response
    } catch (error) {
      datadogLogs.logger.warn('Error generating client-secret', error)
      throw error
    }
  },
)

export const deleteUserBillingPaymentMethodThunk = createAsyncThunk(
  'user-billing/payment-methods/delete',
  async (paymentMethodId: string): Promise<GetPaymentMethodResponse> => {
    try {
      // Logs the API request to Datadog inside deletePaymentMethod function
      const response = await deletePaymentMethod({ paymentMethodId })

      return response
    } catch (error) {
      datadogLogs.logger.warn('Error deleting user billing payment method', error)
      throw error
    }
  },
)

export const updateSubscriptionDefaultPaymentMethodThunk = createAsyncThunk(
  'user-billing/payment-methods/subscription-patch',
  async ({
    stripeSubscriptionId,
    paymentMethodId,
  }: {
    stripeSubscriptionId: string
    paymentMethodId: string
  }): Promise<SubscriptionDefaultPaymentMethodResponse> => {
    try {
      // Logs the API request to Datadog inside patchDefaultPaymentMethod function
      const response = await patchSubscriptionDefaultPaymentMethod({
        stripeSubscriptionId,
        paymentMethodId,
      })

      return response
    } catch (error) {
      datadogLogs.logger.warn('Error updating subscription billing default payment method', error)
      throw error
    }
  },
)

export const updateUserBillingDefaultPaymentMethodThunk = createAsyncThunk(
  'user-billing/payment-methods/patch',
  async ({
    paymentMethodId,
  }: {
    paymentMethodId: string
  }): Promise<UserDefaultPaymentMethodResponse> => {
    try {
      // Logs the API request to Datadog inside patchUserDefaultPaymentMethod function
      const response = await patchUserDefaultPaymentMethod({ paymentMethodId })

      return response
    } catch (error) {
      datadogLogs.logger.warn('Error updating user billing default payment method', error)
      throw error
    }
  },
)
