import { AppDispatch, useAppDispatch } from '@store/index'
import { setWebSocket } from '@store/websocket-slice'
import { useEffect } from 'react'
import { WebSocketOnUserPlays, WebSocketOnUserStops } from '../..'
import { WEBSOCKET_URL } from '../../websocket.config'

export enum ReOpenFunctionType {
  ON_USER_PLAYS = 'on-user-plays',
  ON_USER_STOPS = 'on-user-stops',
}

export function openWebSocket(dispatch: AppDispatch, reOpenFunction?: ReOpenFunctionType): void {
  const webSocket = new WebSocket(WEBSOCKET_URL)
  webSocket.onopen = () => {
    dispatch(
      setWebSocket({
        webSocket: webSocket,
      }),
    )

    switch (reOpenFunction) {
      case ReOpenFunctionType.ON_USER_PLAYS:
        WebSocketOnUserPlays(webSocket, dispatch)
        break
      case ReOpenFunctionType.ON_USER_STOPS:
        WebSocketOnUserStops(webSocket, dispatch)
        break
      default:
        break
    }
  }
}

function useWebSocketInitialize(): void {
  const dispatch = useAppDispatch()
  const isBrowser = typeof window !== undefined

  useEffect(() => {
    if (isBrowser) {
      openWebSocket(dispatch)
    }
  }, [dispatch, isBrowser])
}

export default function UseWebSocketInitialize(): JSX.Element {
  useWebSocketInitialize()
  return <></>
}
