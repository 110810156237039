import axiosRequest from '@apis/axios'
import { RecommendedContentInterface } from '@shared/interfaces/content/recommended-content/recommended-content'

export default async function getRecommendedContentByIdApi(
  contentId: string,
): Promise<RecommendedContentInterface[]> {
  return (await axiosRequest('contents', {
    method: 'GET',
    url: `content/${contentId}/recommended-content`,
  })) as RecommendedContentInterface[]
}
