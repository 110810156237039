import { FeatureFlagState } from '@store/feature-flag-slice/_types'
import { PayloadAction } from '@reduxjs/toolkit'
import { FeatureFlagResponseMap } from '@shared/interfaces/feature-flags.interface'

export const setFeatureFlags = (
  state: FeatureFlagState,
  action: PayloadAction<FeatureFlagResponseMap>,
) => {
  if (!state.isLoaded) state.isLoaded = true
  state.featureFlags = action.payload.featureFlags
}

export const setFeatureFlagsFailed = (state: FeatureFlagState) => {
  if (!state.isLoaded) state.isLoaded = true
}
