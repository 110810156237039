import { PaginationInterface } from '@shared/interfaces/pagination.interface'

export const DEFAULT_TAKE = 8
export const DEFAULT_PAGINATION = 1

export const PAGINATION_PLACEHOLDER: PaginationInterface = {
  page: DEFAULT_PAGINATION,
  take: DEFAULT_TAKE,
  itemCount: 1,
  pageCount: 1,
  hasPreviousPage: false,
  hasNextPage: false,
}
