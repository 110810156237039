import type { AppEnvironments } from '../_utilities/environment/api.url.details'
import { webSocketUrl } from './constants'

export class WebSocketEnvironmentConfig {
  public appEnv: AppEnvironments

  constructor(environment: AppEnvironments) {
    this.appEnv = environment
  }

  getUrl(): string {
    if (this.appEnv === 'local') {
      return `${webSocketUrl}dev`
    }
    return `${webSocketUrl}${this.appEnv}`
  }
}

export const WEBSOCKET_URL = new WebSocketEnvironmentConfig(
  <'local' | 'prod' | 'dev' | 'uat'>process.env.ENVIRONMENT,
).getUrl()
