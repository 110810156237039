import axiosRequest from '@apis/axios'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { datadogLogs } from '@datadog/browser-logs'
import { CHANNEL_DEFAULT_QUERY_PARAMS_V2 } from '@shared/constants/get-channel-query-params.constants-v2'
import { GetByChannelPathNameParamsInterface } from '@apis/channels-microservice/_entities/_types'

export default async function getChannelByPathnameV2(
  channelPathname: string,
  queryParams: GetByChannelPathNameParamsInterface = CHANNEL_DEFAULT_QUERY_PARAMS_V2,
): Promise<ChannelInterface> {
  datadogLogs.logger.info('Making a FE API request to fetch a channel by pathname', {
    channelPathname,
    queryParams,
  })

  return (await axiosRequest(
    'creators',
    {
      method: 'GET',
      url: `channel-by-url/${channelPathname}`,
      params: queryParams,
    },
    false,
    '2',
  )) as ChannelInterface
}
