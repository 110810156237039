import { PlayerState } from '@store/player-slice/_types'
import { createSlice } from '@reduxjs/toolkit'
import { setAudioPlayReducer, setVideoPlayReducer } from '@store/player-slice/_reducers'

export const initialState: PlayerState = {
  audioPlay: false,
  videoPlay: false,
}

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setAudioPlay: setAudioPlayReducer,
    setVideoPlay: setVideoPlayReducer,
  },
})

export const playerReducer = playerSlice.reducer
export const { setAudioPlay, setVideoPlay } = playerSlice.actions
