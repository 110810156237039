import axiosRequest from '@apis/axios'
import { CHANNEL_DEFAULT_QUERY_PARAMS } from '@shared/constants/get-channel-query-params.constants'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { datadogLogs } from '@datadog/browser-logs'
import { GetByChannelPathNameParamsInterface } from '@apis/channels-microservice/_entities/_types'

export default async function getChannelByPathname(
  channelPathname: string,
  queryParams: GetByChannelPathNameParamsInterface = CHANNEL_DEFAULT_QUERY_PARAMS,
): Promise<ChannelInterface> {
  datadogLogs.logger.info('Making a FE API request to fetch a channel by pathname', {
    channelPathname,
    queryParams,
  })

  return (await axiosRequest('creators', {
    method: 'GET',
    url: `channel-by-url/${channelPathname}`,
    params: queryParams,
  })) as ChannelInterface
}
