import { ONE_SECOND_MS } from '../../constants'

/**
 * Function to return the difference in seconds between two date strings
 * @param startDate
 * @param endDate
 */
export function getDifferenceInSecondsFromDateStrings(startDate: string, endDate: string): number {
  const startDateTime = new Date(startDate).getTime()
  const endDateTime = new Date(endDate).getTime()

  return (endDateTime - startDateTime) / ONE_SECOND_MS
}
