import { createSlice } from '@reduxjs/toolkit'
import { getPurchasesFulfilledReducer, pendingReducer, rejectedReducer } from './_reducers'
import { initialUserBillingPurchasesState } from '@store/user-billing-slice/purchases/_types'
import { getUserBillingPurchasesThunk } from '@store/user-billing-slice/purchases/_thunks'

const userBillingPurchasesSlice = createSlice({
  name: 'user-billing-purchases',
  initialState: initialUserBillingPurchasesState,
  reducers: {
    resetUserBillingPurchasesState: (state) => initialUserBillingPurchasesState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserBillingPurchasesThunk.pending, pendingReducer)
    builder.addCase(getUserBillingPurchasesThunk.fulfilled, getPurchasesFulfilledReducer)
    builder.addCase(getUserBillingPurchasesThunk.rejected, rejectedReducer)
  },
})

export const userBillingPurchasesReducer = userBillingPurchasesSlice.reducer
