import { ContentTagInterface, TagState, TagStateInterface } from '@shared/interfaces/tag.interface'
import { TAGS_PLACEHOLDER } from '@shared/constants/tags.constants'
import { FetchStatus } from '@store/_types'
import { PaginationInterface } from '@shared/interfaces/pagination.interface'

/**
 * Set Fetch status as loading
 * @param state
 * @param action
 */
export const fetchTagPendingReducer = (state: TagState, action: any) => {
  const { arg: payload } = action.meta
  const currentTagData: TagStateInterface = state.tags.find((tag) => tag.tagId === payload.tagId)

  // Exclude requested tag from current state
  const filterStatus: TagStateInterface[] = state.tags.filter((tag) => tag.tagId !== payload.tagId)

  const updatedStatus: TagStateInterface =
    currentTagData !== undefined
      ? { ...currentTagData, fetchStatus: 'loading' }
      : { ...TAGS_PLACEHOLDER, fetchStatus: 'loading', tagId: payload.tagId, name: payload.name }
  state.tags = [...filterStatus, updatedStatus]
}

/**
 * Set Fetch status as Success/Failure
 * @param state
 * @param action
 */
export const fetchTagResultReducer = (
  state: TagState,
  { payload: response }: { payload: TagStateInterface },
) => {
  // Getting current tag from state
  const tagStateDate: TagStateInterface = state.tags.find((tag) => tag.tagId === response.tagId)

  // Removing tag from state
  const filteredState: TagStateInterface[] = [
    ...state.tags.filter((tag) => tag.tagId !== response.tagId),
  ]

  if (response?.meta?.page > 1 && tagStateDate) {
    const newState: TagStateInterface = {
      ...tagStateDate, // Ensure existing properties are preserved
      data: [...tagStateDate.data, ...response.data],
      fetchStatus: response.fetchStatus, // Updating fetch status
      meta: { ...response.meta }, // Update meta with the latest response meta
    }

    state.tags = [...filteredState, newState]
  } else {
    state.tags = [...filteredState, response]
  }
}
