import { UserBillingState } from '@store/user-billing-slice/_interfaces'
import { GetActiveSubscriptionsResponse } from '@shared/interfaces/users-billing/user-billing-subsciptions.interfaces'
import {
  SubscriptionDefaultPaymentMethodResponse,
  UserDefaultPaymentMethodResponse,
} from '@shared/interfaces/users-billing/user-billing-payment-methods.interfaces'

export const pendingReducer = (state: UserBillingState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const userBillingFulfilledReducer = (
  state: UserBillingState,
  {
    payload,
  }: {
    payload: GetActiveSubscriptionsResponse
  },
): void => {
  const { subscriptions } = payload

  // Update the subscriptions array with the new data
  state.data.subscriptions = subscriptions.map((subscription) => ({
    userSubscription: subscription.userSubscription,
    channel: subscription.channel,
    subscriptionDetails: subscription.subscriptionDetails,
    stripeSubscription: subscription.stripeSubscription,
  }))

  // Update the fetchStatus to indicate the successful data retrieval
  state.fetchStatus = 'succeeded'

  // Clear any previous error messages
  state.errorMessages = ''
}

export const subscriptionDefaultPaymentMethodFulfilledReducer = (
  state: UserBillingState,
  { payload }: { payload: SubscriptionDefaultPaymentMethodResponse },
): void => {
  const { stripeSubscriptionId, defaultPaymentMethodId } = payload

  // Find the subscription by stripeSubscriptionId
  const subscriptionToUpdate = state.data.subscriptions.find(
    (subscription) => subscription?.userSubscription?.stripeSubscriptionId === stripeSubscriptionId,
  )

  // If found, update the default_payment_method
  if (subscriptionToUpdate) {
    subscriptionToUpdate.stripeSubscription.default_payment_method = defaultPaymentMethodId
  }

  // Update the fetchStatus to indicate the successful data retrieval
  state.fetchStatus = 'succeeded'

  // Clear any previous error messages
  state.errorMessages = ''
}

export const rejectedReducer = (
  state: UserBillingState,
  { error }: { error: { message: string } },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = error.message
}
