import { createAsyncThunk } from '@reduxjs/toolkit'
import UseFeatureFlags from '@hooks/use-feature-flags'

export const fetchFeatureFlagsThunk = createAsyncThunk(
  'featureFlags/getAll',
  async (_, thunkAPI) => {
    try {
      return await UseFeatureFlags()
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
