import { createSlice } from '@reduxjs/toolkit'
import { getActivePlansFulfilledReducer, pendingReducer, rejectedReducer } from './_reducers'
import { initialUserBillingActivePlansState } from '@store/user-billing-slice/active-plans/_types'
import { getUserBillingActivePlansThunk } from '@store/user-billing-slice/active-plans/_thunks'

const userBillingActivePlansSlice = createSlice({
  name: 'user-billing-active-plans',
  initialState: initialUserBillingActivePlansState,
  reducers: {
    resetUserBillingActivePlansState: (state) => initialUserBillingActivePlansState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserBillingActivePlansThunk.pending, pendingReducer)
    builder.addCase(getUserBillingActivePlansThunk.fulfilled, getActivePlansFulfilledReducer)
    builder.addCase(getUserBillingActivePlansThunk.rejected, rejectedReducer)
  },
})

export const userBillingActivePlansReducer = userBillingActivePlansSlice.reducer
